import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { InputTextComponent } from "../../InputTextComponent";
import { WhiteButton } from "../../../styles/styledcomponets";
import { useQuery, gql } from "@apollo/client";
import { LoaderLarge } from "../../Loaders";
import { EditedAuthor } from "@balthasarspeyr/common";
import { theme } from "../../../styles/theme";
const EDITED_AUTHORS = gql`
  query getEditedAuthors {
    getEditedAuthors {
      id
      name {
        en
      }
      surname {
        en
      }
    }
  }
`;

const SetEditedAuthor: FC<{
  editedAuthor: string | undefined;
  updateEditedAuthor: (editedAuthor: string | undefined) => void;
}> = ({
  editedAuthor: editedAuthorProps,
  updateEditedAuthor: updateEditedAuthor
}) => {
  const { data, loading, error } = useQuery(EDITED_AUTHORS);

  const [filter, setFilter] = useState<string>("");
  const [editedAuthor, setEditedAuthor] = useState<EditedAuthor | undefined>(
    undefined
  );

  useEffect(() => {
    if (data) {
      const editedAuthor = data.getEditedAuthors.find(
        (editedAuthor) => editedAuthorProps === editedAuthor.id
      );

      setEditedAuthor(editedAuthor);
    }
  }, [data, editedAuthorProps]);

  if (loading) return <LoaderLarge />;
  if (error) return <p>Error :( </p>;

  return (
    <Div>
      <strong>Edited Author</strong>:
      {editedAuthor && (
        <EditedAuthorDiv>
          <div>
            {editedAuthor.name.en} {editedAuthor.surname?.en || ""}
          </div>
        </EditedAuthorDiv>
      )}
      <InputTextComponent
        width="auto"
        helper="Select Edited Author (search by name)"
        placeholder="name or surname (English)"
        value={filter}
        onChange={(value) => {
          setFilter(value);
        }}
      />
      {filter.length >= 1 &&
        data.getEditedAuthors
          .filter(
            (editedAuthor) =>
              editedAuthor.name.en
                .toLowerCase()
                .includes(filter.toLowerCase()) ||
              editedAuthor.surname.en
                .toLowerCase()
                .includes(filter.toLowerCase())
          )
          .map((editedAuthor) => (
            <div key={editedAuthor.id}>
              {editedAuthor.name.en} {editedAuthor.surname.en}
              <WhiteButton
                margin="5px 0 0 5px"
                onClick={() => {
                  setFilter("");
                  updateEditedAuthor(editedAuthor.id);
                }}
              >
                Set
              </WhiteButton>
            </div>
          ))}
    </Div>
  );
};

export default SetEditedAuthor;

const Div = styled.div`
  width: fit-content;
  margin: 0 20px 0 0;
`;

const EditedAuthorDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    min-width: 10px;
    width: 10px;
    min-height: 10px;
    height: 10px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    :hover {
      fill: ${theme.colors.brandRed};
    }
  }
  margin: 0px;
  padding: 0px;
`;
