import React, { FC, useState } from "react";
import {
  SITELANGS,
  ShortWork,
  ShortWorkPublicationInputData,
  sitePressHouse,
  Publication,
  PUBLICATIONLANGS,
  Work,
  WorkShortWork
} from "@balthasarspeyr/common";
import {
  Cell,
  Row,
  Table,
  Title,
  WhiteButton
} from "../../../styles/styledcomponets";
import ShortWorkEdit from "./ShortWorkEdit";
import { gql, useMutation } from "@apollo/client";
import { theme } from "../../../styles/theme";
import { ReactComponent as TrashIcon } from "../../../static/icons/trash.svg";
import styled from "@emotion/styled";
import CoverPreview from "../CoverPreview";

const ADD_PUBLICATION = gql`
  mutation addPublication($publication: PublicationInput!) {
    addPublication(publication: $publication) {
      id
    }
  }
`;

const UPDATE_PUBLICATION = gql`
  mutation updatePublication($id: ID!, $publication: PublicationInput!) {
    updatePublication(id: $id, publication: $publication) {
      id
    }
  }
`;

type ShortWorkPublicationsProps = {
  workShortWorkProp: WorkShortWork;
  author: string;
  shortWorksStage: string;
  deleteHandler: (id: string) => void;
  refetchHandler: () => void;
};

const ShortWorkPublications: FC<ShortWorkPublicationsProps> = ({
  workShortWorkProp: work,
  author,
  shortWorksStage,
  deleteHandler,
  refetchHandler
}) => {
  const newPublication: ShortWorkPublicationInputData = {
    public: false,
    featured: false,
    title: "",
    subtitle: "",
    list: false,
    pressHouse: sitePressHouse.id,
    translators: [],
    lang: work.originalLang as keyof typeof PUBLICATIONLANGS,
    year: work.year,
    DOI: "",
    purchaseLinks: [],
    downloadLinks: [],
    readLink: "",
    formats: [],
    bibref: "",
    containedWorks: [
      {
        work: work.id,
        parts: [],
        full: true
      }
    ],
    text: ""
  };

  enum STATUS {
    EDITING = "EDITING",
    ADDING = "ADDING",
    LISTING = "LISTING"
  }

  const [viewPublications, setViewPublications] = useState<boolean>(false);
  const [status, setStatus] = useState<STATUS>(STATUS.LISTING);
  const [editPubId, setEditPubId] = useState<string>("");
  const [addPublication] = useMutation<
    { addPublication: Publication },
    { publication: ShortWorkPublicationInputData }
  >(ADD_PUBLICATION, {
    onCompleted({ addPublication }) {
      if (addPublication) {
        refetchHandler();
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const [updatePublication] = useMutation<
    { updatePublication: Publication },
    { id: string; publication: ShortWorkPublicationInputData }
  >(UPDATE_PUBLICATION, {
    onCompleted({ updatePublication }) {
      if (updatePublication) {
        refetchHandler();
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  if (status === STATUS.EDITING) {
    return (
      <ShortWorkEdit
        work={work}
        publicationID={editPubId}
        save={(publication: ShortWorkPublicationInputData) => {
          updatePublication({ variables: { id: editPubId, publication } });
          setStatus(STATUS.LISTING);
        }}
        cancel={() => {
          setStatus(STATUS.LISTING);
        }}
      />
    );
  }
  if (status === STATUS.ADDING) {
    return (
      <ShortWorkEdit
        publication={newPublication}
        work={work}
        save={(publication) => {
          addPublication({ variables: { publication } });
          setStatus(STATUS.LISTING);
        }}
        cancel={() => {
          setStatus(STATUS.LISTING);
        }}
      />
    );
  }
  if (status === STATUS.LISTING) {
    return (
      <React.Fragment>
        <Row height="30px">
          <Cell>{work.title.en || ""}</Cell>
          <Cell>{work.subtitle?.en || ""}</Cell>
          <Cell>{work.year || ""}</Cell>
          <Cell>{work.originalLang}</Cell>
          <Cell>{work.baselref || ""}</Cell>
          <Cell>{work.public ? "Yes" : "No"}</Cell>
          <Cell>
            {!viewPublications && (
              <WhiteButton onClick={() => setViewPublications(true)}>
                View Publications
              </WhiteButton>
            )}
            {viewPublications && (
              <WhiteButton onClick={() => setViewPublications(false)}>
                Hide Publications
              </WhiteButton>
            )}
          </Cell>
        </Row>
        <Row>
          <Cell colSpan={6}>
            <Table width="100%" borderBottom="1px solid black">
              {viewPublications &&
                work.allPubs.map((pub: Publication) => (
                  <React.Fragment key={pub.id}>
                    <Row>
                      <Cell>{PUBLICATIONLANGS[pub.lang]}</Cell>
                      <Cell>{pub.bibref}</Cell>
                      <Cell>{pub.year}</Cell>
                      <Cell>{pub.pressHouse.name}</Cell>
                      <Cell>
                        <Actions>
                          <a
                            onClick={() => {
                              setEditPubId(pub.id);
                              setStatus(STATUS.EDITING);
                            }}
                          >
                            Edit
                          </a>
                          <CoverPreview id={pub.id} />
                          <TrashIcon
                            onClick={() => {
                              if (
                                confirm(
                                  "Are you sure you want to delete this publication?"
                                )
                              ) {
                                deleteHandler(pub.id);
                              }
                            }}
                          />
                        </Actions>
                      </Cell>
                    </Row>
                  </React.Fragment>
                ))}
              <Row>
                <Cell textAlign="center" colSpan={4}>
                  <WhiteButton
                    margin="10px 0 10px 0"
                    onClick={() => {
                      setStatus(STATUS.ADDING);
                    }}
                  >
                    Add Publication
                  </WhiteButton>
                </Cell>
              </Row>
            </Table>
          </Cell>
        </Row>
        <Row></Row>
      </React.Fragment>
    );
  }

  return <div>You should never see this</div>;
};

export default ShortWorkPublications;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  height: fit-content;
  a {
    margin-right: 10px;
    cursor: pointer;
    color: ${theme.colors.brandBlack};
    font-weight: 500;
    &:hover {
      color: ${theme.colors.gold01};
    }
  }
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    min-width: 18px;
    width: 18px;
    min-height: 18px;
    height: 18px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    :hover {
      fill: ${theme.colors.brandRed};
    }
  }
  margin: 0px;
  padding: 0px;
`;
