import styled from "@emotion/styled";
import React, { FC, useEffect, useMemo, useState } from "react";
import { colors } from "../theme";
import Icon from "./Icon";
import TableRow, { ITableRowProps } from "./TableRow";

interface ITableRowHeadProps extends ITableRowProps {
  onDragging?: (dragging: boolean) => void;
  onPosition?: (position: number) => void;
  position?: number;
}

const minWidth = 60; //px

const TableRowHead: FC<ITableRowHeadProps> = ({ columns, onDragging, onPosition, position }) => {
  const [containerClientRect, setContainerClientRect] = useState<DOMRect>();
  const [dragging, setDragging] = useState<boolean>(false);
  const [leftWidth, setLeftWidth] = useState<number>();

  const updateLeftWidth = useMemo(() => {
    if (dragging && containerClientRect) {
      return (clientX: number) => {
        setLeftWidth(clientX - containerClientRect.left);
      };
    } else {
      return () => undefined;
    }
  }, [containerClientRect, dragging]);

  useEffect(() => {
    if (!onPosition) return;

    const onResize = () => {
      if (containerClientRect) {
        onPosition(
          Math.min(
            Math.max(leftWidth || containerClientRect.width / 2, minWidth),
            containerClientRect.width - minWidth
          ) / containerClientRect.width
        );
      }
    };

    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [containerClientRect, leftWidth]);

  useEffect(() => {
    if (!onPosition) return;
  
    const onMouseMove = (e: MouseEvent) => updateLeftWidth(e.clientX);
    const onMouseUp = () => (onDragging?.(false), setDragging(false));

    document.body.style.pointerEvents = dragging ? "none" : "inherit";
    document.documentElement.style.cursor = dragging ? "grabbing" : "inherit";

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [containerClientRect, dragging]);

  return (
    <TableRow
      columns={columns.map((column, index) => (
        <React.Fragment key={index}>
          {column}
          {onPosition && position && columns.length === 2 && (
            <ResizeButton
              dragging={dragging}
              index={index}
              onDragStart={e => e.preventDefault()}
              onMouseDown={() => (onDragging?.(true), setDragging(true))}
              onTouchStart={() => (onDragging?.(true), setDragging(true))}
              onTouchMove={e => updateLeftWidth(e.touches[0].clientX)}
              onTouchEnd={() => (onDragging?.(false), setDragging(false))}
            >
              <Icon name="resize" />
            </ResizeButton>
          )}
        </React.Fragment>
      )) as [JSX.Element]|[JSX.Element, JSX.Element]}
      dragging={dragging}
      onContainerClientRect={setContainerClientRect}
      position={position}
    />
  );
}

export default TableRowHead;

const ResizeButton = styled.div<{ dragging: boolean, index: number }>`
  color: ${props => colors.gold[props.dragging ? 0 : 3]};
  cursor: pointer;
  height: 20px!important;
  right: 0;
  margin-top: 0;
  position: absolute;
  transform: translateX(50%);
  width: 20px!important;

  ${props => props.index > 0 && `
    left: 0;
    transform: translateX(-50%);
  `}
`;
