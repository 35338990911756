import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../../styles/theme";
import { ShortWorksStage, LangTexts, SITELANGS } from "@balthasarspeyr/common";
import { gql, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import BoxWithTitleContainer from "../BoxWithTitleContainer";
import MultiLingualContainer from "../MultiLingualContainer";
import MDEditorComponent from "../MDEditorComponent";
import {
  BlackButton,
  Cell,
  Input,
  RedButton,
  Row,
  SelectInput,
  Table,
  Title,
  WhiteButton
} from "../../styles/styledcomponets";
import { ShortWorksStageInput } from "../../types";

const langs: string[] = Object.values(SITELANGS);

const UPDATE_SHORTWORKSSTAGE = gql`
  mutation updateShortWorksStage(
    $id: ID!
    $shortWorksStage: ShortWorksStageInput!
  ) {
    updateShortWorksStage(id: $id, shortWorksStage: $shortWorksStage) {
      id
      order
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      author {
        id
        name {
          en
        }
        surname {
          en
        }
      }
      abstract {
       ${Object.values(SITELANGS).join("\n")}
      }
      description {
       ${Object.values(SITELANGS).join("\n")}
      }
    }
  }
`;

interface ShortWorksStageEditProps {
  shortWorksStageProp: ShortWorksStageInput & { id: string };
  refetchHandler: () => void;
  closeHandler: () => void;
}

const ShortWorksStageEdit: FC<ShortWorksStageEditProps> = ({
  shortWorksStageProp,
  refetchHandler,
  closeHandler
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [shortWorksStage, setShortWorksStage] = useState<
    ShortWorksStageInput & { id: string }
  >(cloneDeep(shortWorksStageProp));

  const [shortWorksStageAbstract, setShortWorksStageAbstract] =
    useState<LangTexts>({
      ...shortWorksStageProp.abstract!
    });

  const [shortWorksStageDescription, setShortWorksStageDescription] =
    useState<LangTexts>({
      ...shortWorksStageProp.description!
    });

  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const [updateShortWorksStage] = useMutation<
    { updateShortWorksStage: Partial<ShortWorksStage> },
    { id: string; shortWorksStage: ShortWorksStageInput }
  >(UPDATE_SHORTWORKSSTAGE, {
    onCompleted({ updateShortWorksStage }) {
      if (updateShortWorksStage) {
        setEditing(false);
        setPendingChange(false);
        refetchHandler();
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const valueChange = (value, key: string, lang?: string) => {
    const newShortWorksStage = cloneDeep(shortWorksStage);
    if (key !== "order") {
      newShortWorksStage[key][lang] = value;
    } else {
      newShortWorksStage[key] = Number(value);
    }

    setShortWorksStage(newShortWorksStage);
    setPendingChange(true);
  };

  const onSave = () => {
    const { id, ...short } = shortWorksStage;
    const variables = {
      id: shortWorksStage.id!,
      shortWorksStage: {
        ...short,
        abstract: shortWorksStageAbstract,
        description: shortWorksStageDescription
      }
    };

    updateShortWorksStage({
      variables
    });

    setPendingChange(false);
    setEditing(false);
  };

  return (
    <div>
      <Actions editing={editing}>
        {pendingChange ? (
          <BlackButton
            onClick={() => {
              onSave();
            }}
          >
            Save
          </BlackButton>
        ) : null}
        {pendingChange ? (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Close without saving
          </RedButton>
        ) : (
          <WhiteButton
            onClick={() => {
              closeHandler();
            }}
          >
            Back to ShortWorksStages List
          </WhiteButton>
        )}
      </Actions>
      <BoxWithTitleContainer title="ShortWorksStage Data">
        <Table width="100%">
          <thead>
            <Row>
              <Title width="40%">Name</Title>
              <Title width="20%">&nbsp;</Title>
              <Title width="5%">Order</Title>
            </Row>
          </thead>
          <tbody>
            {langs.map((lang) => (
              <Row key={`${lang}${shortWorksStage.id}`}>
                <Cell>
                  <Input
                    type="text"
                    name="name"
                    width="90%"
                    marginBottom="5px"
                    placeholder={`Stage name in ${lang}`}
                    editing={editing}
                    onClick={() => setEditing(true)}
                    onChange={(e) => valueChange(e.target.value, "name", lang)}
                    value={shortWorksStage.name[lang] || ""}
                  />
                </Cell>
                <Cell>{lang}</Cell>
                {lang == "en" && (
                  <Cell>
                    <Input
                      type="number"
                      name="order"
                      width="90%"
                      editing={editing}
                      onClick={() => setEditing(true)}
                      onChange={(e) => valueChange(e.target.value, "order")}
                      value={shortWorksStage.order || 0}
                    />
                  </Cell>
                )}
              </Row>
            ))}
          </tbody>
        </Table>
      </BoxWithTitleContainer>
      <MultiLingualContainer
        langWidth="100"
        title="Abstract"
        updateData={(data) => {
          setPendingChange(true);
          setShortWorksStageAbstract({ ...(data as LangTexts) });
        }}
        componentData={{ ...shortWorksStageAbstract }}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <MultiLingualContainer
        langWidth="100"
        title="Description"
        updateData={(data) => {
          setPendingChange(true);
          setShortWorksStageDescription({ ...(data as LangTexts) });
        }}
        componentData={{ ...shortWorksStageDescription }}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <Actions editing={editing}>
        {pendingChange ? (
          <BlackButton
            onClick={() => {
              onSave();
            }}
          >
            Save
          </BlackButton>
        ) : null}
        {pendingChange ? (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Close without saving
          </RedButton>
        ) : (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Back to ShortWorksStages List
          </RedButton>
        )}
      </Actions>
    </div>
  );
};

export default ShortWorksStageEdit;

interface IActionsProps {
  editing: boolean;
}
const Actions = styled.div<IActionsProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
