import React, { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import noImage from "../static/images/noimage.png";
import FileUploaderComponent from "./FileUploader";
import { Publication, SITELANGS, Work } from "@balthasarspeyr/common";
import { PublicationImage } from "@balthasarspeyr/ui";
import { gql, useQuery } from "@apollo/client";

const GET_WORKS = gql`
  query getWorksByID($ids: [ID!]!) {
    getWorksByID(ids: $ids) {
      authors {
        author {
          name {
            en
          }
          surname {
            en
          }
        }
      }
    }
  }
`;

const CoverUploader: FC<{
  setImageURL: (url: string) => void;
  url: string;
  title: string;
  works: string[];
  width?: number;
}> = ({ url: urlProp, title, works, width, setImageURL }) => {
  const [url, setURL] = useState<string>(urlProp);

  const [publication, setPublication] =
    useState<Partial<Publication | undefined>>(undefined);

  const { data, refetch } = useQuery<{
    getWorksByID: Array<Partial<Work>>;
  }>(GET_WORKS, {
    variables: {
      ids: works
    },
    onCompleted: (data) => {
      setPublication({
        title: title || "Title",
        containedWorks: data.getWorksByID.map((work) => ({
          work: work as Work,
          full: true,
          parts: []
        }))
      });
    }
  });

  useEffect(() => {
    if (data)
      setPublication({
        title: title || "Title",
        containedWorks: data.getWorksByID.map((work) => ({
          work: work as Work,
          full: true,
          parts: []
        }))
      });
  }, [data]);

  useEffect(() => {
    if (!url) refetch();
  }, [works]);

  useEffect(() => {
    if (!url)
      setPublication({
        ...publication,
        title: title || "Title"
      });
  }, [title]);

  useEffect(() => {
    setURL(urlProp);
  }, [urlProp]);

  const setFileURL: (imageURL: string) => void = (imageURL: string) => {
    setImageURL(imageURL);
    setURL(imageURL);
  };

  // FIXME - NAME OF FILE
  console.log("Antes del return");
  return (
    <Container>
      {/* {url === "" ? <Image src={noImage} /> : <Image src={url} />} */}

      {url === "" && publication && (
        <PublicationImage
          width={width || 110}
          publication={publication}
          language={SITELANGS.en}
          CMS={true}
        />
      )}

      {url === "" && !publication && (
        <Image width={width || 110} src={noImage} />
      )}

      {url !== "" && <Image width={width || 110} src={url} />}
      <br />
      <FileUploaderComponent
        helper=""
        text="Upload"
        width="fit-content"
        margin="0 0 0 0"
        setFileURL={(value) => setFileURL(value)}
        type="image"
      />
    </Container>
  );
};

export default CoverUploader;

const Container = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img<{ width: number }>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.width * 1.618}px`};
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;
