import React, { FC } from "react";
import {
  contents2string,
  ContentsEntry,
  emptyLangs,
  JSON2Contents,
  LangTexts
} from "@balthasarspeyr/common";
import ContentsComponent from "../../ContentsComponent";
import MDEditorComponent from "../../MDEditorComponent";
import MultiLingualContainer from "../../MultiLingualContainer";
import WorkGeneralDataComponent from "./MongraphicGeneralDataComponent";
import MonographicTitlesComponent from "./MongraphicTitlesComponent";
import { MainWorkInput } from "../../../types";

const MonographicEditorComponent: FC<{
  workCMSData: MainWorkInput;
  updateData: (data: Partial<MainWorkInput>) => void;
}> = ({ workCMSData, updateData }) => {
  return (
    <React.Fragment>
      <WorkGeneralDataComponent
        updateData={updateData}
        title="General Data"
        componentData={{
          authors: workCMSData.authors,
          baselref: workCMSData.baselref,
          year: workCMSData.year,
          originalLang: workCMSData.originalLang,
          type: workCMSData.type,
          labels: workCMSData.labels
        }}
      />
      <MultiLingualContainer
        updateData={(data) =>
          updateData({
            title: data["title"],
            caption: data["caption"],
            subtitle: data["subtitle"]
          })
        }
        langWidth="50"
        title="Title"
        Component={MonographicTitlesComponent}
        componentData={{
          title: workCMSData.title,
          caption: workCMSData.caption || { ...emptyLangs },
          subtitle: workCMSData.subtitle || { ...emptyLangs }
        }}
      />
      <MultiLingualContainer
        updateData={(data) => {
          updateData({ abstract: data as LangTexts });
        }}
        langWidth="100"
        title="Abstract"
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
        componentData={workCMSData.abstract || { ...emptyLangs }}
      />
      <MultiLingualContainer
        updateData={(data) => {
          updateData({ description: data as LangTexts });
        }}
        langWidth="100"
        title="Description"
        Component={MDEditorComponent}
        componentProps={{ height: "400px" }}
        componentData={workCMSData.description || { ...emptyLangs }}
      />
      <MultiLingualContainer
        updateData={(data) => updateData({ excerpt: data as LangTexts })}
        langWidth="100"
        title="Excerpt"
        Component={MDEditorComponent}
        componentProps={{ height: "400px" }}
        componentData={workCMSData.excerpt || { ...emptyLangs }}
      />
      <MultiLingualContainer
        updateData={(data) =>
          updateData({ contents: contents2string(data as ContentsEntry) })
        }
        langWidth="50"
        title="Contents"
        Component={ContentsComponent}
        componentData={JSON2Contents(workCMSData.contents) as ContentsEntry}
      />
    </React.Fragment>
  );
};

export default MonographicEditorComponent;
