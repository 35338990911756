import styled from "@emotion/styled";
import React, { FC } from "react";
import { breakpoints, colors } from "../theme";
import { IButtonProps } from "./Button";
import IconButton from "./IconButton";

const ScrollButton: FC<IButtonProps> = (props) => (
  <StyledIconButton
    name="angle"
    dark
    {...props}
  />
);

export default ScrollButton;

const StyledIconButton = styled(IconButton)`
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

  :hover {
    background-color: ${colors.blue[0]};
  }

  svg {
    transform: rotate(180deg);
  }
`;
