import styled from "@emotion/styled";
import React, { FC, useState, useEffect } from "react";
import { theme } from "../../styles/theme";
import {
  Recipient,
  LangTexts,
  siteAuthors,
  PUBLICATIONLANGS,
  SITELANGS
} from "@balthasarspeyr/common";
import { gql, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import BoxWithTitleContainer from "../BoxWithTitleContainer";
import MultiLingualContainer from "../MultiLingualContainer";
import MDEditorComponent from "../MDEditorComponent";
import {
  BlackButton,
  Cell,
  RedButton,
  Row,
  SelectInput,
  Table,
  Title,
  WhiteButton
} from "../../styles/styledcomponets";
import AvatarUploader from "../AvatarUploader";

// const langs: string[] = Object.values(Langs);

const UPDATE_RECIPIENT = gql`
  mutation updateRecipient($id: ID!, $recipient: RecipientInput!) {
    updateRecipient(id: $id, recipient: $recipient) {
      id
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      surname {
       ${Object.values(SITELANGS).join("\n")}
      }
      alias {
       ${Object.values(SITELANGS).join("\n")}
      }
      abstract {
       ${Object.values(SITELANGS).join("\n")}
      }
      image
    }
  }
`;

type UpdateRecipientInput = Omit<
  Recipient,
  "id" | "letters" | "description"
> & {
  description: Array<{
    author: string;
    description: LangTexts;
    correspondenceLanguage: string;
  }>;
};
type RecipientData = Omit<Recipient, "letters">;

interface RecipientEditProps {
  recipientProp: RecipientData;
  refetchHandler: () => void;
  closeHandler: () => void;
}

const RecipientEdit: FC<RecipientEditProps> = ({
  recipientProp,
  refetchHandler,
  closeHandler
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [recipient, setRecipient] = useState<RecipientData>(
    cloneDeep(recipientProp)
  );

  const [abstract, setRecipientAbstract] = useState<LangTexts>({
    ...recipientProp.abstract!
  });

  const [balthasarDescription, setBalthasarDescription] = useState<{
    author: string;
    description: LangTexts;
    correspondenceLanguage: string;
  }>({
    ...recipientProp.description!.find(
      (desc) => desc.author!.id! === siteAuthors.balthasar.id
    )!,
    author: siteAuthors.balthasar.id
  });

  const [speyrDescription, setSpeyrDescription] = useState<{
    author: string;
    description: LangTexts;
    correspondenceLanguage: string;
  }>({
    ...recipientProp.description!.find(
      (desc) => desc.author!.id! === siteAuthors.speyr.id
    )!,
    author: siteAuthors.speyr.id
  });

  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const [updateRecipient] = useMutation<
    { updateRecipient: UpdateRecipientInput },
    { id: string; recipient: UpdateRecipientInput }
  >(UPDATE_RECIPIENT, {
    onCompleted({ updateRecipient }) {
      if (updateRecipient) {
        setEditing(false);
        setPendingChange(false);
        refetchHandler();
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const valueChange = (value, key: string, lang?: string) => {
    const newRecipient = cloneDeep(recipient);
    //
    if (lang) {
      newRecipient[key][lang] = value;
    } else {
      newRecipient[key] = value;
    }
    setRecipient(newRecipient);
    setPendingChange(true);
  };

  const onSave = () => {
    const { id, ...recip } = recipient;
    const variables = {
      id: recipient.id!,
      recipient: {
        ...recip,
        description: [balthasarDescription, speyrDescription],
        abstract
      }
    };

    updateRecipient({
      variables
    });

    setPendingChange(false);
    setEditing(false);
  };

  return (
    <div>
      <Actions editing={editing}>
        {pendingChange ? (
          <BlackButton
            onClick={() => {
              onSave();
            }}
          >
            Save
          </BlackButton>
        ) : null}
        {pendingChange ? (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Close without saving
          </RedButton>
        ) : (
          <WhiteButton
            onClick={() => {
              closeHandler();
            }}
          >
            Back to Recipients List
          </WhiteButton>
        )}
      </Actions>
      <BoxWithTitleContainer title="Recipient Data">
        <Table width="100%">
          <thead>
            <tr>
              <Title width="50px">&nbsp;</Title>
              <Title width="20%">Name</Title>
              <Title width="20%">Surname</Title>
              <Title width="20%">Alias</Title>
              <Title width="10%">&nbsp;</Title>
              <Title width="15%">HUvB lang.</Title>
              <Title width="15%">AvS lang.</Title>
            </tr>
          </thead>
          <tbody>
            {Object.keys(SITELANGS).map((lang) => (
              <Row key={`${lang}${recipient.id}`}>
                <Cell>
                  {lang === SITELANGS.en && (
                    <AvatarUploader
                      setImageURL={(url) => valueChange(url, "image")}
                      url={recipient.image || ""}
                    />
                  )}
                </Cell>
                <Cell>
                  <Input
                    type="text"
                    name="name"
                    editing={editing}
                    onClick={() => setEditing(true)}
                    onChange={(e) => valueChange(e.target.value, "name", lang)}
                    value={recipient.name[lang] || ""}
                  />
                </Cell>
                <Cell>
                  <Input
                    type="text"
                    name="surname"
                    editing={editing}
                    onClick={() => setEditing(true)}
                    onChange={(e) =>
                      valueChange(e.target.value, "surname", lang)
                    }
                    value={recipient.surname[lang] || ""}
                  />
                </Cell>
                <Cell>
                  <Input
                    type="text"
                    editing={editing}
                    name="alias"
                    onClick={() => setEditing(true)}
                    onChange={(e) => valueChange(e.target.value, "alias", lang)}
                    value={recipient.alias?.[lang] || ""}
                  />
                </Cell>
                <Cell>{lang}</Cell>
                <Cell>
                  {lang === SITELANGS.en ? (
                    <SelectInput
                      width="fit-content"
                      defaultValue={
                        balthasarDescription.correspondenceLanguage || "de"
                      }
                      onChange={(e) => {
                        setPendingChange(true);
                        setBalthasarDescription({
                          ...balthasarDescription,
                          correspondenceLanguage: e.target.value
                        });
                      }}
                      value={
                        balthasarDescription.correspondenceLanguage || "de"
                      }
                    >
                      {Object.keys(SITELANGS).map((lang) => (
                        <option key={lang} value={SITELANGS[lang]}>
                          {SITELANGS[lang]}
                        </option>
                      ))}
                    </SelectInput>
                  ) : null}
                </Cell>
                <Cell>
                  {lang === SITELANGS.en ? (
                    <SelectInput
                      width="fit-content"
                      defaultValue={
                        speyrDescription.correspondenceLanguage || "de"
                      }
                      onChange={(e) => {
                        setPendingChange(true);
                        setSpeyrDescription({
                          ...speyrDescription,
                          correspondenceLanguage: e.target.value
                        });
                      }}
                      value={speyrDescription.correspondenceLanguage || "de"}
                    >
                      {Object.keys(SITELANGS).map((lang) => (
                        <option key={lang} value={SITELANGS[lang]}>
                          {SITELANGS[lang]}
                        </option>
                      ))}
                    </SelectInput>
                  ) : null}
                </Cell>
              </Row>
            ))}
          </tbody>
        </Table>
      </BoxWithTitleContainer>
      <MultiLingualContainer
        langWidth="100"
        title={`${recipient.name.en}'s Short Bio and Context`}
        updateData={(data) => {
          setPendingChange(true);
          setRecipientAbstract({ ...(data as LangTexts) });
        }}
        componentData={{ ...abstract }}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <MultiLingualContainer
        langWidth="100"
        title="Balthasar's Correspondence Description"
        updateData={(data) => {
          setPendingChange(true);
          setBalthasarDescription({
            ...balthasarDescription,
            description: { ...(data as LangTexts) }
          });
        }}
        componentData={balthasarDescription.description}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <MultiLingualContainer
        langWidth="100"
        title="Adrienne's correspondence Description"
        updateData={(data) => {
          setPendingChange(true);
          setSpeyrDescription({
            ...speyrDescription,
            description: { ...(data as LangTexts) }
          });
        }}
        componentData={speyrDescription.description}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <Actions editing={editing}>
        {pendingChange ? (
          <BlackButton
            onClick={() => {
              onSave();
            }}
          >
            Save
          </BlackButton>
        ) : null}
        {pendingChange ? (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Close without saving
          </RedButton>
        ) : (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Back to Recipients List
          </RedButton>
        )}
      </Actions>
    </div>
  );
};

export default RecipientEdit;

type InputProps = {
  editing: boolean;
};
const Input = styled.input<InputProps>`
  width: 95%;
  background-color: ${(props) =>
    props.editing ? theme.colors.brandWhite : theme.colors.gray6};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

interface IActionsProps {
  editing: boolean;
}
const Actions = styled.div<IActionsProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
