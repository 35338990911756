import styled from "@emotion/styled";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import colors from "../theme/colors";
import fonts from "../theme/fonts";

interface IBarRangeProps {
  className?: string;
  max: number;
  maxLabel: string;
  min: number;
  minLabel: string;
  offset?: number;
  onDragging?: (dragging: boolean) => void;
  onMax: (max: number) => void;
  onMin: (min: number) => void;
}

const BarRange: FC<IBarRangeProps> = ({
  max,
  maxLabel,
  min,
  minLabel,
  offset = 0,
  onDragging,
  onMax,
  onMin,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<"max"|"min">();

  const onDrag = useMemo(() => {
    if (dragging === "max" && ref?.current) {
      return (clientX: number) => {
        const left = ref.current?.getBoundingClientRect().left || 0;
        const width = ref.current?.getBoundingClientRect().width || 1;
        onMax(Math.max(Math.min((clientX - left) / width, 1), min + offset))
      };
    } else if (dragging === "min" && ref?.current) {
      return (clientX: number) => {
        const left = ref.current?.getBoundingClientRect().left || 0;
        const width = ref.current?.getBoundingClientRect().width || 1;
        onMin(Math.max(Math.min((clientX - left) / width, max - offset), 0));
      };
    } else {
      return () => undefined;
    }
  }, [dragging]);

  useEffect(() => {  
    const onMouseMove = (e: MouseEvent) => onDrag(e.clientX);
    const onMouseUp = () => (onDragging?.(false), setDragging(undefined));

    document.body.style.pointerEvents = dragging ? "none" : "inherit";
    document.documentElement.style.cursor = dragging ? "grabbing" : "inherit";

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragging]);

  return (
    <Bar max={max} min={min} ref={ref} {...props}>
      <Dot
        label={minLabel}
        onDragStart={e => e.preventDefault()}
        onMouseDown={() => (onDragging?.(true), setDragging("min"))}
        onTouchStart={() => (onDragging?.(true), setDragging("min"))}
        onTouchMove={e => onDrag(e.touches[0].clientX)}
        onTouchEnd={() => (onDragging?.(false), setDragging(undefined))}
        value={min}
      />
      <Dot
        label={maxLabel}
        onDragStart={e => e.preventDefault()}
        onMouseDown={() => (onDragging?.(true), setDragging("max"))}
        onTouchStart={() => (onDragging?.(true), setDragging("max"))}
        onTouchMove={e => onDrag(e.touches[0].clientX)}
        onTouchEnd={() => (onDragging?.(false), setDragging(undefined))}
        value={max}
      />
    </Bar>
  );
};

export default BarRange;

const Bar = styled.div<{ max: number, min: number }>`
  background-color: ${colors.grey[0]};
  height: 3px;
  position: relative;
  width: 100%;

  :before {
    background: ${colors.blue[0]};
    bottom: 0;
    content: "";
    height: 100%;
    left: ${props => props.min * 100}%;
    position: absolute;
    width: ${props => (props.max - props.min) * 100}%;
  }
`;

const Dot = styled.div<{ value: number, label: string }>`
  ${fonts.family.theinhardt}
  ${fonts.size.smallExtra}

  background-color: ${colors.blue[4]};
  border-radius: 50%;
  color: ${colors.blue[4]};
  cursor: pointer;
  display: flex;
  height: 10px;
  justify-content: center;
  left: ${props => props.value * 100}%;
  position: absolute;
  top: 1.5px;
  transform: translateY(-50%);
  width: 10px;

  :before {
    content: "${props => props.label}";
    padding-top: 15px;
  }
`;
