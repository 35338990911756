import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import { ContentsEntry, LangTexts, SITELANGS } from "@balthasarspeyr/common";
import { cloneDeep } from "@apollo/client/utilities";
import { theme } from "../styles/theme";
import BoxWithTitleContainer from "./BoxWithTitleContainer";
import { WhiteButton } from "../styles/styledcomponets";

const MultiLingualContainer: FC<{
  Component: FC<any>;
  title: string;
  langs?: number;
  langWidth?: string;
  componentProps?: { [key: string]: string };
  componentData?: LangTexts | ContentsEntry | { [key: string]: LangTexts };
  updateData: (
    data: LangTexts | ContentsEntry | { [key: string]: LangTexts }
  ) => void;
}> = ({
  Component,
  title,
  langs,
  langWidth,
  componentProps,
  componentData,
  updateData
}) => {
  useEffect(() => {
    setData(cloneDeep(componentData));
  }, [componentData]);
  const [columnWidth, setColumnWidth] = useState<string>(langWidth || "50");

  const [data, setData] = useState(cloneDeep(componentData));

  const [language, setLanguage] = useState<Array<keyof typeof SITELANGS>>(
    Object.keys(SITELANGS) as Array<keyof typeof SITELANGS>
  );
  const [numlangs, setNumlangs] = useState<number>(langs || 1);

  const update = (c) => {
    const data = cloneDeep(c);
    setData(data);
    updateData(data);
  };

  const MultiLingualComponent: JSX.Element = (
    <div>
      <Actions>
        <WhiteButton
          margin="auto 5px auto 5px"
          onClick={() => {
            numlangs > 1 && setNumlangs(numlangs - 1);
          }}
        >
          View less languages
        </WhiteButton>
        <WhiteButton
          margin="auto 5px auto 5px"
          onClick={() => {
            numlangs < 5 && setNumlangs(numlangs + 1);
          }}
        >
          View more languages
        </WhiteButton>
        Column Width:{" "}
        <Slider
          type="range"
          min="1"
          max="100"
          value={columnWidth}
          onChange={(e) => {
            Number(e.target.value) > 15 && setColumnWidth(e.target.value);
          }}
        />
      </Actions>
      <MultiLingualBox>
        {language.map(
          (lang, index) =>
            index < numlangs && (
              <SingleLang width={`${columnWidth}%`} key={`${lang}${index}`}>
                <LangsMenu>
                  {Object.keys(SITELANGS).map((key) => (
                    <Lang
                      key={key}
                      onClick={() => {
                        language[index] = SITELANGS[key];
                        setLanguage([...language]);
                      }}
                      selected={language[index] === SITELANGS[key]}
                    >
                      {key}
                    </Lang>
                  ))}
                </LangsMenu>
                <MainBox>
                  <Component
                    {...componentProps}
                    language={language[index]}
                    updateLang={(c) => update(c)}
                    data={{ ...data }}
                  />
                </MainBox>
              </SingleLang>
            )
        )}
      </MultiLingualBox>
    </div>
  );

  return (
    <BoxWithTitleContainer title={title}>
      {MultiLingualComponent}
    </BoxWithTitleContainer>
  );
};

export default MultiLingualContainer;

const LangsMenu = styled.div`
  display: flex;
  flex-direction: row;
`;

const Lang = styled.div<{ selected: boolean }>`
  border: 1px solid ${theme.colors.gray7};
  border-bottom: none;
  padding: 8px;
  font-size: 0.9rem;
  width: 60px;
  text-align: center;
  background-color: ${(props) =>
    props.selected ? theme.colors.gray6 : theme.colors.brandWhite};
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  cursor: pointer;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;
const MainBox = styled.div`
  border: 1px solid ${theme.colors.gray7};
  padding: 20px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  margin-right: 5px;
`;

const MultiLingualBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
`;

const SingleLang = styled.div<{ width?: string }>`
  flex-grow: 1;
  width: ${(props) => props.width || "50%"};
  max-width: ${(props) => props.width || "50%"};
  min-width: ${(props) => props.width || "50%"};
  margin-top: 10px;
`;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: stretch;
`;

const Slider = styled.input`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 400px;
  height: 15px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  margin-top: 10px;
  margin-left: 10px;

  /* Mouse-over effects */
  :hover {
    opacity: 1; /* Fully shown on mouse-over */
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  ::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 20px; /* Set a specific slider handle width */
    height: 20px; /* Slider handle height */
    border-radius: 10px;
    background: ${theme.colors.brandBlack}; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }

  ::-moz-range-thumb {
    width: 20px; /* Set a specific slider handle width */
    height: 20px; /* Slider handle height */
    border-radius: 10px;
    background: ${theme.colors.brandBlack}; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
`;
