import React, { FC, useState } from "react";

import BooksList from "./BooksList";
import BookEdit from "./BookEdit";

enum STATUS {
  LISTING,
  EDITING,
  ADDING
}

const OrphansCMSLayout: FC = () => {
  const [status, setStatus] = useState(STATUS.LISTING);
  const [publication, setPublication] = useState<string | undefined>(undefined);

  return (
    <div>
      {status === STATUS.EDITING && publication && (
        <BookEdit
          setStatus={setStatus}
          action="edit"
          cancel={() => setStatus(STATUS.LISTING)}
          publicationID={publication}
        />
      )}
      {status === STATUS.LISTING && (
        <>
          <BooksList
            setStatus={setStatus}
            setPublication={setPublication}
            orphans={true}
          />
        </>
      )}
    </div>
  );
};

export default OrphansCMSLayout;
