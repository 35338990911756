import React, { FC, useEffect, useState } from "react";
import { AUTHORROLE, PUBLICATIONLANGS, WORKTYPE } from "@balthasarspeyr/common";
import BoxWithTitleContainer from "../../BoxWithTitleContainer";
import styled from "@emotion/styled";
import { InputTextComponent } from "../../InputTextComponent";
import { EditedWorkInput } from "../../../types";
import AddAuthors from "../AddAuthors";
import { PublicationLanguageSelect } from "../../LanguageSelect";
import SetEditedAuthor from "./SetEditedAuthor";
import { Checkbox } from "@balthasarspeyr/ui";
import LabelsSelector from "../../labels/LabelsSelector";

interface IAuthorRole {
  role: AUTHORROLE;
  id: string;
}

const WorkGeneralDataComponent: FC<{
  title: string;
  componentData: Partial<EditedWorkInput>;
  updateData: (data: Partial<EditedWorkInput>) => void;
}> = ({ title, componentData, updateData }) => {
  const [addedAuthors, setAddedAuthors] = useState<Array<IAuthorRole>>(
    componentData.authors || []
  );

  const [editedAuthor, setEditedAuthor] = useState<string | undefined>(
    componentData.editedAuthor || undefined
  );
  const [baselref, setBaselRef] = useState<string>(
    componentData.baselref || ""
  );
  const [year, setYear] = useState<string>(
    componentData.year?.toString() || ""
  );

  const [publicW, setPublic] = useState<boolean>(componentData.public || false);

  const [originalLang, setOriginalLang] = useState<string>(
    componentData.originalLang || PUBLICATIONLANGS.de
  );

  const [type, setType] = useState<WORKTYPE[]>(componentData.type || []);
  const [labels, setLabels] = useState<string[]>(componentData.labels || []);

  // if a list of authors are passed as argument, set them as added
  useEffect(() => {
    setAddedAuthors(componentData.authors || []);
    setEditedAuthor(componentData.editedAuthor || undefined);
    setBaselRef(componentData.baselref || "");
    setYear(componentData.year?.toString() || "");
    setOriginalLang(componentData.originalLang || PUBLICATIONLANGS.de);
    setType(componentData.type || []);
    setLabels(componentData.labels || []);
    setPublic(componentData.public || false);
  }, [componentData]);

  const ComponentJSX = (
    <Container>
      <AuthorDiv>
        <AddAuthors
          authors={addedAuthors}
          updateAuthors={(authors: IAuthorRole[]) => {
            updateData({ authors });
            setAddedAuthors([...authors]);
          }}
        />
      </AuthorDiv>
      <SetEditedAuthor
        editedAuthor={editedAuthor}
        updateEditedAuthor={(editedAuthor: string | undefined) => {
          updateData({ editedAuthor });
          setEditedAuthor(editedAuthor);
        }}
      />
      <div>
        <Checkbox
          label="Public"
          checked={publicW}
          onChange={(e) => {
            updateData({ public: e.target.checked });
            setPublic(e.target.checked);
          }}
        />

        <InputTextComponent
          margin="0px 20px 0px 0px"
          padding="0px 0px 0px 0px"
          width="200px"
          placeholder="bib. ref."
          value={baselref}
          helper="Basel bib. Reference"
          onChange={(value) => {
            updateData({ baselref: value });
            setBaselRef(value);
          }}
        />
        <div>
          <Checkbox
            label={WORKTYPE.PREFACE}
            onChange={(e) => {
              const types = e.target.checked
                ? [...type, WORKTYPE.PREFACE]
                : type.filter((t) => t !== WORKTYPE.PREFACE);
              setType(types);
              updateData({ type: types });
            }}
            checked={type.includes(WORKTYPE.PREFACE)}
          />
          <Checkbox
            label={WORKTYPE.EPILOGUE}
            onChange={(e) => {
              const types = e.target.checked
                ? [...type, WORKTYPE.EPILOGUE]
                : type.filter((t) => t !== WORKTYPE.EPILOGUE);
              setType(types);
              updateData({ type: types });
            }}
            checked={type.includes(WORKTYPE.EPILOGUE)}
          />
          <Checkbox
            label={WORKTYPE.ANTHOLOGY}
            onChange={(e) => {
              const types = e.target.checked
                ? [...type, WORKTYPE.ANTHOLOGY]
                : type.filter((t) => t !== WORKTYPE.ANTHOLOGY);
              setType(types);
              updateData({ type: types });
            }}
            checked={type.includes(WORKTYPE.ANTHOLOGY)}
          />
          <Checkbox
            label={WORKTYPE.TRANSLATION}
            onChange={(e) => {
              const types = e.target.checked
                ? [...type, WORKTYPE.TRANSLATION]
                : type.filter((t) => t !== WORKTYPE.TRANSLATION);
              setType(types);
              updateData({ type: types });
            }}
            checked={type.includes(WORKTYPE.TRANSLATION)}
          />
        </div>
      </div>
      <div>
        <PublicationLanguageSelect
          value={originalLang}
          onChange={(e) => {
            updateData({ originalLang: e.target.value });
            setOriginalLang(e.target.value);
          }}
        />

        <br />
        <InputTextComponent
          margin="0px 20px 10px 0px"
          padding="0px 0px 0px 0px"
          width="200px"
          placeholder="Year"
          value={year}
          helper="First Publication Year"
          onChange={(value) => {
            updateData({ year: Number(value) });
            setYear(value);
          }}
        />
        <LabelsSelector
          labels={labels}
          showHelper={true}
          onChange={(value) => {
            updateData({ labels: value });
            setLabels(value);
          }}
        />
      </div>
    </Container>
  );

  return (
    <BoxWithTitleContainer title={title}>{ComponentJSX}</BoxWithTitleContainer>
  );
};

export default WorkGeneralDataComponent;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const AuthorDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  width: 50%;
`;
