import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { theme } from "../../styles/theme";

const MessageModal: FC<{ message: string; timeout: number }> = ({
  message,
  timeout,
}) => {
  const [visible, setVisible] = useState<boolean>(true);
  const [hide, setHide] = useState<boolean>(false);
  useEffect(() => {
    let timer2: NodeJS.Timeout;
    const timer = setTimeout(() => {
      setHide(true);
      timer2 = setTimeout(() => {
        setVisible(false);
      }, timeout);
    }, timeout);
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  if (visible)
    return (
      <Container hide={hide} timeout={`${timeout}ms`}>
        {message}
      </Container>
    );
  else return null;
};

const Container = styled.div<{ hide: boolean; timeout: string }>`
  position: fixed;
  font-size: 0.8rem;
  font-weight: bold;
  top: 100px;
  left: 50%;
  padding: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  transform: translate(-50%, 0%);
  border: 1px solid;
  border-radius: 10px;
  background-color: ${theme.colors.brandWhite};
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.5);
  visibility: visible;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: opacity ${(props) => props.timeout};
`;

export default MessageModal;
