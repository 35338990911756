import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../../../styles/theme";
import { ReactComponent as TrashIcon } from "../../../static/icons/trash.svg";
import {
  AUTHORROLE,
  WorkShortWork,
  PUBLICATIONLANGS
} from "@balthasarspeyr/common";
import { ShortWorkInput } from "../../../types";

import { gql, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import {
  Actions,
  BlackButton,
  Cell,
  Input,
  RedButton,
  Row,
  Rowx,
  SelectInput,
  WhiteButton
} from "../../../styles/styledcomponets";
import MultilingualInput from "../../MultilingualInput";
import {
  PublicationLanguageSelect,
  SiteLanguageSelect
} from "../../LanguageSelect";
import LabelsSelector from "../../labels/LabelsSelector";

const UPDATE_WORK = gql`
  mutation updateWork($id: ID!, $work: WorkInput!) {
    updateWork(id: $id, work: $work) {
      id
    }
  }
`;

interface ShortWorkEditProps {
  shortWorkProp: ShortWorkInput & { id: string };
  author: string;
  deleteHandler: () => void;
  refetchHandler: () => void;
}

const ShortWorkEdit: FC<ShortWorkEditProps> = ({
  shortWorkProp,
  author,
  deleteHandler,
  refetchHandler
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [multilang, setMultilang] = useState<boolean>(false);
  const [shortWork, setShortWork] = useState<ShortWorkInput & { id: string }>({
    ...cloneDeep(shortWorkProp),
    authors: [{ id: author, role: AUTHORROLE.Author }]
  });
  const [backWork, setBackShortWork] = useState<
    ShortWorkInput & { id: string }
  >({
    ...cloneDeep(shortWorkProp),
    authors: [{ id: author, role: AUTHORROLE.Author }]
  });
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const [updateWork] = useMutation<
    { updateWork: WorkShortWork },
    { id: string; work: ShortWorkInput }
  >(UPDATE_WORK, {
    onCompleted({ updateWork }) {
      if (updateWork) {
        setEditing(false);
        setPendingChange(false);
        setBackShortWork(cloneDeep(shortWork));
        refetchHandler();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const valueChange = (value, key: string) => {
    const newWork = cloneDeep(shortWork);
    newWork[key] = value;
    setShortWork(newWork);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setEditing(false);
    setPendingChange(false);
    setShortWork(backWork);
  };

  const onSave = () => {
    const { id, ...updatedWork } = shortWork;
    updateWork({
      variables: {
        id: shortWork.id!,
        work: updatedWork
      }
    });
  };

  return (
    <React.Fragment>
      <Row borderBottom="1px solid #bbb">
        <Cell>
          <MultilingualInput
            editing={editing}
            marginBottom={"5px"}
            width={"95%"}
            value={{ ...shortWork.title }}
            onChange={(value) => valueChange(value, "title")}
            multilang={multilang}
          />
        </Cell>
        <Cell>
          <MultilingualInput
            editing={editing}
            marginBottom={"5px"}
            width={"95%"}
            value={{ ...shortWork.subtitle }}
            onChange={(value) => valueChange(value, "subtitle")}
            multilang={multilang}
          />
        </Cell>
        <Cell>
          <Input
            type="number"
            editing={editing}
            width="95%"
            value={shortWork.year}
            onChange={(e) => valueChange(parseInt(e.target.value), "year")}
          />
        </Cell>
        <Cell padding="0 0 0 10px">
          <PublicationLanguageSelect
            hideHelper={true}
            onChange={(e) => valueChange(e.target.value, "originalLang")}
            value={shortWork.originalLang || PUBLICATIONLANGS[0]}
          />
        </Cell>
        <Cell>
          <Input
            editing={editing}
            width={"95%"}
            placeholder="Basel ref."
            type="string"
            onChange={(e) => valueChange(e.target.value, "baselref")}
            value={shortWork.baselref || ""}
          />
        </Cell>
        <Cell>
          <Input
            editing={editing}
            placeholder="Public"
            type="checkbox"
            onChange={(e) => {
              valueChange(e.target.checked, "public");
            }}
            checked={shortWork.public}
          />
        </Cell>
        <Cell>
          <LabelsSelector
            labels={shortWork.labels}
            onChange={(labels) => valueChange(labels, "labels")}
          />
        </Cell>
        <Cell>
          <Actions>
            {multilang ? (
              <BlackButton onClick={() => setMultilang(false)}>
                Only En.
              </BlackButton>
            ) : (
              <BlackButton onClick={() => setMultilang(true)}>
                All langs
              </BlackButton>
            )}
            {pendingChange ? (
              <WhiteButton
                onClick={() => {
                  onSave();
                }}
              >
                Save
              </WhiteButton>
            ) : null}
            {pendingChange ? (
              <RedButton
                onClick={() => {
                  onDiscard();
                }}
              >
                Discard
              </RedButton>
            ) : null}
            <TrashIcon
              onClick={() => {
                if (
                  confirm(
                    "Are you sure you want to delete this short work? (you will remove all its publications)"
                  )
                ) {
                  deleteHandler();
                }
              }}
            />
          </Actions>
        </Cell>
      </Row>
    </React.Fragment>
  );
};

export default ShortWorkEdit;
