import styled from "@emotion/styled";
import React, { FC, useEffect, useRef, useState } from "react";
import { colors } from "../theme";
import Icon from "./Icon";

export interface ICarrouselProps {
  arrows?: "big" | "small";
  className?: string;
  loading?: JSX.Element;
  onPage: (page: number) => void;
  page: number;
  totalPages: number;
}

const Carrousel: FC<ICarrouselProps> = ({ arrows  = "small", children, loading, onPage, page, totalPages, ...props }) => {
  const paginatorRef = useRef<HTMLDivElement>(null);
  const [paginatorWidth, setPaginatorWidth] = useState<number>(0);

  const onPageLeft = () => onPage(page > 1 ? page - 1 : totalPages);
  const onPageRight = () => onPage(page < totalPages ? page + 1 : 1);

  const onResize = () => paginatorRef.current?.clientWidth && setPaginatorWidth(paginatorRef.current?.clientWidth);

  useEffect(() => {
    onResize();

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [children]);

  return (
    <CarrouselWrap arrows={arrows} {...props}>
      {loading || (
        <>
          <div>
            {totalPages > 1 && arrows === "big" && (
              <CarrouselArrow>
                <div onClick={onPageLeft}>
                  <Icon name="less-than" />
                </div>
              </CarrouselArrow>
            )}
            <div>
              {children}
            </div>
            {totalPages > 1 && arrows === "big" && (
              <CarrouselArrow>
                <div onClick={onPageRight}>
                  <Icon name="less-than" />
                </div>
              </CarrouselArrow>
            )}
          </div>
          {totalPages > 1 && (
            <Paginator width={paginatorWidth}>
              {arrows === "small" && (
                <PaginatorArrow onClick={onPageLeft}>
                  <Icon name="less-than" />
                </PaginatorArrow>
              )}
              <div>
                <div ref={paginatorRef}>
                  {Array(...Array(totalPages)).map((_value, index) => (
                    <PaginatorCircle
                      active={page === index + 1}
                      key={index}
                      onClick={() => onPage(index + 1)}
                    />
                  ))}
                </div>
              </div>
              {arrows === "small" && (
                <PaginatorArrow onClick={onPageRight}>
                  <Icon name="less-than" />
                </PaginatorArrow>
              )}
            </Paginator>
          )}
        </>
      )}
    </CarrouselWrap>
  );
}

export default Carrousel;

const Arrow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 5px;

  > svg {
    cursor: pointer;
  }

  :last-of-type {
    transform: rotate(180deg);
  }
`;

const CarrouselArrow = styled(Arrow)`
  background-color: ${colors.white};
  height: 20px;
  justify-content: flex-start;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0 30px;
  position: absolute;
  z-index: 1;

  > div {
    cursor: pointer;
    height: 20px;
    transform: translateY(-50px);
  }

  :last-of-type {
    left: unset;
    right: 0;
    transform: rotate(180deg);

    > div {
      transform: translateY(50px);
    }
  }
`;

const CarrouselWrap = styled.div<{ arrows?: "big" | "small" }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;

  > div:first-of-type {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    > div:not(:first-of-type):not(:last-of-type), > div:only-of-type {
      display: flex;
      justify-content: center;
      width: ${props => props.arrows === "big" ? "calc(100% - 110px)" : "100%"};
    }
  }
`;

const Paginator = styled.div<{ width: number }>`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  padding-top: 15px;
  width: 100%;

  > div:not(:first-of-type):not(:last-of-type), > div:only-of-type {
    flex: 1;
    height: 10px;
    overflow: scroll;
    position: relative;
    max-width: ${props => props.width}px;

    :only-of-type > div {
      padding: 0 10px;
    }

    > div {
      align-items: center;
      display: flex;
      height: 10px;
      position: absolute;
    }
  }
`;

const PaginatorArrow = styled(Arrow)`
  height: 10px;
  width: 10px;
`;

const PaginatorCircle = styled.div<{ active: boolean }>`
  background-color: ${(props) =>
    props.active ? colors.gold[0] : colors.gold[3]};
  border-radius: 50%;
  cursor: pointer;
  height: 10px;
  margin: 0 5px;
  width: 10px;
`;
