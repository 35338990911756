import React, { FC, useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { LoaderLarge } from "../../Loaders";
import { Author, PUBLICATIONLANGS, WORKTYPE } from "@balthasarspeyr/common";
import {
  Input,
  H2,
  Table,
  Row,
  Title,
  Cell
} from "../../../styles/styledcomponets";
import { GenericBook } from "@balthasarspeyr/common";
import { ReactComponent as TrashIcon } from "../../../static/icons/trash.svg";
import styled from "@emotion/styled";
import { theme } from "../../../styles/theme";

enum STATUS {
  LISTING,
  EDITING,
  ADDING
}

type BooksData = {
  getPublications: {
    data: GenericBook[];
  };
};

type ThirdAuthorsBooksData = {
  getThirdAuthorPublications: {
    data: GenericBook[];
  };
};

type OrphanBooksData = {
  getOrphanPublications: {
    data: GenericBook[];
  };
};
const GET_BOOKS = gql`
  query getPublications($filter: PublicationFilter!) {
    getPublications(filter: $filter) {
      data {
        public
        list
        title
        id
        lang
        year
        pressHouse {
          name
        }
        DOI
        containedWorks {
          work {
            type
          }
        }
        isbn
      }
    }
  }
`;

const GET_THIRD_AUTHORS_BOOKS = gql`
  query getThirdAuthorPublications {
    getThirdAuthorPublications {
      data {
        public
        list
        title
        id
        lang
        year
        pressHouse {
          name
        }
        DOI
        containedWorks {
          work {
            type
          }
        }
        isbn
      }
    }
  }
`;

const GET_BOOKS_ORPHANS = gql`
  query getOrphanPublications {
    getOrphanPublications {
      data {
        public
        list
        title
        id
        lang
        year
        pressHouse {
          name
        }
        DOI
        isbn
      }
    }
  }
`;

const DELETEPUBLICATION = gql`
  mutation deletePublication($id: ID!) {
    deletePublication(id: $id) {
      ok
      id
    }
  }
`;

const BooksList: FC<{
  authors?: Omit<Author, "works">[];
  setPublication: (id: string) => void;
  setStatus: (status: STATUS) => void;
  orphans?: boolean;
  thirdAuthors?: boolean;
}> = ({ authors, setPublication, setStatus, orphans, thirdAuthors }) => {
  const [nameFilter, setNameFilter] = useState("");
  const [collapse, setCollapse] = useState(true);
  const { loading, error, data, refetch } = useQuery<BooksData>(GET_BOOKS, {
    variables: {
      filter: {
        authors: authors?.map((author) => author.id!) || [],
        book: true
      }
    },
    fetchPolicy: "no-cache",
    skip: orphans || thirdAuthors
  });

  const {
    loading: loadingorphans,
    error: errororphans,
    data: dataorphans,
    refetch: refetchorphans
  } = useQuery<OrphanBooksData>(GET_BOOKS_ORPHANS, {
    fetchPolicy: "no-cache",
    skip: !orphans
  });

  const {
    loading: loadingthird,
    error: errorthird,
    data: datathird,
    refetch: refetchthird
  } = useQuery<ThirdAuthorsBooksData>(GET_THIRD_AUTHORS_BOOKS, {
    fetchPolicy: "no-cache",
    skip: !thirdAuthors
  });

  const [deletePublication] = useMutation<
    { deletePublication: { ok: boolean; id: string } },
    { id: string }
  >(DELETEPUBLICATION, {
    onCompleted({ deletePublication }) {
      if (deletePublication.ok) {
        if (!orphans) refetch();
        else refetchorphans();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <H2 style={{ cursor: "pointer" }} onClick={() => setCollapse(!collapse)}>
        {collapse ? <span>▼</span> : <span>►</span>}
        {authors &&
          authors
            .map((author) => `Books by ${author.name.en} ${author.surname.en}`)
            .join(" and ")}
        {orphans && "Orphan Publications"}
        {thirdAuthors && "Third Authors Publications"}
      </H2>
      {collapse && (
        <>
          <br />
          <Input
            border="1px solid #ccc"
            marginBottom="10px"
            marginTop="10px"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            placeholder="Filter by title"
            width="200px"
          />

          {data && !orphans && !thirdAuthors && (
            <Table width="100%">
              <thead>
                <Row borderBottom="1px solid #aaa">
                  <Title width="30%">Title</Title>
                  <Title width="40px">Year</Title>
                  <Title width="80px">Language</Title>
                  <Title width="150px">Press house</Title>
                  <Title width="150px">isbn</Title>
                  {/* <Title width="150px">Type(s)</Title> */}
                  <Title width="60px">Public</Title>
                  <Title width="80px">Pubs. Sec.</Title>
                  <Title width="*">&nbsp;</Title>
                </Row>
              </thead>
              <tbody>
                {data.getPublications.data
                  .filter(
                    (book: GenericBook) =>
                      book.title
                        ?.toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                      book.pressHouse?.name
                        ?.toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                      book.isbn
                        ?.toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                      book.DOI?.toLowerCase().includes(
                        nameFilter.toLowerCase()
                      ) ||
                      book.year?.toString().includes(nameFilter.toLowerCase())
                  )
                  .map((book: GenericBook) => (
                    <Row key={book.id}>
                      <Cell>{book.title}</Cell>
                      <Cell>{book.year}</Cell>
                      <Cell>{PUBLICATIONLANGS[book.lang]}</Cell>
                      <Cell>{book.pressHouse?.name}</Cell>
                      <Cell>{book.isbn || "-"}</Cell>
                      <Cell>{book.public ? "Yes" : "No"}</Cell>
                      <Cell>{book.list ? "Yes" : "No"}</Cell>
                      <Cell>
                        <Actions>
                          <TrashIcon
                            style={{ margin: "0 20px 0 20px" }}
                            onClick={() => {
                              if (
                                confirm(
                                  "Are you sure you want to delete this publication?"
                                )
                              )
                                deletePublication({
                                  variables: { id: book.id }
                                });
                            }}
                          />{" "}
                          <span
                            onClick={() => {
                              setPublication(book.id);
                              setStatus(STATUS.EDITING);
                            }}
                          >
                            Edit
                          </span>
                        </Actions>
                      </Cell>
                    </Row>
                  ))}
              </tbody>
            </Table>
          )}

          {thirdAuthors && datathird && (
            <Table width="100%">
              <thead>
                <Row borderBottom="1px solid #aaa">
                  <Title width="30%">Title</Title>
                  <Title width="40px">Year</Title>
                  <Title width="80px">Language</Title>
                  <Title width="150px">Press house</Title>
                  <Title width="150px">isbn</Title>
                  <Title width="150px">Type(s)</Title>
                  <Title width="60px">Public</Title>
                  <Title width="80px">Pubs. Sec.</Title>
                  <Title width="*">&nbsp;</Title>
                </Row>
              </thead>
              <tbody>
                {datathird.getThirdAuthorPublications.data
                  .filter(
                    (pub: GenericBook) =>
                      pub.title
                        ?.toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                      pub.pressHouse?.name
                        ?.toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                      pub.isbn
                        ?.toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                      pub.DOI?.toLowerCase().includes(
                        nameFilter.toLowerCase()
                      ) ||
                      pub.year?.toString().includes(nameFilter.toLowerCase())
                  )
                  .map((pub: GenericBook) => (
                    <Row key={pub.id}>
                      <Cell>{pub.title}</Cell>
                      <Cell>{pub.year}</Cell>
                      <Cell>{PUBLICATIONLANGS[pub.lang]}</Cell>
                      <Cell>{pub.pressHouse?.name}</Cell>
                      <Cell>{pub.isbn || "-"}</Cell>
                      <Cell>
                        {pub.containedWorks
                          .flatMap((w) => w.work.type)
                          .join(", ")}
                      </Cell>
                      <Cell>{pub.public ? "Yes" : "No"}</Cell>
                      <Cell>{pub.list ? "Yes" : "No"}</Cell>
                      <Cell>
                        <Actions>
                          <TrashIcon
                            style={{ margin: "0 20px 0 20px" }}
                            onClick={() => {
                              if (
                                confirm(
                                  "Are you sure you want to delete this publication?"
                                )
                              )
                                deletePublication({
                                  variables: { id: pub.id }
                                });
                            }}
                          />{" "}
                          <span
                            onClick={() => {
                              setPublication(pub.id);
                              setStatus(STATUS.EDITING);
                            }}
                          >
                            Edit
                          </span>
                        </Actions>
                      </Cell>
                    </Row>
                  ))}
              </tbody>
            </Table>
          )}

          {orphans && dataorphans && (
            <Table width="100%">
              <thead>
                <Row borderBottom="1px solid #aaa">
                  <Title width="30%">Title</Title>
                  <Title width="40px">Year</Title>
                  <Title width="80px">Language</Title>
                  <Title width="150px">Press house</Title>
                  <Title width="150px">isbn</Title>
                  {/* <Title width="150px">Type(s)</Title> */}
                  <Title width="60px">Public</Title>
                  <Title width="80px">Pubs. Sec.</Title>
                  <Title width="*">&nbsp;</Title>
                </Row>
              </thead>
              <tbody>
                {dataorphans.getOrphanPublications.data
                  .filter(
                    (book: GenericBook) =>
                      book.title
                        ?.toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                      book.pressHouse?.name
                        ?.toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                      book.DOI?.toLowerCase().includes(
                        nameFilter.toLowerCase()
                      ) ||
                      book.year?.toString().includes(nameFilter.toLowerCase())
                  )
                  .map((book: GenericBook) => (
                    <Row key={book.id}>
                      <Cell>{book.title}</Cell>
                      <Cell>{book.year}</Cell>
                      <Cell>{PUBLICATIONLANGS[book.lang]}</Cell>
                      <Cell>{book.pressHouse?.name}</Cell>
                      <Cell>{book.isbn || "-"}</Cell>
                      {/* <Cell>
                        <em>Orphan</em>
                      </Cell> */}
                      <Cell>{book.public ? "Yes" : "No"}</Cell>
                      <Cell>{book.list ? "Yes" : "No"}</Cell>
                      <Cell>
                        <Actions>
                          <TrashIcon
                            style={{ margin: "0 20px 0 20px" }}
                            onClick={() => {
                              if (
                                confirm(
                                  "Are you sure you want to delete this publication?"
                                )
                              )
                                deletePublication({
                                  variables: { id: book.id }
                                });
                            }}
                          />{" "}
                          <span
                            onClick={() => {
                              setPublication(book.id);
                              setStatus(STATUS.EDITING);
                            }}
                          >
                            Edit
                          </span>
                        </Actions>
                      </Cell>
                    </Row>
                  ))}
              </tbody>
            </Table>
          )}
        </>
      )}
    </div>
  );
};

export default BooksList;

const Actions = styled.div`
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    min-width: 10px;
    width: 10px;
    min-height: 10px;
    height: 10px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    :hover {
      fill: ${theme.colors.brandRed};
    }
  }
  span {
    cursor: pointer;
    :hover {
      color: ${theme.colors.gold01};
    }
  }

  margin: 0px;
  padding: 0px;
`;
