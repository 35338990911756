import React, { FC, useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { LoaderLarge } from "../../Loaders";
import { Author, LettersMap } from "@balthasarspeyr/common";
import {
  Input,
  H2,
  H3,
  Table,
  Row,
  Title,
} from "../../../styles/styledcomponets";
import { cloneDeep } from "@apollo/client/utilities";
import styled from "@emotion/styled";
import LetterPublications from "./LetterPublications";

type WorkLettersData = {
  getLettersMap: LettersMap;
};
const LETTERSMAP = gql`
  query getLettersMap($author: ID!) {
    getLettersMap(author: $author) {
      author {
        id
        name {
          en
        }
        surname {
          en
        }
      }
      recipients {
        id
        name {
          en
        }
        surname {
          en
        }
        letters {
          public
          year
          id
          authors {
            author {
              name {
                en
              }
              surname {
                en
              }
              id
            }
          }

          letterDate
          letterRecipient {
            name {
              en
            }
            surname {
              en
            }
          }
          letterPlace
          baselref
          originalLang
          originalLangPubs {
            id
          }
          allPubs {
            id
            bibref
            lang
            year
            pressHouse {
              name
            }
          }
        }
      }
    }
  }
`;

const DELETEPUBLICATION = gql`
  mutation deletePublication($id: ID!) {
    deletePublication(id: $id) {
      ok
      id
    }
  }
`;

const LettersList: FC<{ author: Omit<Author, "works"> }> = ({ author }) => {
  const [nameFilter, setNameFilter] = useState("");

  const [visibleRecipient, setVisibleRecipient] = useState<string | null>(null);

  const { loading, error, data, refetch } = useQuery<WorkLettersData>(
    LETTERSMAP,
    {
      variables: { author: author.id! },
      fetchPolicy: "no-cache",
    }
  );

  const [deletePublication] = useMutation<
    { deletePublication: { ok: boolean; id: string } },
    { id: string }
  >(DELETEPUBLICATION, {
    onCompleted({ deletePublication }) {
      if (deletePublication.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <H2>
        Letters by {author.name?.en} {author.surname?.en}
      </H2>
      <Input
        border="1px solid #ccc"
        marginBottom="10px"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        placeholder="Filter by name or surname"
        width="200px"
      />
      {data &&
        data.getLettersMap &&
        data.getLettersMap.recipients
          .filter(
            (recipient) =>
              recipient.name.en
                ?.toLowerCase()
                .includes(nameFilter.toLowerCase()) ||
              recipient.surname.en
                ?.toLowerCase()
                .includes(nameFilter.toLowerCase())
          )
          .map((recipient) => (
            <React.Fragment key={recipient.id}>
              {visibleRecipient === recipient.id && (
                <React.Fragment>
                  <H3 onClick={() => setVisibleRecipient(null)}>
                    To {recipient.name.en} {recipient.surname.en}
                  </H3>
                  <Table width="100%">
                    <thead>
                      <Row borderBottom="1px solid #aaa">
                        <Title width="15%">From (place)</Title>
                        <Title width="15%">Date</Title>
                        <Title width="10%">Language</Title>
                        <Title width="15%">Basel Ref.</Title>
                        <Title width="15%">Public</Title>
                        <Title width="*"></Title>
                      </Row>
                    </thead>
                    <tbody>
                      {recipient.letters.map((letter) => (
                        <LetterPublications
                          author={author.id!}
                          recipient={recipient.id}
                          refetchHandler={() => refetch()}
                          deleteHandler={(id) =>
                            deletePublication({
                              variables: { id },
                            })
                          }
                          key={letter.id!}
                          workLetterProp={cloneDeep(letter)}
                        />
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
              {visibleRecipient !== recipient.id &&
                recipient.letters.length > 0 && (
                  <RecipientItem
                    onClick={() => setVisibleRecipient(recipient.id)}
                  >
                    {recipient.name.en} {recipient.surname.en}
                    {` (${recipient.letters.length} letter${
                      recipient.letters.length > 1 ? "s)" : ")"
                    }`}
                  </RecipientItem>
                )}
            </React.Fragment>
          ))}
    </div>
  );
};

export default LettersList;

const RecipientItem = styled.div`
  font-size: 0.9rem;
  cursor: pointer;
`;
