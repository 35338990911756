import React, { FC } from "react";
import { useMutation, gql } from "@apollo/client";
import styled from "@emotion/styled";
import { Helper, WhiteButton } from "../styles/styledcomponets";
import { ReactComponent as UploadIcon } from "../static/icons/upload.svg";
import { LoaderLarge } from "./Loaders";

const SINGLE_UPLOAD = gql`
  mutation ($type: String!, $name: String!) {
    singleUpload(type: $type, name: $name) {
      fileName
      url
    }
  }
`;

interface IFileUploaderProps {
  helper?: string;
  text: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  setFileURL: (url: string) => void;
  type: string;
  name?: string;
}

const FileUploaderComponent: FC<IFileUploaderProps> = ({
  helper,
  text,
  width,
  height,
  margin,
  padding,
  setFileURL,
  type
}) => {
  const [uploadFile, { loading, error }] = useMutation<
    {
      singleUpload: {
        fileName;
        url;
      };
    },
    { type: string; name: string }
  >(SINGLE_UPLOAD, {
    onError(error) {
      console.log(error);
    }
  });
  const onChange = async ({
    target: {
      validity,
      files: [file]
    }
  }: any) => {
    if (validity.valid) {
      const res = await uploadFile({ variables: { type, name: file.name } });
      // use url as google storage put-signed url to upload file
      try {
        const response = await fetch(res.data?.singleUpload.url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream"
          },
          body: file
        });
        console.log("File upload successful");
        setFileURL(res.data?.singleUpload.fileName);
        return res;
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (event) => {
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click();
    }
  };

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <div>{JSON.stringify(error, null, 2)}</div>;

  return (
    <Div {...{ margin, padding, width, height }}>
      {helper !== "" && <Helper>{helper}</Helper>}
      <WhiteButton onClick={handleClick}>
        <UploadIcon width="12px" style={{ margin: "0px 10px 0px 0px" }} />
        {text}
      </WhiteButton>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onChange}
        style={{ display: "none" }}
      />
    </Div>
  );
};

export default FileUploaderComponent;

const Div = styled.div<{
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
}>`
  width: "fit-content";
  height: ${(props) => props.height || "fit-content"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
`;
