import styled from "@emotion/styled";
import React, { FC } from "react";
import { colors } from "../theme";
import Icon from "./Icon";

interface IAvatarProps {
  large?: boolean;
  image?: string;
}

const Avatar: FC<IAvatarProps> = ({
  image,
  ...props
}) => <AvatarContent {...props}>{(image && <img src={image} /> || <div><Icon name="person" /></div>)}</AvatarContent>;

export default Avatar;

const AvatarContent = styled.div<{ large?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;

  > img, > div {
    border: 1px solid ${colors.gold[3]};
    border-radius: 50%;
    height: 70px;
    padding: 5px;
    width: 70px;
  }

  > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  ${props => !props.large && `
    > img, > div {
      height: 40px;
      width: 40px;
    }

    svg {
      transform: scale(0.8);
    }
  `}
`;
