import { gql, useMutation } from "@apollo/client";
import React, { FC } from "react";
import { v4 as uuidv4 } from "uuid";
import { WhiteButton } from "../../../styles/styledcomponets";
import { IWorkInputData, Langs, EditedWorkInput } from "../../../types";
import EditedWorkEditorComponent from "./EditedWorkEditorComponent";
import {
  AUTHORROLE,
  contents2JSON,
  contents2string,
  ContentsEntry,
  emptyLangs,
  LangTexts,
  Work,
  WorkEditedWork
} from "@balthasarspeyr/common";

const ADD_WORK = gql`
  mutation addWork($work: WorkInput!) {
    addWork(work: $work) {
      id
    }
  }
`;

const EditedWorkAdd: FC<{ workSaved: () => void }> = ({ workSaved }) => {
  const [addWork] = useMutation<
    { addWork: { id: string } },
    { work: Omit<EditedWorkInput, "id"> }
  >(ADD_WORK, {
    onCompleted({ addWork }) {
      workSaved();
    },
    onError(error) {
      console.log(error);
    }
  });

  const contentsTemplate: ContentsEntry = {
    id: "0",
    sign: "",
    children: [
      {
        id: uuidv4(),
        sign: "1",
        text: { ...emptyLangs },
        children: []
      }
    ],
    text: { ...emptyLangs }
  };

  const workID: string | undefined = undefined;

  let workCMSData: EditedWorkInput = {
    type: [],
    editedAuthor: "",
    authors: [],
    baselref: "",
    year: 1900,
    contents: contents2string(contentsTemplate),
    title: { ...emptyLangs },
    subtitle: { ...emptyLangs },
    caption: { ...emptyLangs },
    description: { ...emptyLangs },
    excerpt: { ...emptyLangs },
    public: false,
    originalLang: Langs.de,
    labels: []
  };

  const updateData = (data: Partial<EditedWorkInput>) => {
    workCMSData = {
      ...workCMSData,
      ...data
    };
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <WhiteButton
          onClick={() => {
            addWork({
              variables: {
                work: {
                  ...workCMSData
                }
              }
            });
          }}
        >
          Save
        </WhiteButton>
      </React.Fragment>
      <EditedWorkEditorComponent
        workCMSData={workCMSData}
        updateData={updateData}
      />

      <React.Fragment>
        <WhiteButton
          onClick={() => {
            addWork({
              variables: {
                work: workCMSData
              }
            });
          }}
        >
          Save
        </WhiteButton>
      </React.Fragment>
    </React.Fragment>
  );
};

export default EditedWorkAdd;
