import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../../styles/theme";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";

import { gql, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { Cell, Input, Row } from "../../styles/styledcomponets";
import {
  PressHouse,
  siteAuthors,
  sitePressHouse
} from "@balthasarspeyr/common";

const UPDATE_PRESSHOUSE = gql`
  mutation updatePressHouse($id: ID!, $pressHouse: PressHouseInput!) {
    updatePressHouse(id: $id, pressHouse: $pressHouse) {
      id
      name
      country
      website
    }
  }
`;

interface PressHouseEditProps {
  pressHouseProp: PressHouse;
  deleteHandler: () => void;
  refetchHandler: () => void;
}

type UpdatePressHouseInput = PressHouse;

const PressHouseEdit: FC<PressHouseEditProps> = ({
  pressHouseProp,
  deleteHandler,
  refetchHandler
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [pressHouse, setPressHouse] = useState<PressHouse>(
    cloneDeep(pressHouseProp)
  );
  const [backPressHouse, setBackPressHouse] = useState<PressHouse>(
    cloneDeep(pressHouseProp)
  );
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const [updatePressHouse] = useMutation<
    { updatePressHouse: PressHouse },
    { id: string; pressHouse: UpdatePressHouseInput }
  >(UPDATE_PRESSHOUSE, {
    onCompleted({ updatePressHouse }) {
      if (updatePressHouse) {
        setEditing(false);
        setPendingChange(false);
        setBackPressHouse(cloneDeep(pressHouse));
        refetchHandler();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const valueChange = (value, key: string) => {
    pressHouse[key] = value;
    setPressHouse({ ...pressHouse });
    setPendingChange(true);
  };

  const onDiscard = () => {
    setEditing(false);
    setPendingChange(false);
    setPressHouse(backPressHouse);
  };

  const onSave = () => {
    const { publications, ...pressHouseInput } = pressHouse;
    updatePressHouse({
      variables: {
        id: pressHouse.id!,
        pressHouse: pressHouseInput
      }
    });
  };

  return (
    <Row>
      <Cell>{pressHouse.publications?.length || 0}</Cell>
      <Cell>
        <Input
          width="95%"
          type="text"
          name="name"
          marginBottom="5px"
          editing={editing}
          onClick={() => setEditing(true)}
          onChange={(e) => valueChange(e.target.value, "name")}
          value={pressHouse.name || ""}
        />
      </Cell>
      <Cell>
        <Input
          width="95%"
          type="text"
          name="website"
          marginBottom="5px"
          editing={editing}
          onClick={() => setEditing(true)}
          onChange={(e) => valueChange(e.target.value, "website")}
          value={pressHouse.website || ""}
        />
      </Cell>
      <Cell>
        <Input
          width="95%"
          type="text"
          marginBottom="5px"
          editing={editing}
          name="country"
          onClick={() => setEditing(true)}
          onChange={(e) => valueChange([e.target.value], "country")}
          value={pressHouse.country?.[0] || ""}
        />
      </Cell>
      <Cell>
        <Actions editing={editing}>
          {pendingChange ? (
            <Save
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Save>
          ) : null}
          {pendingChange ? (
            <Discard
              onClick={() => {
                onDiscard();
              }}
            >
              Discard
            </Discard>
          ) : null}
          {sitePressHouse.id !== pressHouse.id && (
            <TrashIcon
              onClick={() => {
                if (
                  confirm(
                    `Are you sure you want to delete this PressHouse? (you will remove ${
                      pressHouse.publications?.length || 0
                    } publications)`
                  )
                ) {
                  deleteHandler();
                }
              }}
            />
          )}
        </Actions>
      </Cell>
    </Row>
  );
};

export default PressHouseEdit;

interface IInputProps {
  editing: boolean;
}

interface IActionsProps {
  editing: boolean;
}
const Actions = styled.div<IActionsProps>`
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;

const Save = styled.button`
  background-color: ${theme.colors.brandBlack};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;

const Discard = styled.button`
  background-color: ${theme.colors.brandRed};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;
