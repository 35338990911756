import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { InputTextComponent } from "../InputTextComponent";
import { WhiteButton } from "../../styles/styledcomponets";
import { useQuery, gql } from "@apollo/client";
import { LoaderLarge } from "../Loaders";
import { Author, AUTHORROLE } from "@balthasarspeyr/common";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { theme } from "../../styles/theme";
import AuthorRoleSelect from "../AuthorRoleSelect";

type AuthorAndRole = {
  id: string;
  role: AUTHORROLE;
};
const AUTHORS = gql`
  query getAuthors {
    getAuthors {
      id
      name {
        en
      }
      surname {
        en
      }
    }
  }
`;

const AddAuthors: FC<{
  authors: AuthorAndRole[];
  updateAuthors: (authors: AuthorAndRole[]) => void;
}> = ({ authors: authorProps, updateAuthors }) => {
  const { data, loading, error } =
    useQuery<{ getAuthors: Partial<Author>[] }>(AUTHORS);

  const [filter, setFilter] = useState<string>("");
  const [authors, setAuthors] = useState<
    { author: Author; role: AUTHORROLE }[]
  >([]);

  const [selectedRoles, setSelectedRoles] = useState<{
    [key: string]: AUTHORROLE;
  }>({});

  useEffect(() => {
    if (data) {
      const authors = authorProps.map((author) => ({
        author: {
          ...data.getAuthors.find((a: Partial<Author>) => a.id === author.id)
        } as Author,
        role: author.role
      }));

      setAuthors([...authors]);
    }
  }, [data, authorProps]);

  if (loading) return <LoaderLarge />;
  if (error) return <p>Error :( </p>;

  return (
    <Div>
      <strong>Authors</strong>:
      {authors.map((author) => (
        <AuthorDiv key={author.author.id}>
          <div>
            {author.author.name.en} {author.author.surname.en}
          </div>
          <div
            style={{
              margin: "0 0 0 10px",
              fontStyle: "italic"
            }}
          >
            {AUTHORROLE[author.role]}
          </div>

          <div>
            <TrashIcon
              onClick={() => {
                updateAuthors(
                  authors
                    .filter(
                      (t) =>
                        t.author.id !== author.author.id ||
                        t.role !== author.role
                    )
                    .map((author) => ({
                      id: author.author.id,
                      role: author.role
                    }))
                );
              }}
            />
          </div>
        </AuthorDiv>
      ))}
      <InputTextComponent
        width="auto"
        helper="Add author (search by name)"
        placeholder="name or surname"
        value={filter}
        onChange={(value) => {
          setFilter(value);
        }}
      />
      {filter.length >= 1 &&
        data!.getAuthors
          .filter(
            (author) =>
              (author?.name?.en || "")
                .toLowerCase()
                .includes(filter.toLowerCase()) ||
              (author?.surname?.en || "")
                .toLowerCase()
                .includes(filter.toLowerCase())
          )
          .map((author) => (
            <AuthorDiv key={author.id}>
              {author?.name?.en || ""} {author?.surname?.en || ""}
              <AuthorRoleSelect
                margin="0 0 0 10px"
                hideHelper={true}
                value={selectedRoles[author!.id!] || AUTHORROLE.Author}
                onChange={(e) => {
                  setSelectedRoles({
                    ...selectedRoles,
                    [author!.id!]: e.target.value as AUTHORROLE
                  });
                }}
              />
              <WhiteButton
                margin="0 0 0 10px"
                onClick={() => {
                  setFilter("");
                  updateAuthors([
                    ...authors.map((a) => ({
                      id: a.author.id,
                      role: a.role
                    })),
                    {
                      id: author.id!,
                      role: selectedRoles[author.id!] || AUTHORROLE.Author
                    }
                  ]);
                }}
              >
                Add
              </WhiteButton>
            </AuthorDiv>
          ))}
    </Div>
  );
};

export default AddAuthors;

const Div = styled.div`
  width: fit-content;
  margin: 0 20px 0 0;
`;

const AuthorDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    min-width: 10px;
    width: 10px;
    min-height: 10px;
    height: 10px;
    margin: 0px 0px 0px 10px;
    padding: 0px;
    cursor: pointer;
    :hover {
      fill: ${theme.colors.brandRed};
    }
  }
  margin: 0px;
  padding: 0px;
`;
