import { LangTexts, SITELANGS } from "@balthasarspeyr/common";
import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import {
  BlackButton,
  Cell,
  RedButton,
  Row,
  WhiteButton
} from "../../styles/styledcomponets";
import { theme } from "../../styles/theme";

import { AddNewsInput } from "../../types";

interface NewsAddProps {
  addHandler: (news: AddNewsInput) => void;
}

const langs = Object.keys(SITELANGS);

const emptyLangs: LangTexts = Object.values(SITELANGS).reduce((acc, lang) => {
  acc[lang] = "";
  return acc;
}, {});
const NewsAdd: FC<NewsAddProps> = ({ addHandler }) => {
  const [news, setNews] = useState<AddNewsInput>({
    title: { ...emptyLangs },
    body: { ...emptyLangs },
    url: { ...emptyLangs },
    linkText: { ...emptyLangs },
    start: new Date().toISOString(),
    end: new Date().toISOString()
  });

  const cleanNews: AddNewsInput = {
    title: { ...emptyLangs },
    body: { ...emptyLangs },
    url: { ...emptyLangs },
    linkText: { ...emptyLangs },
    start: new Date().toISOString(),
    end: new Date().toISOString()
  };

  const [pendingChange, setPendingChange] = useState<boolean>(false);
  const [multilang, setMultilang] = useState<boolean>(false);

  const valueChange = (value, key, lang?) => {
    const newNews: AddNewsInput = { ...news };
    newNews[key][lang] = value;
    setNews(newNews);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setNews({ ...cleanNews });
  };

  const onAdd = () => {
    addHandler(news);
    setNews({ ...cleanNews });
  };

  return (
    <React.Fragment>
      {langs.map(
        (lang) =>
          (multilang || lang === "en") && (
            <Row key={`new${lang}`}>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "title", lang)}
                  value={news.title![lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "body", lang)}
                  value={news.body![lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "url", lang)}
                  value={news.url?.[lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) =>
                    valueChange(e.target.value, "linkText", lang)
                  }
                  value={news.linkText?.[lang] || ""}
                />
              </Cell>

              {lang === "en" ? (
                <Cell>
                  <Actions>
                    {multilang ? (
                      <WhiteButton onClick={() => setMultilang(false)}>
                        View on English
                      </WhiteButton>
                    ) : (
                      <WhiteButton onClick={() => setMultilang(true)}>
                        View all langs.
                      </WhiteButton>
                    )}
                    <BlackButton
                      onClick={(e) => {
                        onAdd();
                      }}
                    >
                      Add
                    </BlackButton>

                    {pendingChange ? (
                      <RedButton
                        onClick={() => {
                          onDiscard();
                        }}
                      >
                        Discard
                      </RedButton>
                    ) : null}
                  </Actions>
                </Cell>
              ) : (
                <Cell>
                  <Actions />
                </Cell>
              )}
            </Row>
          )
      )}
    </React.Fragment>
  );
};

export default NewsAdd;

const Input = styled.input`
  width: 95%;
  background-color: ${theme.colors.brandWhite};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
`;
