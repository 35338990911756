import React, { FC, useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Label, LangTexts, SITELANGS } from "@balthasarspeyr/common";
import { LoaderLarge } from "../Loaders";
import {
  Actions,
  BlackButton,
  Cell,
  Input,
  RedButton,
  Row,
  Table,
  Title,
  WhiteButton
} from "../../styles/styledcomponets";
import MultilingualInput from "../MultilingualInput";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { cloneDeep } from "@apollo/client/utilities";

const LABELS = gql`
  query getLabels {
    getLabels {
      id
      label {
       ${Object.values(SITELANGS).join("\n")}
      }
      public
    }
  }
`;

const ADD_LABEL = gql`
  mutation addLabel($label: LabelInput!) {
    addLabel(label: $label) {
      id
    }
  }
`;

const UPDATE_LABEL = gql`
  mutation updateLabel($id: ID!, $label: LabelInput!) {
    updateLabel(id: $id, label: $label) {
      id
    }
  }
`;

const DELETE_LABEL = gql`
  mutation deleteLabel($id: ID!) {
    deleteLabel(id: $id) {
      ok
      id
    }
  }
`;

const LabelsCMSLayout: FC = () => {
  const { loading, error, data, refetch } = useQuery(LABELS);
  const [addLabel] = useMutation<
    { addLabel: Label },
    { label: Omit<Label, "id"> }
  >(ADD_LABEL, {
    onCompleted({ addLabel }) {
      if (addLabel) {
        refetch();
        setNewLabel({
          label: Object.values(SITELANGS).reduce((acc, lang) => {
            acc[lang] = "";
            return acc;
          }, {}),
          public: false
        });
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const [updateLabel] = useMutation<
    { updateLabel: Label },
    { id: string; label: Omit<Label, "id"> }
  >(UPDATE_LABEL, {
    onCompleted({ updateLabel }) {
      if (updateLabel) {
        refetch();
        const index = pendingChange.indexOf(updateLabel.id);
        if (index > -1) {
          pendingChange.splice(index, 1);
          setPendingChange([...pendingChange]);
        }
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const [deleteLabel] = useMutation<
    { deleteLabel: { ok: boolean; id: string } },
    { id: string }
  >(DELETE_LABEL, {
    onCompleted({ deleteLabel }) {
      if (deleteLabel) {
        refetch();
        const index = pendingChange.indexOf(deleteLabel.id);
        if (index > -1) {
          pendingChange.splice(index, 1);
          setPendingChange([...pendingChange]);
        }
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const [pendingChange, setPendingChange] = useState<string[]>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [multilang, setMultilang] = useState<string>("");
  const [labels, setLabels] = useState<Label[]>([]);
  const [newLabel, setNewLabel] = useState<Omit<Label, "id">>({
    label: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    public: false
  });

  useEffect(() => {
    if (data) {
      setLabels(data.getLabels);
    }
  }, [data]);

  const onChangeLabel = (id: string, value: LangTexts) => {
    const labelsClone = cloneDeep(labels);
    const label = labelsClone.find((label) => label.id === id);
    if (label) {
      label.label = { ...value };
      setLabels([...labelsClone]);
      !pendingChange.some((label) => label === id) &&
        setPendingChange([...pendingChange, id]);
    }
  };

  const onChangePublic = (id: string, value: boolean) => {
    const newLabels = cloneDeep(labels);
    const label = newLabels.find((l) => l.id === id);

    if (label) {
      label.public = value;
      setLabels(newLabels);
      !pendingChange.some((label) => label === id) &&
        setPendingChange([...pendingChange, id]);
    }
  };

  const saveChanges = (id) => {
    const labelToChange = labels.find((label) => label.id === id);
    if (labelToChange) {
      const { id, ...label } = labelToChange;
      updateLabel({
        variables: {
          id,
          label
        }
      });
    } else {
      console.error("Label not found");
    }
  };

  if (loading) <LoaderLarge />;
  if (error) <p>Error :(</p>;

  return (
    <div>
      <Table>
        <thead>
          <Row borderBottom="1px solid grey">
            <Title>Name</Title>
            <Title>Public</Title>
            <Title>&nbsp;</Title>
          </Row>
        </thead>
        <tbody>
          {labels.map((label) => (
            <Row key={label.id}>
              <Cell>
                <MultilingualInput
                  marginBottom="5px"
                  value={label.label}
                  onChange={(value) => {
                    setEditing(true);
                    onChangeLabel(label.id, value);
                  }}
                  editing={editing}
                  multilang={multilang === label.id}
                />
              </Cell>
              <Cell>
                <Input
                  width="auto"
                  height="auto"
                  onClick={() => setEditing(true)}
                  placeholder="Public"
                  type="checkbox"
                  onChange={(e) => {
                    onChangePublic(label.id, e.target.checked);
                  }}
                  checked={label.public || false}
                />
              </Cell>
              <Cell>
                <Actions>
                  {pendingChange.includes(label.id) && (
                    <WhiteButton onClick={() => saveChanges(label.id)}>
                      Save
                    </WhiteButton>
                  )}
                  <TrashIcon
                    onClick={() => {
                      if (
                        confirm(
                          "Are you sure you want to delete this label? (you will remove this label from all works. Works will be kept)"
                        )
                      ) {
                        deleteLabel({
                          variables: {
                            id: label.id
                          }
                        });
                      }
                    }}
                    width="20px"
                    height="20px"
                  />
                  {!multilang && (
                    <BlackButton onClick={() => setMultilang(label.id)}>
                      View all langs.
                    </BlackButton>
                  )}
                  {multilang && (
                    <BlackButton onClick={() => setMultilang("")}>
                      View one lang.
                    </BlackButton>
                  )}
                </Actions>
              </Cell>
            </Row>
          ))}
          <Row>
            <Cell>
              <MultilingualInput
                marginBottom="5px"
                editing={true}
                multilang={true}
                onChange={(value) => {
                  setNewLabel({ ...newLabel, label: value });
                }}
                value={newLabel.label}
              />
            </Cell>
            <Cell>
              <Input
                editing={true}
                width="auto"
                height="auto"
                placeholder="Public"
                type="checkbox"
                onChange={(e) => {
                  setNewLabel({ ...newLabel, public: e.target.checked });
                }}
                checked={newLabel.public || false}
              />
            </Cell>
            <Cell>
              <Actions>
                <WhiteButton
                  onClick={() => {
                    addLabel({
                      variables: {
                        label: { ...newLabel }
                      }
                    });
                  }}
                >
                  Add
                </WhiteButton>
                <RedButton
                  onClick={() => {
                    setNewLabel({
                      label: Object.values(SITELANGS).reduce((acc, lang) => {
                        acc[lang] = "";
                        return acc;
                      }, {}),
                      public: false
                    });
                  }}
                >
                  Clear
                </RedButton>
              </Actions>
            </Cell>
          </Row>
        </tbody>
      </Table>
    </div>
  );
};

export default LabelsCMSLayout;
