import { gql, useMutation } from "@apollo/client";
import React, { FC } from "react";
import { v4 as uuidv4 } from "uuid";
import { WhiteButton } from "../../../styles/styledcomponets";
import { Langs, MainWorkInput } from "../../../types";
import MonographicEditorComponent from "./MonographicEditorComponent";
import {
  contents2string,
  ContentsEntry,
  emptyLangs,
  WORKTYPE
} from "@balthasarspeyr/common";

const ADD_WORK = gql`
  mutation addWork($work: WorkInput!) {
    addWork(work: $work) {
      id
    }
  }
`;

const MonographicAdd: FC<{ workSaved: () => void }> = ({ workSaved }) => {
  const [addWork] = useMutation<
    { addWork: { id: string } },
    { work: Omit<MainWorkInput, "id"> }
  >(ADD_WORK, {
    onCompleted({ addWork }) {
      workSaved();
    },
    onError(error) {
      console.log(error);
    }
  });

  const contentsTemplate: ContentsEntry = {
    id: "0",
    sign: "",
    children: [
      {
        id: uuidv4(),
        sign: "1",
        text: { ...emptyLangs },
        children: []
      }
    ],
    text: { ...emptyLangs }
  };

  let workCMSData: MainWorkInput = {
    type: [WORKTYPE.MAINWORK],
    authors: [],
    baselref: "",
    year: 1900,
    contents: contents2string(contentsTemplate),
    title: { ...emptyLangs },
    subtitle: { ...emptyLangs },
    caption: { ...emptyLangs },
    description: { ...emptyLangs },
    excerpt: { ...emptyLangs },
    public: false,
    originalLang: Langs.de,
    labels: []
  };

  const updateData = (data: Partial<MainWorkInput>) => {
    workCMSData = {
      ...workCMSData,
      ...data
    };
  };

  return (
    <React.Fragment>
      {workCMSData.public ? (
        <WhiteButton>Save</WhiteButton>
      ) : (
        <React.Fragment>
          <WhiteButton
            onClick={() => {
              addWork({
                variables: {
                  work: {
                    ...workCMSData,
                    public: true
                  }
                }
              });
            }}
          >
            Publish
          </WhiteButton>
          <WhiteButton
            onClick={() => {
              addWork({
                variables: {
                  work: {
                    ...workCMSData
                  }
                }
              });
            }}
          >
            Save Draft
          </WhiteButton>
        </React.Fragment>
      )}
      <MonographicEditorComponent
        workCMSData={workCMSData}
        updateData={updateData}
      />
      {workCMSData.public ? (
        <WhiteButton>Save</WhiteButton>
      ) : (
        <React.Fragment>
          <WhiteButton
            onClick={() => {
              addWork({
                variables: {
                  work: {
                    ...workCMSData,
                    public: true
                  }
                }
              });
            }}
          >
            Publish
          </WhiteButton>
          <WhiteButton
            onClick={() => {
              addWork({
                variables: {
                  work: workCMSData
                }
              });
            }}
          >
            Save Draft
          </WhiteButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MonographicAdd;
