import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, {
  FC,
  ComponentProps,
  HTMLAttributes,
  forwardRef,
  LegacyRef,
} from "react";
import { breakpoints } from "../theme";
import colors from "../theme/colors";
import DotsTyping from "./DotsTyping";
import Icon from "./Icon";

export interface IButtonProps
  extends Omit<ComponentProps<"button">, "ref">,
    Omit<
      HTMLAttributes<HTMLButtonElement>,
      | "aria-relevant"
      | "children"
      | "onBlur"
      | "onBlurCapture"
      | "onFocus"
      | "onFocusCapture"
      | "onKeyDown"
      | "onKeyDownCapture"
      | "onKeyPress"
      | "onKeyPressCapture"
      | "onKeyUp"
      | "onKeyUpCapture"
      | "style"
      | "ref"
    > {
  className?: string;
  dark?: boolean;
  iconName?: string;
  loading?: boolean;
  secondary?: boolean;
}

const Button: FC<IButtonProps> = forwardRef(
  ({ iconName, type = "button", ...props }, ref) => (
    <StyledButton
      type={type}
      ref={ref as LegacyRef<HTMLButtonElement>}
      {...props}
    >
      {props.loading ? <DotsTyping /> : props.children}
      {iconName && <Icon name={iconName} />}
    </StyledButton>
  )
);

Button.displayName = "Button";

export default Button;

const StyledButton = styled.button<IButtonProps>`
  align-items: center;
  background-color: ${(props) =>
    props.dark ? colors.blue[3] : colors.gold[1]};
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.white};
  cursor: pointer;
  ${(props) => !props.hidden && "display: flex"};
  flex-shrink: 0;
  min-height: 40px;
  justify-content: center;
  padding: 10px 15px 8px 15px;

  svg {
    color: ${colors.white};
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:hover {
    background-color: ${(props) =>
      props.dark ? colors.blue[4] : colors.gold[0]};
  }

  &[disabled] {
    background-color: ${(props) =>
      props.dark ? colors.blue[1] : colors.grey[2]};
    color: ${(props) => (props.dark ? colors.white : colors.grey[0])};
    cursor: not-allowed;
    pointer-events: none;

    svg {
      color: ${(props) => (props.dark ? colors.white : colors.grey[0])};
    }
  }

  ${(props) =>
    props.secondary &&
    css`
      background-color: ${props.dark ? colors.grey[0] : colors.grey[1]};
      color: ${colors.black};

      svg {
        color: ${colors.black};
      }
    
      &:active, &:hover {
        background-color: ${props.dark ? colors.grey[2] : colors.grey[0]};
      }
    `}

  ${(props) =>
    props.loading &&
    css`
      background-color: ${props.secondary ? (props.dark ? colors.grey[2] : colors.grey[0]) : (props.dark ? colors.blue[4] : colors.gold[0])};
      cursor: not-allowed;
      pointer-events: none;
    `}

  @media screen and (min-width: ${breakpoints.mobile}px) {
    padding: 10px 20px 8px 20px;
  }
`;
