import React, { FC, useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { InputTextComponent } from "../InputTextComponent";
import { Cell, Row, Table, WhiteButton } from "../../styles/styledcomponets";
import { useQuery, gql } from "@apollo/client";

import { ContainedWork, SITELANGS, Work } from "@balthasarspeyr/common";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { theme } from "../../styles/theme";

type ContainedWorksIDs = Omit<ContainedWork, "work"> & {
  work: string;
};

const WORKSBYID = gql`
  query getWorksByID($ids: [ID!]!) {
    getWorksByID(ids: $ids) {
      id
      title {
       ${Object.values(SITELANGS).join("\n")}
      }
      originalLang
      type
      baselref
      year
    }
  }
`;

const WORKS = gql`
  query getWorks($filter: WorksFilter!) {
    getWorks(filter: $filter) {
      data {
        id
        authors {
          author {
            name {
              en
            }
            surname {
              en
            }
          }
        }
        title {
         ${Object.values(SITELANGS).join("\n")}
        }
        originalLang
        type
        baselref
        year
      }
    }
  }
`;

const AddContainedWorks: FC<{
  authors?: string[];
  containedWorks: Array<Omit<ContainedWork, "work"> & { work: string }>;
  updateContainedWorks: (containedWorks: ContainedWorksIDs[]) => void;
}> = ({
  authors,
  containedWorks: containedWorksProps,
  updateContainedWorks
}) => {
  const inputSearchText = useRef<string | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [containedWorks, setContainedWorks] = useState<
    Partial<ContainedWork>[]
  >([]);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [works, setWorks] = useState<Partial<Work>[]>([]);

  const {
    data: dataWorks,
    loading: loadingWorks,
    error: errorWorks,
    refetch: refetchWorks
  } = useQuery<{
    getWorks: { data: Partial<Work>[] };
  }>(WORKS, {
    variables: {
      filter: {
        authors: authors,
        searchText: searchText
      }
    },
    skip: !searchText,
    onCompleted: (data) => {
      const works = data.getWorks.data;
      setWorks(works);
      setSearchText(undefined);
    },
    fetchPolicy: "no-cache"
  });

  const {
    data: dataCW,
    loading: loadingCW,
    error: errorCW
  } = useQuery<{ getWorksByID: Partial<Work>[] }>(WORKSBYID, {
    variables: { ids: containedWorksProps.map((cw) => cw.work) },
    skip: !firstTime,
    onCompleted: (data) => {
      const containedWorks = data.getWorksByID.map((w) => ({
        work: { ...(w as Work) },
        full: containedWorksProps.find((cw) => cw.work === w.id)?.full || false
      }));
      setContainedWorks(containedWorks);
      setFirstTime(false);
    }
  });

  // useEffect(() => {
  //   if (dataCW) {
  //     const containedWorks = dataCW.getWorksByID.map((w) => ({
  //       work: { ...(w as Work) }
  //     }));
  //     setContainedWorks(containedWorks);
  //   }
  // }, [dataCW]);

  const addWorkToContainedWorks = (work: Partial<Work>, full: boolean) => {
    const newContainedWorks = [
      ...(containedWorks as ContainedWork[]),
      { work, full, parts: [] } as ContainedWork
    ];

    // remove duplicates and return
    return newContainedWorks.filter((cw, index, self) => {
      return self.findIndex((cw2) => cw2.work.id === cw.work.id) === index;
    });
  };

  const removeWorkFromContainedWorks = (work: Partial<Work>) => {
    const newContainedWorks = containedWorks.filter(
      (cw) => cw.work?.id !== work.id
    );
    return newContainedWorks as ContainedWork[];
  };

  // if (loadingWorks || loadingCW) return <LoaderLarge />;
  if (errorWorks || errorCW) return <p>Error :( </p>;

  return (
    <Div>
      <Table>
        <tbody>
          {containedWorks.map(({ work: w, full }, index) => (
            <Row key={w?.id || index} height={"30px"}>
              <Cell padding={"0 20px 0 0px"}>
                <strong>
                  {w?.title?.[w.originalLang ? w.originalLang : "en"] ||
                    w?.title?.["en"]}
                </strong>
              </Cell>
              <Cell padding={"0 20px 0 0px"}>{full ? "full" : "partial"}</Cell>
              <Cell padding={"0 20px 0 0px"}>{w?.type?.join(", ") || ""}</Cell>
              <Cell padding={"0 20px 0 0px"}>{w?.year || ""}</Cell>
              <Cell padding={"0 20px 0 0px"}>
                {w?.baselref || "no basel ref"}
              </Cell>
              <Cell padding={"0 20px 0 0px"}>
                {w?.authors
                  ?.map(
                    (a) => `${a.author?.surname.en || a.author?.name.en || ""}`
                  )
                  .join(", ") || ""}
              </Cell>
              <Cell>
                <Actions>
                  <TrashIcon
                    onClick={() => {
                      const contWorks = removeWorkFromContainedWorks(w!);
                      updateContainedWorks(
                        contWorks.map((cw) => ({
                          ...cw,
                          work: cw.work.id!
                        }))
                      );
                      setContainedWorks([...contWorks]);
                    }}
                  />
                </Actions>
              </Cell>
            </Row>
          ))}
        </tbody>
      </Table>
      <SearchDiv>
        <InputTextComponent
          width="auto"
          helper="Add Contained Work (search by title)"
          placeholder="title"
          defaultValue={searchText || ""}
          onChange={(value) => {
            inputSearchText.current = value;
          }}
        />
        <WhiteButton
          onClick={() => {
            setSearchText(inputSearchText.current);
          }}
        >
          Search
        </WhiteButton>
      </SearchDiv>
      <Table>
        <tbody>
          {works.map((w) => (
            <Row key={w.id} height={"30px"}>
              <Cell padding={"0 20px 0 0px"}>
                <strong>
                  {w.title?.[w.originalLang ? w.originalLang : "en"] || ""}
                </strong>
              </Cell>
              <Cell padding={"0 20px 0 0px"}>{w?.type?.join(", ") || ""}</Cell>
              <Cell padding={"0 20px 0 0px"}>{w.year || ""}</Cell>
              <Cell padding={"0 20px 0 0px"}>
                {w.baselref || "no basel ref"}
              </Cell>
              <Cell padding={"0 20px 0 0px"}>
                {w.authors
                  ?.map(
                    (a) => `${a.author?.surname.en || a.author?.name.en || ""}`
                  )
                  .join(", ") || ""}
              </Cell>
              <Cell>
                <WhiteButton
                  margin="0 0 0 10px"
                  onClick={() => {
                    setSearchText(undefined);
                    const contworks = addWorkToContainedWorks(w, true);
                    updateContainedWorks(
                      contworks.map((cw) => ({
                        ...cw,
                        work: cw.work.id!
                      }))
                    );
                    setContainedWorks([...contworks]);
                  }}
                >
                  Add Full
                </WhiteButton>
              </Cell>
              <Cell>
                <WhiteButton
                  margin="0 0 0 10px"
                  onClick={() => {
                    setSearchText(undefined);
                    const contworks = addWorkToContainedWorks(w, false);
                    updateContainedWorks(
                      contworks.map((cw) => ({
                        ...cw,
                        work: cw.work.id!
                      }))
                    );
                    setContainedWorks([...contworks]);
                  }}
                >
                  Add Partial
                </WhiteButton>
              </Cell>
            </Row>
          ))}
        </tbody>
      </Table>
    </Div>
  );
};

export default AddContainedWorks;

const Div = styled.div`
  width: fit-content;
  margin: 0 20px 0 0;
`;

const SearchDiv = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 20px 0 20px 0;
`;

const Actions = styled.div`
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    min-width: 10px;
    width: 10px;
    min-height: 10px;
    height: 10px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    :hover {
      fill: ${theme.colors.brandRed};
    }
  }
  margin: 0px;
  padding: 0px;
`;
