import React, { FC } from "react";
import styled from "@emotion/styled";
import { adjustColorOpacity, colors, fonts, zIndexes } from "../theme";
import DotsTyping from "./DotsTyping";

interface ILoadingProps {
  className?: string;
  loading?: boolean;
}

const Loading: FC<ILoadingProps> = ({ children, loading = true, ...props }) => loading ? (
  <Container {...props}>
    <Background />
    <Content>
      {children}
      <DotsTyping />
    </Content>
  </Container>
) : null;

export default Loading;

const Background = styled.div`
  background: ${adjustColorOpacity(colors.white, 0.5)};
  backdrop-filter: blur(6px);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  overflow: auto;
  width: 100%;
  z-index: ${zIndexes.loading};
`;

const Content = styled.div`
  ${fonts.family.theinhardt}
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
