interface IColor {
  [key: string]: string;
}

type ITheme = {
  breakpoints: string[];
  colors: IColor;
};

const theme: ITheme = {
  colors: {
    brandRed: "#eb3239",
    brandWhite: "#ffffff",
    brandGray: "#d2d3d6",
    brandBlack: "#202131",
    redViolet: "#9c2aa0",
    albergine: "#5e2750",
    aquaBlue: "#00b0ca",
    turquoise: "#007c92",
    springGreen: "#abb400",
    lemonYellow: "#fecb00",
    freshOrange: "#eb9700",
    maroon: "#990000",
    darkRed: "#bd0000",
    digitalGreen: "#428600",
    darkGreen: "#285200",
    black: "#202131",
    gray1: "#2b2c3c",
    gray2: "#363745",
    gray3: "#999999",
    gray4: "#afafaf",
    gray5: "#ebebeb",
    gray6: "#f4f4f4",
    gray7: "#cccccc",
    gray8: "#eeeeee",
    gold01: "#a59c82",
    gold02: "#bdb398",
    gold03: "#d4ccb3",
    gold04: "#e9e3d2"

  },
  breakpoints: ["768px", "1280px"],
};

export { theme };