const mobile = 375;
const tablet = 768;
const small = 1024;
const maxWidth = 1175;
const medium = 1280;
const large = 1366;
const wide = 1920;

export default {
  mobile,
  tablet,
  small,
  maxWidth,
  medium,
  large,
  wide
};
