import { gql, useMutation, useQuery } from "@apollo/client";
import {
  contents2JSON,
  ContentsEntry,
  JSON2Contents,
  Work,
  WorkMain
} from "@balthasarspeyr/common";
import React, { FC, useRef, useState } from "react";
import { WhiteButton, RedButton } from "../../../styles/styledcomponets";
import { MainWorkInput } from "../../../types";
import { LoaderLarge } from "../../Loaders";
import MessageModal from "../../modals/MessageModal";
import ThirdAuthorWorkEditorComponent from "./ThirdAuthorWorkEditorComponent";

const UPDATE_WORK = gql`
  mutation updateWork($id: ID!, $work: WorkInput!) {
    updateWork(id: $id, work: $work) {
      id
    }
  }
`;

type QueryWorkData = {
  getWork: Omit<WorkMain, "contents"> & {
    contents: string;
  };
};

const GET_WORK = gql`
  query getWork($id: ID!) {
    getWork(id: $id) {
      type
      labels {
        id
      }
      authors {
        author {
          id
        }
        role
      }
      year
      title {
        en
      }
      originalLang
    }
  }
`;

const ThirdAuthorWorkDetails: FC<{ id: string }> = ({ id }) => {
  const [saved, setSaved] = useState<boolean>(false);

  const [updateWork, { loading: mutationLoading }] = useMutation<
    { updateWork: { id: string } },
    { id: string; work: MainWorkInput }
  >(UPDATE_WORK, {
    refetchQueries: () => [
      {
        query: GET_WORK,
        variables: { id: id }
      }
    ],
    onCompleted: () => {
      setSaved(true);
    }
  });

  const { error, loading, data, refetch } = useQuery<QueryWorkData>(GET_WORK, {
    variables: { id: id }
  });

  const workCMSData = useRef<MainWorkInput | null>(null);

  if (loading || mutationLoading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <React.Fragment>Ups, error :(</React.Fragment>;

  const work: Omit<WorkMain, "contents"> & {
    contents: string;
  } = {
    ...data!.getWork!
  };

  workCMSData.current = {
    ...work,
    authors: work.authors.map((author) => {
      return { id: author.author.id, role: author.role };
    }),
    labels: work.labels.map((label) => label.id)
  };

  const updateData = (data: Partial<MainWorkInput>) => {
    workCMSData.current = {
      ...(workCMSData.current as MainWorkInput),
      ...data
    };
  };

  return (
    <React.Fragment>
      <WhiteButton
        onClick={() => {
          updateWork({
            variables: {
              id: id,
              work: { ...workCMSData.current!, public: false }
            }
          });
        }}
      >
        Save
      </WhiteButton>
      {saved && <MessageModal message="Saved" timeout={2000} />}
      <ThirdAuthorWorkEditorComponent
        workCMSData={workCMSData.current}
        updateData={updateData}
      />
    </React.Fragment>
  );
};

export default ThirdAuthorWorkDetails;
