import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { Icon } from ".";
import { adjustColorOpacity, colors, fonts, zIndexes } from "../theme";
import { IPositionArrowType, IPositionType } from "./Tooltip";

interface ITooltipErrorProps {
  className?: string;
  arrowPosition?: IPositionArrowType;
  error?: string;
  onClose?: () => void;
  position?: IPositionType;
  transform?: string;
}

const TooltipError: FC<ITooltipErrorProps> = ({
  arrowPosition = "left",
  children,
  error,
  onClose,
  position = "bottom",
  transform,
  ...props
}) => (
  <ErrorWrap {...props}>
    {children}
    {!!error && (
      <Error hasClose={!!onClose} transform={transform}>
        <Arrow position={position} arrowPosition={arrowPosition} />
        <div>
          {error}
          {onClose && (
            <CloseButton onClick={onClose}>
              <Icon name="close" />
            </CloseButton>
          )}
        </div>
      </Error>
    )}
  </ErrorWrap>
);

export default TooltipError;

const Arrow = styled.div<{
  arrowPosition: IPositionArrowType;
  position: IPositionType;
}>`
  background-color: inherit;
  height: 9px;
  position: absolute;
  width: 9px;

  ${(props) => {
    switch (props.position) {
      case "bottom":
        return css`
          border-top-left-radius: 3px;
          left: ${props.arrowPosition === "middle"
            ? "50%"
            : props.arrowPosition === "left"
            ? "9px"
            : "calc(100% - 19px)"};
          top: 0;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case "left":
        return css`
          border-top-right-radius: 3px;
          right: 0;
          top: 50%;
          transform: translate(50%, -50%) rotate(45deg);
        `;
      case "right":
        return css`
          border-bottom-left-radius: 3px;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case "top":
        return css`
          border-bottom-right-radius: 3px;
          left: ${props.arrowPosition === "middle"
            ? "50%"
            : props.arrowPosition === "left"
            ? "9px"
            : "calc(100% - 19px)"};
          bottom: 0;
          transform: translate(-50%, 50%) rotate(45deg);
        `;
    }
  }}
`;

const CloseButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;

  svg {
    transform: scale(0.8);
  }
`;

const Error = styled.div<{ hasClose?: boolean; transform?: string }>`
  ${fonts.family.theinhardt}
  ${fonts.size.smallExtra}

  background-color: ${colors.red[0]};
  border-radius: 5px;
  box-shadow: 0 10px 20px 5px ${adjustColorOpacity(colors.black, 0.2)};
  box-sizing: border-box;
  color: ${colors.white};
  margin: 10px 0;
  max-width: 100%;
  padding: ${(props) => (props.hasClose ? "5px 10px 5px 15px" : "10px 15px")};
  position: absolute;
  z-index: ${zIndexes.modal};

  ${(props) => props.transform && `transform: ${props.transform};`}

  > div:last-of-type {
    align-items: center;
    display: flex;
  }
`;

const ErrorWrap = styled.div`
  position: relative;
`;
