import React, { FC } from "react";
import { Publication, SITELANGS } from "@balthasarspeyr/common";
import { PublicationImage } from "@balthasarspeyr/ui";
import { gql, useQuery } from "@apollo/client";
import { LoaderLarge } from "../Loaders";
import styled from "@emotion/styled";
import { useState } from "react";

const GET_PUBLICATION = gql`
  query getPublication($id: ID!) {
    getPublication(id: $id) {
      title
      containedWorks {
        full
        work {
          originalLang
          authors {
            author {
              name {
               ${Object.values(SITELANGS).join("\n")}
              }
              surname {
               ${Object.values(SITELANGS).join("\n")}
              }
              covercolors {
                Mainwork
                Translation
                Anthology
                Article
                Contribution
                Preface
                Epilogue
                Letter
              }
            }
          }
        }
      }
    }
  }
`;

const CoverPreview: FC<{ id: string }> = ({ id }) => {
  const { loading, error, data } = useQuery<{ getPublication: Publication }>(
    GET_PUBLICATION,
    {
      variables: {
        id
      }
    }
  );

  const [show, setShow] = useState(false);
  if (error) return <div>Error</div>;
  if (loading) return <LoaderLarge />;

  return (
    <>
      <a onClick={() => setShow(!show)}>Show Cover</a>
      <Div>
        {show && (
          <CoverPreviewContainer onClick={() => setShow(!show)}>
            <PublicationImage
              language="en"
              publication={data!.getPublication!}
              width={113}
              CMS
            />
            <PublicationImage
              language="en"
              publication={data!.getPublication!}
              width={129}
              CMS
            />
          </CoverPreviewContainer>
        )}
      </Div>
    </>
  );
};

export default CoverPreview;

const Div = styled.div`
  position: absolute;
`;

const CoverPreviewContainer = styled.div`
  width: 250px;
  position: relative;
  bottom: 0px;
  right: 0px;
  transform: translate(-100%, -100%);
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
`;
