import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../../../styles/theme";
import { ReactComponent as TrashIcon } from "../../../static/icons/trash.svg";
import {
  AUTHORROLE,
  PUBLICATIONLANGS,
  SITELANGS,
  WorkLetter,
  WORKTYPE
} from "@balthasarspeyr/common";
import { WorkLetterInput } from "../../../types";

import { gql, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import {
  Cell,
  Input,
  RedButton,
  Row,
  SelectInput,
  WhiteButton
} from "../../../styles/styledcomponets";
import LabelsSelector from "../../labels/LabelsSelector";

const langs = Object.values(SITELANGS);

const UPDATE_WORK = gql`
  mutation updateWork($id: ID!, $work: WorkInput!) {
    updateWork(id: $id, work: $work) {
      id
      letterPlace
      letterDate
      originalLang
      public
      baselref
    }
  }
`;

interface WorkLetterEditProps {
  workLetterProp: WorkLetterInput & { id: string };
  author: string;
  recipient: string;
  deleteHandler: () => void;
  refetchHandler: () => void;
}

const WorkLetterEdit: FC<WorkLetterEditProps> = ({
  workLetterProp,
  author,
  recipient,
  deleteHandler,
  refetchHandler
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [work, setWork] = useState<WorkLetterInput & { id: string }>({
    ...cloneDeep(workLetterProp),
    type: [WORKTYPE.LETTER],
    authors: [{ id: author, role: AUTHORROLE.Author }],
    letterRecipient: recipient
  });
  const [backWork, setBackWorkLetter] = useState<
    WorkLetterInput & { id: string }
  >({
    ...cloneDeep(workLetterProp),
    type: [WORKTYPE.LETTER],
    authors: [{ id: author, role: AUTHORROLE.Author }],
    letterRecipient: recipient
  });
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const [updateWork] = useMutation<
    { updateWork: WorkLetter },
    { id: string; work: WorkLetterInput }
  >(UPDATE_WORK, {
    onCompleted({ updateWork }) {
      if (updateWork) {
        setEditing(false);
        setPendingChange(false);
        setBackWorkLetter(cloneDeep(work));
        refetchHandler();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const valueChange = (value, key: string) => {
    const newWork = cloneDeep(work);
    if (Array.isArray(value)) newWork[key] = [...value];
    else newWork[key] = value;

    setWork(newWork);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setEditing(false);
    setPendingChange(false);
    setWork(backWork);
  };

  const onSave = () => {
    const { id, ...updatedWork } = work;
    updateWork({
      variables: {
        id: work.id!,
        work: {
          ...updatedWork,
          year: new Date(updatedWork.letterDate).getFullYear()
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Row>
        <Cell>
          <Input
            width="95%"
            editing={editing}
            onClick={() => setEditing(true)}
            placeholder="from (place)"
            type="text"
            onChange={(e) => valueChange(e.target.value, "letterPlace")}
            value={work.letterPlace || ""}
          />
        </Cell>
        <Cell>
          <Input
            width="95%"
            editing={editing}
            onClick={() => setEditing(true)}
            placeholder="date"
            type="date"
            onChange={(e) => valueChange(e.target.value, "letterDate")}
            value={work.letterDate || ""}
          />
        </Cell>
        <Cell>
          <SelectInput
            onClick={() => setEditing(true)}
            width="fit-content"
            defaultValue={PUBLICATIONLANGS.de}
            onChange={(e) => valueChange(e.target.value, "originalLang")}
            value={work.originalLang || "de"}
          >
            {Object.keys(PUBLICATIONLANGS).map((lang) => (
              <option key={lang} value={PUBLICATIONLANGS[lang]}>
                {PUBLICATIONLANGS[lang]}
              </option>
            ))}
          </SelectInput>
        </Cell>
        <Cell>
          <Input
            width="95%"
            editing={editing}
            onClick={() => setEditing(true)}
            placeholder="Basel ref."
            type="string"
            onChange={(e) => valueChange(e.target.value, "baselref")}
            value={work.baselref || ""}
          />
        </Cell>
        <Cell>
          <LabelsSelector
            labels={work.labels || []}
            onChange={(labels) => valueChange(labels, "labels")}
          />
        </Cell>
        <Cell>
          <Input
            editing={editing}
            onClick={() => setEditing(true)}
            placeholder="Public"
            type="checkbox"
            onChange={(e) => {
              valueChange(e.target.checked, "public");
            }}
            checked={work.public}
          />
        </Cell>
        <Cell>
          <Actions editing={editing}>
            {pendingChange ? (
              <WhiteButton
                onClick={() => {
                  onSave();
                }}
              >
                Save
              </WhiteButton>
            ) : null}
            {pendingChange ? (
              <RedButton
                onClick={() => {
                  onDiscard();
                }}
              >
                Discard
              </RedButton>
            ) : null}
            <TrashIcon
              onClick={() => {
                {
                  if (
                    confirm(
                      "Are you sure you want to delete this letter? (you will remove all its publications)"
                    )
                  ) {
                    deleteHandler();
                  }
                }
              }}
            />
          </Actions>
        </Cell>
      </Row>
    </React.Fragment>
  );
};

export default WorkLetterEdit;

interface IActionsProps {
  editing: boolean;
}
const Actions = styled.div<IActionsProps>`
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
