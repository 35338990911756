import { SITELANGS, WorkMain } from "@balthasarspeyr/common";
import React, { FC } from "react";
import { ReactComponent as TrashIcon } from "../../../static/icons/trash.svg";
import {
  Actions,
  Cell,
  Row,
  WhiteButton
} from "../../../styles/styledcomponets";

interface IWorkElemProps {
  work: Partial<WorkMain>;
  deleteHandler: (id: string) => void;
  workIDHandler: (id: string, state: States) => void;
}

enum States {
  LISTING = 0,
  ADDING = 1,
  EDITING = 2
}

const MonographicElem: FC<IWorkElemProps> = ({
  work,
  deleteHandler,
  workIDHandler
}) => {
  return (
    <Row key={work.id}>
      <Cell>{work.numPublications}</Cell>
      <Cell>
        {work.title?.[work.originalLang || SITELANGS.en] || work.title?.en}
      </Cell>
      <Cell>
        {work?.authors?.[0]?.author?.name?.en}{" "}
        {work?.authors?.[0]?.author?.surname?.en}
      </Cell>
      <Cell>{work?.year}</Cell>
      <Cell>{work?.baselref}</Cell>
      <Cell>{work?.public ? "Yes" : "No"}</Cell>
      <Cell>
        <Actions>
          <WhiteButton
            margin="auto 5px auto 5px"
            onClick={() => workIDHandler(work.id || "", States.EDITING)}
          >
            Edit
          </WhiteButton>
          <TrashIcon
            onClick={() => {
              if (
                confirm(
                  `Are you sure you want to delete this work? (you will remove ${work.numPublications} publications`
                )
              ) {
                deleteHandler(work.id!);
              }
            }}
          />
        </Actions>
      </Cell>
    </Row>
  );
};

export default MonographicElem;
