import styled from "@emotion/styled";
import React, { FC } from "react";
import { breakpoints } from "../theme";
import Button, { IButtonProps } from "./Button";
import Icon, { IIconProps } from "./Icon";

const IconButton: FC<IButtonProps & IIconProps> = ({ name, ...buttonProps}) => (
  <StyledButton {...buttonProps}>
    <Icon name={name} negative={buttonProps.dark} />
  </StyledButton>
);

export default IconButton;

const StyledButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;

  @media screen and (min-width: ${breakpoints.mobile}px) {
    padding: 0;
  }
`;
