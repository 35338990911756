import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../../styles/theme";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { Author, siteAuthors, SITELANGS } from "@balthasarspeyr/common";

import { gql, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import {
  BlackButton,
  Cell,
  RedButton,
  Row,
  WhiteButton
} from "../../styles/styledcomponets";
import AvatarUploader from "../AvatarUploader";

const langs = Object.values(SITELANGS);

const UPDATE_AUTHOR = gql`
  mutation updateAuthor($id: ID!, $author: AuthorInput!) {
    updateAuthor(id: $id, author: $author) {
      id
      name {
        ${Object.values(SITELANGS).join("\n")}
      }
      surname {
        ${Object.values(SITELANGS).join("\n")}
      }
      alias {
        ${Object.values(SITELANGS).join("\n")}
      }
      image
    }
  }
`;

interface AuthorEditProps {
  authorProp: Author;
  deleteHandler: () => void;
  refetchHandler: () => void;
}

type UpdateAuthorInput = Author;

const AuthorEdit: FC<AuthorEditProps> = ({
  authorProp,
  deleteHandler,
  refetchHandler
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [multilang, setMultilang] = useState<boolean>(false);
  const [author, setAuthor] = useState<Author>(cloneDeep(authorProp));
  const [backAuthor, setBackAuthor] = useState<Author>(cloneDeep(authorProp));
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const [updateAuthor] = useMutation<
    { updateAuthor: Author },
    { id: string; author: UpdateAuthorInput }
  >(UPDATE_AUTHOR, {
    onCompleted({ updateAuthor }) {
      if (updateAuthor) {
        setEditing(false);
        setPendingChange(false);
        setBackAuthor(cloneDeep(author));
        refetchHandler();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const valueChange = (value, key: string, lang?: string) => {
    const newAuthor = cloneDeep(author);
    if (lang) newAuthor[key][lang] = value;
    else newAuthor[key] = value;
    setAuthor(newAuthor);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setEditing(false);
    setPendingChange(false);
    setAuthor(backAuthor);
  };

  const onSave = () => {
    const { numWorks, numTranslations, ...authorin } = author;
    updateAuthor({
      variables: {
        id: author.id!,
        author: { ...authorin }
      }
    });
  };

  return (
    <React.Fragment>
      {langs.map(
        (lang) =>
          (multilang || lang === "en") && (
            <Row key={`${lang}${author.id}`}>
              <Cell>
                {lang === SITELANGS.en && (
                  <AvatarUploader
                    setImageURL={(url) => valueChange(url, "image")}
                    url={author.image || ""}
                  />
                )}
              </Cell>
              <Cell>
                <Input
                  type="text"
                  name="name"
                  editing={editing}
                  onClick={() => setEditing(true)}
                  onChange={(e) => valueChange(e.target.value, "name", lang)}
                  value={author.name[lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  type="text"
                  name="surname"
                  editing={editing}
                  onClick={() => setEditing(true)}
                  onChange={(e) => valueChange(e.target.value, "surname", lang)}
                  value={author.surname[lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  type="text"
                  editing={editing}
                  name="alias"
                  onClick={() => setEditing(true)}
                  onChange={(e) => valueChange(e.target.value, "alias", lang)}
                  value={author.alias[lang] || ""}
                />
              </Cell>
              <Cell>
                {author.numWorks || 0}/{author.numTranslations || 0}{" "}
              </Cell>
              <Cell>
                {lang === "en" ? (
                  <Actions editing={editing}>
                    {multilang ? (
                      <WhiteButton onClick={() => setMultilang(false)}>
                        View only English
                      </WhiteButton>
                    ) : (
                      <WhiteButton onClick={() => setMultilang(true)}>
                        View all langs.
                      </WhiteButton>
                    )}
                    {pendingChange ? (
                      <BlackButton
                        onClick={() => {
                          onSave();
                        }}
                      >
                        Save
                      </BlackButton>
                    ) : null}
                    {pendingChange ? (
                      <RedButton
                        onClick={() => {
                          onDiscard();
                        }}
                      >
                        Discard
                      </RedButton>
                    ) : null}
                    {![siteAuthors.balthasar.id, siteAuthors.speyr.id].includes(
                      author.id
                    ) && (
                      <TrashIcon
                        onClick={() => {
                          if (
                            confirm(
                              `Are you sure you want to delete this author? (you will remove ${author.numWorks} works, publications and  ${author.numTranslations} translations`
                            )
                          ) {
                            deleteHandler();
                          }
                        }}
                      />
                    )}
                  </Actions>
                ) : (
                  <Actions editing={editing}>{lang}</Actions>
                )}
              </Cell>
            </Row>
          )
      )}
    </React.Fragment>
  );
};

export default AuthorEdit;

const Img = styled.img`
  border: 1px solid #e9e3d2;
  border-radius: 50%;
  height: 30px;
  padding: 0;
  margin: 0;
  width: 30px;
`;

interface IInputProps {
  editing: boolean;
}
const Input = styled.input<IInputProps>`
  background-color: ${(props) =>
    props.editing ? theme.colors.brandWhite : theme.colors.gray6};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

interface IActionsProps {
  editing: boolean;
}
const Actions = styled.div<IActionsProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
