import { css } from "@emotion/react";
import animations, { transition } from "./animations";
import breakpoints from "./breakpoints";
import colors from "./colors";
import fonts from "./fonts";
import zIndexes from "./z-indexes";

const baseStyles = css`
  /* Typography */
  body {
    ${fonts.family.portrait}
    ${fonts.size.normal}
  }

  button {
    ${fonts.family.theinhardt}
    ${fonts.size.smallExtra}
  }

  h1 {
    ${fonts.size.huge}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h2 {
    ${fonts.size.largeExtra}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h3 {
    ${fonts.size.medium}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h4 {
    ${fonts.size.normal}
  }

  #publications {
    h1 {
      ${fonts.size.large}
    }

    h2 {
      ${fonts.size.medium}
      font-weight: normal;
      font-style: italic;
    }

    h3 {
      ${fonts.size.normal}
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    h1 {
      ${fonts.size.giant}
    }
  }
  
  
  :lang(jpn) p, :lang(zho-Hans) p, :lang(zho-Hant) p {
    line-height: 175%;
    letter-spacing: 0.01em;
    text-align: justify;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  :lang(jpn) div:not(:lang(jpn)) p
 {
    line-height: 138%;
    letter-spacing: 0;
    text-align: left;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  
  h1 p:lang(jpn), 
  h2 p:lang(jpn), 
  h3 p:lang(jpn), 
  h4 p:lang(jpn), 
  a p:lang(jpn),
  #breadcrumb-level-1 p:lang(jpn), 
  #breadcrumb-level-2 p:lang(jpn),  
  #breadcrumb-level-3 p:lang(jpn), 
  #breadcrumb-level-4 p:lang(jpn),
  p#breadcrumb-level-2:lang(jpn),
  {
  line-height: 125%;
  text-align: inherit; 
  letter-spacing: 0;
}

  figcaption {
    ${fonts.size.tiny}
    letter-spacing: -0.1px;
  }

  /* Color */
  * {
    color: inherit;
  }

  figcaption {
    color: ${colors.blue[4]};
  }

  a {
    color: ${colors.black[0]};

    :active,
    :hover {
      color: ${colors.gold[0]};
    }
  }

  /* Style reset */
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    border: 0;
    margin: 0;
    padding: 0;
    scrollbar-width: none;
    vertical-align: baseline;

    ::-webkit-scrollbar {
      display: none;
    }

    ::selection {
      background: ${colors.gold[1]};
      color: ${colors.white};
      mix-blend-mode: screen;
    }
  }

  p + :not(button):not(svg) {
    margin-top: 1em;
  }

  label + input,
  label + textarea {
    margin-top: 0.5em;
  }

  html,
  body {
    border: 0;
    color: ${colors.black};
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  body.light {
    background-color: ${colors.white};
    box-shadow: inset 0px -300px 0 0px ${colors.blue[1]};
  }

  body.dark {
    background-color: ${colors.blue[1]};
  }

  a {
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    svg {
      align-self: center;
    }
  }

  ol,
  ul {
    padding-left: 15px;
  }

  figcaption {
    margin-top: 10px;
  }

  .footnote-ref > sup {
    font-size: 75%;
    line-height: 0;
    vertical-align: super;
    color: #a59c82 !important;
    margin-left: 2px;
    ::before {
      content: "[";
      margin-right: 2px;
    }
    ::after {
      content: "]";
      margin-left: 2px;
    }
  }
  sup {
    font-size: 75%;
    line-height: 0;
    vertical-align: super;
  }
  sup .scrollbar {
    scrollbar-width: unset;

    ::-webkit-scrollbar:vertical {
      display: block;
    }

    ::-webkit-scrollbar {
      width: 15px;
    }

    ::-webkit-scrollbar-thumb {
      padding: 5px;
      background-color: ${colors.gold[2]};
      border-radius: 9999px;
      border: 5px solid transparent;
      background-clip: padding-box;
    }
  }

  /* Share Text Twitter */
  #twitter-share-button {
    background-color: ${colors.blue[3]};
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    color: ${colors.white};
    display: none;
    height: 40px;
    left: 0;
    margin: 20px 0;
    padding: 10px 15px;
    position: absolute;
    top: 0;
    z-index: ${zIndexes.modal};

    * {
      pointer-events: none;
    }

    #twitter-share-arrow {
      background-color: inherit;
      border-top-left-radius: 3px;
      height: 9px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 9px;
    }
  }

  .twitter-share-button-entrance {
    animation-duration: ${transition}s;
    animation-fill-mode: both;
    animation-name: ${animations.zoomIn};
  }
`;

export default baseStyles;
