import { SITELANGS } from "@balthasarspeyr/common";
import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import {
  BlackButton,
  Cell,
  RedButton,
  Row,
  WhiteButton
} from "../../styles/styledcomponets";
import { theme } from "../../styles/theme";
import { IAddAuthorInput } from "../../types";
import AvatarUploader from "../AvatarUploader";

interface AuthorAddProps {
  addHandler: (author: IAddAuthorInput) => void;
}
const AuthorAdd: FC<AuthorAddProps> = ({ addHandler }) => {
  const [author, setAuthor] = useState<IAddAuthorInput>({
    name: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    surname: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    alias: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    image: undefined
  });
  const cleanAuthor: IAddAuthorInput = {
    name: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    surname: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    alias: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    image: undefined
  };
  const [pendingChange, setPendingChange] = useState<boolean>(false);
  const [multilang, setMultilang] = useState<boolean>(false);

  const changeToSingleLang = () => {
    // all languages same value (take english value as default)
    const authorKeys = ["name", "surname", "alias"];
    authorKeys.forEach((key) => {
      Object.keys(author[key]).forEach(
        (lang) => (author[key][lang] = author[key]["en"])
      );
    });
    setAuthor(author);
    setMultilang(false);
  };

  const valueChange = (value, key, lang?) => {
    const newAuthor: IAddAuthorInput = { ...author };
    if (key === "image") {
      newAuthor["image"] = value;
    } else if (multilang) {
      newAuthor[key][lang] = value;
    } else {
      // all languages same value
      Object.keys(newAuthor[key]).forEach(
        (lang) => (newAuthor[key][lang] = value)
      );
    }

    setAuthor(newAuthor);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setAuthor({ ...cleanAuthor });
  };

  const onAdd = () => {
    addHandler(author);
    setAuthor({ ...cleanAuthor });
  };

  return (
    <React.Fragment>
      {Object.keys(SITELANGS).map(
        (lang) =>
          (multilang || lang === "en") && (
            <Row key={`new${lang}`}>
              <Cell>
                {lang === SITELANGS.en && (
                  <AvatarUploader
                    setImageURL={(url) => valueChange(url, "image")}
                    url={author.image || ""}
                  />
                )}
              </Cell>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "name", lang)}
                  value={author.name![lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "surname", lang)}
                  value={author.surname![lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "alias", lang)}
                  value={author.alias![lang] || ""}
                />
              </Cell>
              <Cell align="left">
                {lang === "en" ? (
                  <Actions>
                    {multilang ? (
                      <WhiteButton onClick={() => changeToSingleLang()}>
                        Set single lang. (en)
                      </WhiteButton>
                    ) : (
                      <WhiteButton onClick={() => setMultilang(true)}>
                        Set multiple langs.
                      </WhiteButton>
                    )}
                    <BlackButton
                      onClick={(e) => {
                        onAdd();
                      }}
                    >
                      Add
                    </BlackButton>

                    {pendingChange ? (
                      <RedButton
                        onClick={() => {
                          onDiscard();
                        }}
                      >
                        Discard
                      </RedButton>
                    ) : null}
                  </Actions>
                ) : (
                  <Actions />
                )}
              </Cell>
            </Row>
          )
      )}
    </React.Fragment>
  );
};

export default AuthorAdd;

const Input = styled.input`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
  background-color: ${theme.colors.brandWhite};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
`;
