import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../styles/theme";
import { gql, useMutation } from "@apollo/client";

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      email
    }
  }
`;

const Login: FC<{ loginHandle: (token: string, email: string) => void }> = ({
  loginHandle
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [login] = useMutation<
    { login: { token: string; email: string } },
    { email: string; password: string }
  >(LOGIN, {
    onCompleted({ login }) {
      if (login?.token) {
        localStorage.setItem("token", login.token);
        localStorage.setItem("email", login.email);
        setErrorMessage("");
        loginHandle(login.token, login.email);
      } else {
        console.log("not logged");
        setErrorMessage("Login Error");
        localStorage.removeItem("token");
        localStorage.removeItem("email");
      }
    },
    onError(error) {
      setErrorMessage("email or password are incorrect");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
    }
  });

  const onLogin = () => {
    login({ variables: { email, password } });
  };

  return (
    <div>
      <LoginBox>
        <Input
          type="text"
          value={email}
          placeholder="e-mail"
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorMessage("");
          }}
        />
        <br />
        <Input
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorMessage("");
          }}
        />
        <br />
        <Submit onClick={() => onLogin()}>Login</Submit>
        <br />
        {errorMessage !== "" ? <Error>{errorMessage}</Error> : null}
      </LoginBox>
    </div>
  );
};

export default Login;

const LoginBox = styled.div`
  border: 1px solid;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  /* bring your own prefixes */
  transform: translate(-50%, -70%);
  border-radius: 10px;
`;

const Input = styled.input`
  background-color: ${theme.colors.brandWhite};
  border: 1px solid black;
  height: 35px;
  font-size: 0.8rem;
  width: 340px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  border-radius: 8px;
`;

const Submit = styled.button`
  background-color: ${theme.colors.brandBlack};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.8rem;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 35px;
  width: 350px;
  cursor: pointer;
  margin-top: 10px;
`;

const Error = styled.div`
  color: #cc0000;
  text-align: center;
  margin-top: 10px;
  font-size: 0.8rem;
`;
