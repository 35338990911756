import { css } from "@emotion/react";

const family = {
  portrait: css`
    font-family: Portrait Text Web, Portrait Web, 'EB Garamond', YakuHanMP_Noto, 'Noto Serif JP', serif;
  `,
  theinhardt: css`
    font-family: Theinhardt;
  `,
}

const size = {
  tiny: css`
    font-size: 12px;
    line-height: 15px
  `,
  small: css`
    font-size: 12px;
    line-height: 20px
  `,
  smallParagraph: css`
    font-size: 16.5px;
    line-height: 22px;
  `,
  smallExtra: css`
    font-size: 15px;
    line-height: 19px
  `,
  normal: css`
    font-size: 18px;
    line-height: 25px
  `,
  medium: css`
    font-size: 20px;
    line-height: 25px
  `,
  mediumExtra: css`
    font-size: 22px;
    line-height: 25px
  `,
  large: css`
    font-size: 25px;
    line-height: 30px
  `,
  largeExtra: css`
    font-size: 29px;
    line-height: 40px
  `,
  huge: css`
    font-size: 30px;
    line-height: 35px
  `,
  hugeExtra: css`
    font-size: 35px;
    line-height: 40px
  `,
  giant: css`
    font-size: 46px;
    line-height: 50px
  `
};

export default {
  family,
  size
};
