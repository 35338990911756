import React, {
  ComponentProps,
  FC,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { adjustColorOpacity, colors, fonts } from "../theme";
import TooltipError from "./TooltipError";

export interface ICheckboxProps {
  error?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseError?: () => void;
  transformError?: string;
}

const Checkbox: FC<
  ICheckboxProps &
    Omit<ComponentProps<"input">, "ref"> &
    HTMLAttributes<HTMLInputElement>
> = forwardRef((props, _ref) => {
  const { error, label, onCloseError, transformError, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState<boolean | undefined>(props.checked);

  useEffect(() => {
    inputRef.current && setChecked(inputRef.current.checked);
  }, []);

  useEffect(() => setChecked(props.checked), [props.checked]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputRef.current && setChecked(inputRef.current.checked);
    props.onChange?.(e);
  };

  return (
    <CheckboxTooltipError error={error} onClose={onCloseError} transform={transformError}>
      <CheckboxWrap error={!!error}>
        <CheckboxInput
          checked={checked}
          error={!!error}
          disabled={rest.disabled}
          onClick={(e) => (e.preventDefault(), e.stopPropagation(), inputRef.current?.click())}
        >
          <input
            {...rest}
            ref={inputRef}
            type="checkbox"
            onChange={onChange}
            style={{ display: "none" }}
          />
          {checked && <Checked error={!!error} />}
        </CheckboxInput>
        {label && <span>{label}</span>}
      </CheckboxWrap>
    </CheckboxTooltipError>
  );
});

Checkbox.displayName = "Checkbox";

export default Checkbox;

const Checked = styled.div<{ error?: boolean }>`
  background-color: ${props => props.error ? colors.red[0] : colors.blue[0]};
  height: 9px;
  position: absolute;
  width: 9px;
`;

const CheckboxInput = styled.div<{ checked?: boolean, disabled?: boolean, error?: boolean }>`
  align-items: center;
  border-radius: 2px;
  border: 1px solid ${colors.gold[2]};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 15px;
  min-width: 15px;
  justify-content: center;
  transform: translateY(0.5px);

  :hover {
    border-color: ${colors.blue[0]};
  }

  ${(props) =>
    props.error &&
    `
    background-color: ${adjustColorOpacity(colors.red[2], 0.5)};
    border-color: ${colors.red[0]};
  `};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${colors.grey[2]};
    border-color: ${colors.grey[1]};
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const CheckboxTooltipError = styled(TooltipError)`
  > div:last-of-type {
    top: 20px;
  }
`;

const CheckboxWrap = styled.div<{ error?: boolean }>`
  display: flex;

  > div, span {
    margin-top: 4px;
  }

  span {
    ${fonts.family.theinhardt}
    ${fonts.size.smallExtra}

    color: ${props => props.error ? colors.red[0] : "inherit"};
    margin-left: 10px;
  }
`;
