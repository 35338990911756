import React, { FC } from "react";

import { useQuery, useMutation, gql } from "@apollo/client";
import cloneDeep from "clone-deep";
import { LoaderLarge } from "../../Loaders";
import { Row, Table, Title } from "../../../styles/styledcomponets";
import LettersList from "./LettersList";
import { siteAuthors } from "@balthasarspeyr/common";

const LetterCMSLayout: FC = () => {
  return (
    <div>
      <LettersList author={siteAuthors.balthasar} />
      <LettersList author={siteAuthors.speyr} />
    </div>
  );
};

export default LetterCMSLayout;
