import { SITELANGS } from "@balthasarspeyr/common";
import React, { FC, useState } from "react";
import { InputTextComponent } from "../../InputTextComponent";

interface IData {
  title: string;
  subtitle: string;
  caption: string;
}

const MonographicTitlesComponent: FC<{
  data: IData;
  language: SITELANGS;
  updateLang: (data: IData) => void;
}> = ({ data, language, updateLang }) => {
  const updateData = (key: string, value: string) => {
    data[key][language] = value;
    updateLang({ ...data });
  };

  return (
    <div>
      <InputTextComponent
        width="100%"
        placeholder="Title"
        value={data.title[language]}
        helper="Title"
        onChange={(title: string) => updateData("title", title)}
      />
      <InputTextComponent
        width="100%"
        placeholder="Subtitle"
        value={data.subtitle[language]}
        helper="Subtitle"
        onChange={(subtitle: string) => updateData("subtitle", subtitle)}
      />
      <InputTextComponent
        width="100%"
        placeholder="caption"
        value={data.caption[language]}
        helper="Caption"
        onChange={(caption: string) => updateData("caption", caption)}
      />
    </div>
  );
};

export default MonographicTitlesComponent;
