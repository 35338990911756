const blue = ["#202131", "#2B2C3C", "#363745", "#414250", "#4D4D5A"];
const gold = ["#A59C82", "#BDB398", "#D4CCB3", "#E9E3D2", "#847C68", "#736D5B"]; // last two values added for contents.tsx
const grey = ["#D2D3D6", "#E4E4E6", "#EEEEEE"];
const red = ["#EB3239", "#FF535A", "#FD6A70", "#D45B5B"];
const white = "#FCFBFA";

export const adjustColorOpacity = (color: string, opacity: number): string => {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
};

export default {
  black: blue[0],
  blue,
  gold,
  grey,
  red,
  white
};
