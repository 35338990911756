import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../styles/theme";
import { ReactComponent as TrashIcon } from "../static/icons/trash.svg";
import { ContentsEntry, SITELANGS } from "@balthasarspeyr/common";
import { cloneDeep } from "@apollo/client/utilities";
import { v4 as uuidv4 } from "uuid";

interface IContentsProp {
  entry: ContentsEntry;
  moveLeft?: () => void;
  moveRight?: () => void;
  moveUp?: () => void;
  moveDown?: () => void;
  removeEntry?: () => void;
  notifyParent: (entry: ContentsEntry) => void;
  last?: boolean;
  language: string;
}

const ContentsEntryComponent: FC<IContentsProp> = ({
  entry,
  moveLeft,
  moveRight,
  moveUp,
  moveDown,
  removeEntry,
  notifyParent,
  last,
  language
}) => {
  const left = (parent: ContentsEntry, toMove: ContentsEntry) => {
    // remove from parent
    const index = parent.children.indexOf(toMove);
    parent.children.splice(index, 1);

    // add to grandparent just after parent
    if (entry.parent) {
      const grandparent = entry.parent;
      const otherindex = grandparent.children.indexOf(parent);
      grandparent.children.splice(otherindex + 1, 0, toMove);

      // notify the whole object
      notifyParent(grandparent);
    }
  };

  const right = (previous: ContentsEntry, toMove: ContentsEntry) => {
    previous.children.push(cloneDeep(toMove));
    const index = entry.children.indexOf(toMove);
    entry.children.splice(index, 1);
    notifyParent(entry);
  };

  const up = (parent: ContentsEntry, toMove: ContentsEntry) => {
    const index = parent.children.indexOf(toMove);
    const aux = parent.children[index - 1];
    parent.children[index - 1] = toMove;
    parent.children[index] = aux;
    notifyParent(parent);
  };

  const down = (parent: ContentsEntry, toMove: ContentsEntry) => {
    const index = parent.children.indexOf(toMove);
    const aux = parent.children[index + 1];
    parent.children[index + 1] = toMove;
    parent.children[index] = aux;
    notifyParent(parent);
  };

  const remove = (parent: ContentsEntry, toMove: ContentsEntry) => {
    const index = parent.children.indexOf(toMove);
    parent.children.splice(index, 1);
    notifyParent(parent);
  };

  const add = () => {
    if (entry.parent) {
      entry.parent.children.push(newEntry);
      notifyParent(entry.parent);
    }
  };

  const value: ContentsEntry = { ...entry };

  const [editing, setEditing] = useState<boolean>(false);
  const [newEntry, setNewEntry] = useState<ContentsEntry>({
    id: uuidv4(),
    children: [],
    text: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    sign: ""
  });

  return (
    <Entry>
      <Text>
        {moveLeft && <Action onClick={() => moveLeft()}>&#8606;</Action>}
        <Input
          placeholder="sign"
          editing={editing}
          chars={value.sign !== "" ? value.sign.length : "sign".length}
          onFocus={() => setEditing(true)}
          onBlur={() => setEditing(false)}
          onChange={(e) => {
            entry.sign = e.target.value;
            notifyParent(entry);
          }}
          value={value.sign}
        />
        -
        <Input
          type="text"
          placeholder="text"
          editing={editing}
          chars={Math.max(value.text[language].length, "text".length)}
          onFocus={() => setEditing(true)}
          onBlur={() => setEditing(false)}
          onChange={(e) => {
            entry.text[language] = e.target.value;
            notifyParent(entry);
          }}
          value={value.text[language]}
        />
        {moveRight && <Action onClick={() => moveRight()}>&#8608;</Action>}
        {moveUp && <Action onClick={() => moveUp()}>&#8607;</Action>}
        {moveDown && <Action onClick={() => moveDown()}>&#8609;</Action>}
        {removeEntry && (
          <Action>
            <TrashIcon onClick={() => removeEntry()} />
          </Action>
        )}
      </Text>
      {entry.children.map((child: ContentsEntry, index: number) => {
        if (entry.children.length === 1)
          return (
            <ContentsEntryComponent
              entry={child}
              key={child.id}
              moveLeft={() => left(entry, child)}
              notifyParent={(e) => notifyParent(e)}
              removeEntry={() => remove(entry, child)}
              last={true}
              language={language}
            />
          );
        if (index === 0 && entry.children.length > 0)
          return (
            <ContentsEntryComponent
              entry={child}
              key={child.id}
              moveLeft={() => left(entry, child)}
              notifyParent={(e) => notifyParent(e)}
              moveDown={() => down(entry, child)}
              removeEntry={() => remove(entry, child)}
              language={language}
            />
          );
        else if (index > 0 && index < entry.children.length - 1)
          return (
            <ContentsEntryComponent
              notifyParent={(e) => notifyParent(e)}
              entry={child}
              key={child.id}
              moveLeft={() => left(entry, child)}
              moveRight={() => right(entry.children[index - 1], child)}
              moveUp={() => up(entry, child)}
              moveDown={() => down(entry, child)}
              removeEntry={() => remove(entry, child)}
              language={language}
            />
          );
        else
          return (
            <ContentsEntryComponent
              notifyParent={(e) => notifyParent(e)}
              entry={child}
              key={child.id}
              moveLeft={() => left(entry, child)}
              moveRight={() => right(entry.children[index - 1], child)}
              moveUp={() => up(entry, child)}
              last={true}
              removeEntry={() => remove(entry, child)}
              language={language}
            />
          );
      })}
      {last && (
        <Text>
          <Add
            placeholder="sign"
            editing={true}
            chars={newEntry.sign !== "" ? newEntry.sign.length : "sign".length}
            value={newEntry.sign}
            onChange={(e) => setNewEntry({ ...newEntry, sign: e.target.value })}
          />{" "}
          -{" "}
          <Add
            placeholder="text"
            editing={true}
            chars={Math.max(newEntry.text[language].length, "text".length)}
            defaultValue=""
            value={newEntry.text[language]}
            onChange={(e) => {
              const v = { ...newEntry };
              v.text[language] = e.target.value;
              setNewEntry(v);
            }}
          />{" "}
          <Action
            onClick={() => {
              add();
              setNewEntry({
                id: uuidv4(),
                sign: "",
                text: Object.values(SITELANGS).reduce((acc, lang) => {
                  acc[lang] = "";
                  return acc;
                }, {}),
                children: []
              });
            }}
          >
            &#8853;
          </Action>
        </Text>
      )}
    </Entry>
  );
};

export default ContentsEntryComponent;

const Entry = styled.div`
  padding-left: 25px;
  border-left: 1px groove black;
`;

const Text = styled.div`
  margin-left: -5px;
`;

const Input = styled.input<{ editing: boolean; chars: number }>`
  background-color: ${(props) =>
    props.editing ? theme.colors.brandWhite : theme.colors.gray6};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  width: ${(props) => props.chars * 8 + 8}px;
  :focus {
    outline: none;
  }
`;

const Add = styled.input<{ editing: boolean; chars: number }>`
  background-color: ${(props) =>
    props.editing ? theme.colors.brandWhite : theme.colors.gray6};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  width: ${(props) => props.chars * 8 + 8}px;
  :focus {
    outline: none;
  }
`;

const Action = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  :hover {
    color: ${theme.colors.brandRed};
  }
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 15px;
    min-height: 15px;
    min-width: 15px;
    width: 15px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
