import { LangTexts, PressHouse, SITELANGS, TEXTFORMATS } from "..";
import { UPLOADFILETYPES } from "./types";

export const sitePressHouse: PressHouse & { _id: string } = {
  id: "000000000000000000001153",
  _id: "000000000000000000001153",
  name: "Saint John Publications",
  website: "https://balthasarspeyr.org"
};

export const johannesverlag: PressHouse & { _id: string } = {
  id: "000000000000000000000053",
  _id: "000000000000000000000053",
  name: "Johannes Verlag",
  website: "http://www.johannes-verlag.de/"
};

export const ignatius: PressHouse & { _id: string } = {
  id: "000000000000000000000011",
  _id: "000000000000000000000011",
  name: "Ignatius Press",
  website: "https://ignatius.com/"
};

export const siteAuthors = {
  balthasar: {
    id: "000000000000000000000011",
    _id: "000000000000000000000011",
    name: {
      en: "Hans Urs",
      es: "Hans Urs",
      de: "Hans Urs",
      fr: "Hans Urs",
      it: "Hans Urs",
      jpn: "ハンス・ウルス・"
    },
    surname: {
      en: "von Balthasar",
      es: "von Balthasar",
      de: "von Balthasar",
      fr: "von Balthasar",
      it: "von Balthasar",
      jpn: "フ⁠ォ⁠ン⁠・⁠バ⁠ル⁠タ⁠ザ⁠ー⁠ル"
    },
    alias: {
      en: "",
      es: "",
      de: "",
      fr: "",
      it: "",
      jpn: ""
    },
    covercolors: {
      Mainwork: "#820b1c",
      Translation: "#a2471f",
      Anthology: "#a2471f",
      Article: "#a67411",
      Contribution: "#a67411",
      Preface: "#a2471f",
      Epilogue: "#a2471f",
      Letter: "#a68d11",
      EditedWork: "#a2471f"
    },
    image:
      "https://balthasarspeyr.org/_next/static/image/src/assets/images/avatar-balthasar.d615f3e622612eb60ebbc237fd199765.jpg"
  },
  speyr: {
    id: "000000000000000000000053",
    _id: "000000000000000000000053",
    name: {
      en: "Adrienne",
      es: "Adrienne",
      de: "Adrienne",
      fr: "Adrienne",
      it: "Adrienne",
      jpn: "アドリエンヌ・"
    },
    surname: {
      en: "von Speyr",
      es: "von Speyr",
      de: "von Speyr",
      fr: "von Speyr",
      it: "von Speyr",
      jpn: "フ⁠ォ⁠ン⁠・⁠シ⁠ュ⁠パ⁠イ⁠ア"
    },
    alias: {
      en: "",
      es: "",
      de: "",
      fr: "",
      it: "",
      jpn: ""
    },
    covercolors: {
      Mainwork: "#112a52",
      Translation: "#43156b",
      Anthology: "#43156b",
      Article: "#15536b",
      Contribution: "#15536b",
      Preface: "#43156b",
      Epilogue: "#43156b",
      Letter: "#156b63",
      EditedWork: "#43156b"
    },
    image:
      "https://balthasarspeyr.org/_next/static/image/src/assets/images/avatar-speyr.e6140137d52bfe13dcfed424cc4011db.jpg"
  }
};

export const defaultLanguage: string = SITELANGS.en;

export const FILE_LIMIT_SIZE: { [key in UPLOADFILETYPES]: number } = {
  image: 1048576,
  audio: 10485760,
  video: 10485760,
  pdf: 10485760,
  epub: 10485760,
  mobi: 10485760
};

export const ACCEPTED_FILES: { [key in UPLOADFILETYPES]: string[] } = {
  image: [".png", ".gif", ".jpg", ".jpeg", ".webp"],
  audio: [".mp3"],
  video: [".mp4", ".webm"],
  pdf: [".pdf"],
  epub: [".epub"],
  mobi: [".mobi"]
};

export const CONTENTYPES: { [key in UPLOADFILETYPES]: Record<string, string> } =
  {
    image: {
      png: "image/png",
      gif: "image/gif",
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      webp: "image/webp"
    },
    audio: {
      mp3: "audio/mp3"
    },
    video: {
      mp4: "video/mp4",
      webm: "video/webm"
    },
    pdf: {
      pdf: "application/pdf"
    },
    epub: {
      epub: "application/epub+zip"
    },
    mobi: {
      mobi: "application/x-mobipocket-ebook"
    }
  };

export const emptyLangs: LangTexts = {
  en: "",
  es: "",
  de: "",
  fr: "",
  it: "",
  jpn: ""
};
