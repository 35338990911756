import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../../styles/theme";
import { AddRecipientInput } from "../../types";
import { LangTexts, siteAuthors, SITELANGS } from "@balthasarspeyr/common";
import { cloneDeep } from "@apollo/client/utilities";
import BoxWithTitleContainer from "../BoxWithTitleContainer";
import MultiLingualContainer from "../MultiLingualContainer";
import MDEditorComponent from "../MDEditorComponent";
import {
  BlackButton,
  RedButton,
  SelectInput,
  Cell,
  Title,
  Row,
  Table
} from "../../styles/styledcomponets";
import AvatarUploader from "../AvatarUploader";

const langs: string[] = Object.values(SITELANGS);

interface RecipientAddProps {
  recipientProp: AddRecipientInput;
  addHandler: (recipient: AddRecipientInput) => void;
  closeHandler: () => void;
}

const RecipientAdd: FC<RecipientAddProps> = ({
  recipientProp,
  addHandler,
  closeHandler
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [recipient, setRecipient] = useState<AddRecipientInput>(
    cloneDeep(recipientProp)
  );

  const [abstract, setRecipientAbstract] = useState<LangTexts>({
    ...recipientProp.abstract!
  });

  const [balthasarDescription, setBalthasarDescription] = useState<{
    author: string;
    description: LangTexts;
    correspondenceLanguage: string;
  }>({
    ...recipientProp.description!.find(
      (desc) => desc.author! === siteAuthors.balthasar.id
    )!,
    author: siteAuthors.balthasar.id
  });

  const [speyrDescription, setSpeyrDescription] = useState<{
    author: string;
    description: LangTexts;
    correspondenceLanguage: string;
  }>({
    ...recipientProp.description!.find(
      (desc) => desc.author! === siteAuthors.speyr.id
    )!,
    author: siteAuthors.speyr.id
  });

  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const valueChange = (value, key: string, lang?: string) => {
    const newRecipient = cloneDeep(recipient);
    if (lang) {
      newRecipient[key][lang] = value;
    } else {
      newRecipient[key] = value;
    }
    setRecipient({ ...newRecipient });
    setPendingChange(true);
  };

  const onSave = () => {
    addHandler({
      ...recipient,
      description: [balthasarDescription, speyrDescription],
      abstract: { ...abstract }
    });
    setPendingChange(false);
    setEditing(false);
  };

  return (
    <div>
      <Actions adding={editing}>
        {pendingChange ? (
          <BlackButton
            onClick={() => {
              onSave();
            }}
          >
            Save
          </BlackButton>
        ) : null}
        {pendingChange ? (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Close without saving
          </RedButton>
        ) : (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Back to correspondents List
          </RedButton>
        )}
      </Actions>
      <BoxWithTitleContainer title="Recipient Data">
        <Table width="100%">
          <thead>
            <Row>
              <Title width="50px">&nbsp;</Title>
              <Title width="20%">Name</Title>
              <Title width="20%">Surname</Title>
              <Title width="20%">Alias</Title>
              <Title width="10%">&nbsp;</Title>
              <Title width="15%">HUvB lang.</Title>
              <Title width="15%">AvS lang.</Title>
            </Row>
          </thead>
          <tbody>
            {langs.map((lang) => (
              <Row key={`${lang}$`}>
                <Cell>
                  {lang === SITELANGS.en && (
                    <AvatarUploader
                      setImageURL={(url) => valueChange(url, "image")}
                      url={recipient.image || ""}
                    />
                  )}
                </Cell>
                <Cell>
                  <Input
                    type="text"
                    name="name"
                    adding={editing}
                    onClick={() => setEditing(true)}
                    onChange={(e) => valueChange(e.target.value, "name", lang)}
                    value={recipient.name[lang] || ""}
                  />
                </Cell>
                <Cell>
                  <Input
                    type="text"
                    name="surname"
                    adding={editing}
                    onClick={() => setEditing(true)}
                    onChange={(e) =>
                      valueChange(e.target.value, "surname", lang)
                    }
                    value={recipient.surname[lang] || ""}
                  />
                </Cell>
                <Cell>
                  <Input
                    type="text"
                    adding={editing}
                    name="alias"
                    onClick={() => setEditing(true)}
                    onChange={(e) => valueChange(e.target.value, "alias", lang)}
                    value={recipient.alias?.[lang] || ""}
                  />
                </Cell>
                <Cell>{lang}</Cell>
                <Cell>
                  {lang === SITELANGS.en ? (
                    <SelectInput
                      width="fit-content"
                      defaultValue={
                        balthasarDescription.correspondenceLanguage || "de"
                      }
                      onChange={(e) => {
                        setPendingChange(true);
                        setBalthasarDescription({
                          ...balthasarDescription,
                          correspondenceLanguage: e.target.value
                        });
                      }}
                      value={
                        balthasarDescription.correspondenceLanguage || "de"
                      }
                    >
                      {Object.keys(SITELANGS).map((lang) => (
                        <option key={lang} value={SITELANGS[lang]}>
                          {SITELANGS[lang]}
                        </option>
                      ))}
                    </SelectInput>
                  ) : null}
                </Cell>
                <Cell>
                  {lang === SITELANGS.en ? (
                    <SelectInput
                      width="fit-content"
                      defaultValue={
                        speyrDescription.correspondenceLanguage || "de"
                      }
                      onChange={(e) => {
                        setPendingChange(true);
                        setSpeyrDescription({
                          ...speyrDescription,
                          correspondenceLanguage: e.target.value
                        });
                      }}
                      value={speyrDescription.correspondenceLanguage || "de"}
                    >
                      {Object.keys(SITELANGS).map((lang) => (
                        <option key={lang} value={SITELANGS[lang]}>
                          {SITELANGS[lang]}
                        </option>
                      ))}
                    </SelectInput>
                  ) : null}
                </Cell>
              </Row>
            ))}
          </tbody>
        </Table>
      </BoxWithTitleContainer>
      <MultiLingualContainer
        langWidth="100"
        title={`${recipient.name.en}'s Short Bio and Context`}
        updateData={(data) => {
          setPendingChange(true);
          setRecipientAbstract({ ...(data as LangTexts) });
        }}
        componentData={{ ...abstract }}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <MultiLingualContainer
        langWidth="100"
        title="Balthasar's Correspondence Description"
        updateData={(data) => {
          setPendingChange(true);
          setBalthasarDescription({
            ...balthasarDescription,
            description: { ...(data as LangTexts) }
          });
        }}
        componentData={balthasarDescription.description}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <MultiLingualContainer
        langWidth="100"
        title="Adrienne's correspondence Description"
        updateData={(data) => {
          setPendingChange(true);
          setSpeyrDescription({
            ...speyrDescription,
            description: { ...(data as LangTexts) }
          });
        }}
        componentData={speyrDescription.description}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <Actions adding={editing}>
        {pendingChange ? (
          <BlackButton
            onClick={() => {
              onSave();
            }}
          >
            Save
          </BlackButton>
        ) : null}
        {pendingChange ? (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Close without saving
          </RedButton>
        ) : (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Back to correspondents List
          </RedButton>
        )}
      </Actions>
    </div>
  );
};

export default RecipientAdd;

type InputProps = {
  adding: boolean;
};
const Input = styled.input<InputProps>`
  width: 95%;
  background-color: ${(props) =>
    props.adding ? theme.colors.brandWhite : theme.colors.gray6};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

interface IActionsProps {
  adding: boolean;
}
const Actions = styled.div<IActionsProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
