import styled from "@emotion/styled";
import React, { FC } from "react";
import NewsEdit from "./NewsEdit";
import NewsAdd from "./NewsAdd";
import { News, NewsList, SITELANGS } from "@balthasarspeyr/common";
import { useQuery, useMutation, gql } from "@apollo/client";
import cloneDeep from "clone-deep";
import { AddNewsInput } from "../../types";
import { LoaderLarge } from "../Loaders";
import { Row, Table, Title } from "../../styles/styledcomponets";

type NewsData = {
  getNewsList: NewsList;
};

const NEWS = gql`
  query getNewsList {
    getNewsList {
      totalNumber
      page
      totalPages
      pageSize
      data {
        id
        start
        end
        title {
         ${Object.values(SITELANGS).join("\n")}
        }
        body {
         ${Object.values(SITELANGS).join("\n")}
        }
        url {
         ${Object.values(SITELANGS).join("\n")}
        }
        linkText {
         ${Object.values(SITELANGS).join("\n")}
        }
      }
    }
  }
`;

const ADD_NEWS = gql`
  mutation addNews($news: NewsInput!) {
    addNews(news: $news) {
      id
      start
      end
      title {
       ${Object.values(SITELANGS).join("\n")}
      }
      body {
       ${Object.values(SITELANGS).join("\n")}
      }
      url {
       ${Object.values(SITELANGS).join("\n")}
      }
      linkText {
       ${Object.values(SITELANGS).join("\n")}
      }
    }
  }
`;

const DELETE_NEWS = gql`
  mutation deleteNews($id: ID!) {
    deleteNews(id: $id) {
      ok
    }
  }
`;

const NewssCMSLayout: FC = () => {
  const [addNews] = useMutation<{ addNews: News }, { news: AddNewsInput }>(
    ADD_NEWS,
    {
      onCompleted({ addNews }) {
        if (addNews) {
          refetch();
        }
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  const [deleteNews] = useMutation<
    { deleteNews: { ok: boolean } },
    { id: string }
  >(DELETE_NEWS, {
    onCompleted({ deleteNews }) {
      if (deleteNews.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const { loading, error, data, refetch } = useQuery<NewsData>(NEWS);

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <Table width="100%">
        <thead>
          <Row>
            <Title width="20%">Title</Title>
            <Title width="30%">Body</Title>
            <Title width="15%">URL</Title>
            <Title width="20%">Text for link</Title>
            <Title width="15%">&nbsp;</Title>
          </Row>
        </thead>
        <tbody>
          {data &&
            data.getNewsList.data.map((news) => (
              <NewsEdit
                refetchHandler={() => refetch()}
                deleteHandler={() =>
                  deleteNews({ variables: { id: news.id! } })
                }
                key={news.id!}
                newsProp={cloneDeep(news)}
              />
            ))}
          <NewsAdd
            addHandler={(news: AddNewsInput) => {
              addNews({ variables: { news } });
            }}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default NewssCMSLayout;
