import React, { FC } from "react";
import Loader from "react-loader-spinner";
import styled from "@emotion/styled";

export const LoaderLarge: FC = () => (
  <Container>
    <Loader type="ThreeDots" color="#070202" height={50} width={50} />
  </Container>
);

export const LoaderSmall: FC = () => (
  <Container>
    <Loader type="ThreeDots" color="#070202" height={28} width={28} />
  </Container>
);

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;
