import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../theme";

const dotTyping = keyframes`
  0% {
    box-shadow: 9984px 0 0 0, 9999px 0 0 0, 10014px 0 0 0;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0, 9999px 0 0 0, 10014px 0 0 0;
  }
  33.333% {
    box-shadow: 9984px 0 0 0, 9999px 0 0 0, 10014px 0 0 0;
  }
  50% {
    box-shadow: 9984px 0 0 0, 9999px -10px 0 0, 10014px 0 0 0;
  }
  66.667% {
    box-shadow: 9984px 0 0 0, 9999px 0 0 0, 10014px 0 0 0;
  }
  83.333% {
    box-shadow: 9984px 0 0 0, 9999px 0 0 0, 10014px -10px 0 0;
  }
  100% {
    box-shadow: 9984px 0 0 0, 9999px 0 0 0, 10014px 0 0 0;
  }
`;

const DotsTyping = styled.div`
  animation: ${dotTyping} 1.5s infinite linear;
  background-color: ${colors.black};
  border-radius: 50%;
  box-shadow: 9984px 0 0 0, 9999px 0 0 0, 10014px 0 0 0;
  color: ${colors.black};
  height: 5px;
  left: -9999px;
  position: relative;
  width: 5px;
`;

export default DotsTyping;
