import React, {
  ComponentProps,
  FC,
  forwardRef,
  HTMLAttributes,
  LegacyRef,
} from "react";
import styled from "@emotion/styled";
import { adjustColorOpacity, colors, fonts } from "../theme";
import TooltipError from "./TooltipError";

interface ITextAreaProps {
  error?: string;
  label?: string;
  onCloseError?: () => void;
}

export type ITextAreaType = ITextAreaProps &
  Omit<ComponentProps<"textarea">, "ref"> &
  HTMLAttributes<HTMLTextAreaElement>;

const TextArea: FC<ITextAreaType> = forwardRef(
  ({ error, label, onCloseError, ...props }, ref) => (
    <TooltipError error={error} onClose={onCloseError} transform={props.maxLength ? "translateY(-32px);" : undefined}>
      <StyledTextArea
        className="scrollbar"
        ref={ref as LegacyRef<HTMLTextAreaElement>}
        error={!!error}
        {...props}
      />
      {props.maxLength && (
        <Counter>
          {(props.value || "").toString().length + `/${props.maxLength}`}
        </Counter>
      )}
    </TooltipError>
  )
);

TextArea.displayName = "TextArea";

export default TextArea;

const Counter = styled.span`
  ${fonts.family.theinhardt}
  ${fonts.size.smallExtra}
  color: ${adjustColorOpacity(colors.black, 0.6)};
  padding-top: 5px;
`;

const StyledTextArea = styled.textarea<{ error?: boolean }>`
  ${fonts.family.theinhardt}
  ${fonts.size.smallExtra}
  background-color: ${(props) => (props.error ? adjustColorOpacity(colors.red[0], 0.2) : "white")};;
  border: solid 1px ${(props) => (props.error ? colors.red[0] : colors.gold[3])};
  border-radius: 5px;
  box-sizing: border-box;
  color: ${(props) => (props.error ? colors.red[0] : colors.black)};
  padding: 10px 20px;
  resize: vertical;
  width: 100%;

  &::placeholder {
    color: ${(props) => (props.error ? colors.red[0] : adjustColorOpacity(colors.black, 0.6))};
    font-style: italic;
  }

  :focus {
    border-color: ${(props) => (props.error ? colors.red : colors.black)};
    outline: none;
  }

  :disabled {
    background-color: ${colors.grey[2]};
    border-color: ${colors.grey[1]};
    pointer-events: none;
  }
`;
