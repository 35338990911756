import styled from "@emotion/styled";
import React, { ComponentProps, FC, HTMLAttributes } from "react";
import { colors } from "../theme";
import Icon from "./Icon";
import Input, { IInputProps } from "./Input";

const InputSearch: FC<
  IInputProps &
    Omit<ComponentProps<"input">, "ref"> &
    HTMLAttributes<HTMLInputElement>
> = ({ className, ...props }) => (
  <Container className={className} error={!!props.error} disabled={props.disabled}>
    <Input {...props} />
    <Icon name="lens" />
  </Container>
);

export default InputSearch;

const Container = styled.div<{ error?: boolean, disabled?: boolean }>`
  position: relative;

  > svg {
    color: ${props => props.error ? colors.red[0] : props.disabled ? colors.grey[0] : colors.gold[0]};
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
  }

  > div > input {
    padding-right: 40px;
  }
`;
