import React, { FC } from "react";
import MDEditor, {
  commands,
  ICommand,
  TextState,
  TextAreaTextApi
} from "@uiw/react-md-editor";
import "../styles/mdeditor.css";
import styled from "@emotion/styled";
import { Langs } from "../types";
import { LangTexts } from "@balthasarspeyr/common";

const title3: ICommand = {
  name: "title3",
  keyCommand: "title3",
  buttonProps: { "aria-label": "Insert title3" },
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M7.637 13V3.669H6.379V7.62H1.758V3.67H.5V13h1.258V8.728h4.62V13h1.259zm3.625-4.272h1.018c1.142 0 1.935.67 1.949 1.674.013 1.005-.78 1.737-2.01 1.73-1.08-.007-1.853-.588-1.935-1.32H9.108c.069 1.327 1.224 2.386 3.083 2.386 1.935 0 3.343-1.155 3.309-2.789-.027-1.51-1.251-2.16-2.037-2.249v-.068c.704-.123 1.764-.91 1.723-2.229-.035-1.353-1.176-2.4-2.954-2.385-1.873.006-2.857 1.162-2.898 2.358h1.196c.062-.69.711-1.299 1.696-1.299.998 0 1.695.622 1.695 1.525.007.922-.718 1.592-1.695 1.592h-.964v1.074z" />
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    let modifyText = `### ${state.selectedText}\n`;
    if (!state.selectedText) {
      modifyText = `### `;
    }
    api.replaceSelection(modifyText);
  }
};

const title1: ICommand = {
  name: "title1",
  keyCommand: "title1",
  buttonProps: { "aria-label": "Insert title1" },
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8.637 13V3.669H7.379V7.62H2.758V3.67H1.5V13h1.258V8.728h4.62V13h1.259zm5.329 0V3.669h-1.244L10.5 5.316v1.265l2.16-1.565h.062V13h1.244z" />
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    let modifyText = `# ${state.selectedText}\n`;
    if (!state.selectedText) {
      modifyText = `# `;
    }
    api.replaceSelection(modifyText);
  }
};

const title2: ICommand = {
  name: "title2",
  keyCommand: "title2",
  buttonProps: { "aria-label": "Insert title2" },
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M7.638 13V3.669H6.38V7.62H1.759V3.67H.5V13h1.258V8.728h4.62V13h1.259zm3.022-6.733v-.048c0-.889.63-1.668 1.716-1.668.957 0 1.675.608 1.675 1.572 0 .855-.554 1.504-1.067 2.085l-3.513 3.999V13H15.5v-1.094h-4.245v-.075l2.481-2.844c.875-.998 1.586-1.784 1.586-2.953 0-1.463-1.155-2.556-2.919-2.556-1.941 0-2.966 1.326-2.966 2.74v.049h1.223z" />
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    let modifyText = `## ${state.selectedText}\n`;
    if (!state.selectedText) {
      modifyText = `## `;
    }
    api.replaceSelection(modifyText);
  }
};

const comment: ICommand = {
  name: "comment",
  keyCommand: "comment",
  buttonProps: { "aria-label": "Insert comment" },
  icon: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
    >
      <path d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.739 4.063 2.047 5.625l-1.993 6.368 6.946-3c1.705.439 3.334.641 4.864.641 7.174 0 12.136-4.439 12.136-9.634 0-5.812-5.701-10.007-12-10.007zm0 1c6.065 0 11 4.041 11 9.007 0 4.922-4.787 8.634-11.136 8.634-1.881 0-3.401-.299-4.946-.695l-5.258 2.271 1.505-4.808c-1.308-1.564-2.165-3.128-2.165-5.402 0-4.966 4.935-9.007 11-9.007zm-5 7.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm5 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm5 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z" />
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    let modifyText = `[//]: # (${state.selectedText})\n`;
    if (!state.selectedText) {
      modifyText = `[//]: # (Comment here)\n`;
    }
    api.replaceSelection(modifyText);
  }
};

interface ICustomMDEditorProps {
  height: number;
  data: LangTexts;
  language: Langs;
  updateLang: (data: LangTexts) => void;
}

const MDEditorComponent: FC<ICustomMDEditorProps> = ({
  height,
  data,
  language,
  updateLang
}) => {
  const updateData = (value: string) => {
    data[language] = value;
    updateLang({ ...data });
  };

  const extractComments = (value) => {
    const comments: string[] = value.match(/^\[\/\/\]: # \((.*)$/gm);
    return Array.isArray(comments)
      ? comments.map((comment) =>
          comment.slice("[//]: # (".length, comment.lastIndexOf(")"))
        )
      : [];
  };

  return (
    <div className="container">
      <MDEditor
        textareaProps={{
          spellCheck: true,
          autoCorrect: "on",
          autoComplete: "on"
        }}
        height={height}
        className="custombutton customsvg"
        value={data[language]}
        fullscreen={false}
        visiableDragbar={false}
        commands={[
          title1,
          title2,
          title3,
          commands.bold,
          commands.italic,
          commands.hr,
          commands.divider,
          commands.link,
          commands.quote,
          commands.image,
          commands.unorderedListCommand,
          commands.orderedListCommand,
          commands.checkedListCommand,
          commands.codeEdit,
          commands.codeLive,
          commands.codePreview,
          commands.divider,
          comment
        ]}
        onChange={(value: string | undefined) => {
          updateData(value || "");
        }}
      />
      <Comments>
        <h1>Comments</h1>
        <ul>
          {extractComments(data[language]).map((comment, index) => (
            <li key={index}>{comment}</li>
          ))}
        </ul>
      </Comments>
    </div>
  );
};

export default MDEditorComponent;

const Comments = styled.div`
  width: calc(100% - 15px);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2);
  border-radius: 3px;
  background-color: #fff;
  margin-top: 15px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  width: calc(100% - 20px);
  h1 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #24292e;
    font-size: 20px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 30px;
  }
  ul {
    list-style-type: disc;
  }
  li {
    width: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #24292e;
    font-size: 14px;
    line-height: 20px;
    margin-left: 5px;
  }
`;
