import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { InputTextComponent } from "../InputTextComponent";
import { WhiteButton } from "../../styles/styledcomponets";
import { useQuery, gql } from "@apollo/client";
import { LoaderLarge } from "../Loaders";
import { Author } from "@balthasarspeyr/common";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { theme } from "../../styles/theme";
const AUTHORS = gql`
  query getAuthors {
    getAuthors {
      id
      name {
        en
      }
      surname {
        en
      }
    }
  }
`;

const AddTranslators: FC<{
  translators: string[];
  updateTranslators: (translators: string[]) => void;
}> = ({ translators: translatorProps, updateTranslators }) => {
  const { data, loading, error } = useQuery(AUTHORS);

  const [filter, setFilter] = useState<string>("");
  const [translators, setTranslators] = useState<Author[]>([]);

  useEffect(() => {
    if (data) {
      const translators = data.getAuthors.filter((author) =>
        translatorProps.includes(author.id)
      );
      setTranslators(translators);
    }
  }, [data, translatorProps]);

  if (loading) return <LoaderLarge />;
  if (error) return <p>Error :( </p>;

  return (
    <Div>
      <strong>Translators</strong>:
      {translators.map((author) => (
        <Translator key={author.id}>
          <div>
            {author.name.en} {author.surname.en}
          </div>
          <div>
            <TrashIcon
              onClick={() => {
                updateTranslators(
                  translators
                    .filter((t) => t.id !== author.id)
                    .map((author) => author.id)
                );
              }}
            />
          </div>
        </Translator>
      ))}
      <InputTextComponent
        width="auto"
        helper="Add translator (search by name)"
        placeholder="name or surname"
        value={filter}
        onChange={(value) => {
          setFilter(value);
        }}
      />
      {filter.length >= 1 &&
        data.getAuthors
          .filter(
            (author) =>
              author.name.en.toLowerCase().includes(filter.toLowerCase()) ||
              author.surname.en.toLowerCase().includes(filter.toLowerCase())
          )
          .map((author) => (
            <div key={author.id}>
              {author.name.en} {author.surname.en}
              <WhiteButton
                margin="5px 0 0 5px"
                onClick={() => {
                  setFilter("");
                  updateTranslators(
                    [...translators, author].map((author) => author.id)
                  );
                }}
              >
                Add
              </WhiteButton>
            </div>
          ))}
    </Div>
  );
};

export default AddTranslators;

const Div = styled.div`
  width: fit-content;
  margin: 0 20px 0 0;
`;

const Translator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    min-width: 10px;
    width: 10px;
    min-height: 10px;
    height: 10px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    :hover {
      fill: ${theme.colors.brandRed};
    }
  }
  margin: 0px;
  padding: 0px;
`;
