import { atom, selector } from "recoil";

export enum MainSections {
  Authors = "authors",
  ShortWorksStages = "shortworksstages",
  LetterRecipients = "letterrecipients",
  EditedAuthors = "editedAuthors",
  PressHouses = "presshouses",
  Works = "Works",
  Publications = "publications",
  Users = "users",
  MDEditor = "mdeditor",
  News = "news",
  Labels = "labels"
}

export const sectionState = atom({
  key: "section",
  default: MainSections.Authors as MainSections
});

export const subsectionState = atom({
  key: "subsection",
  default: undefined as string | undefined
});
