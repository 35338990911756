import { SITELANGS, WorkMain } from "@balthasarspeyr/common";
import React, { FC } from "react";
import { ReactComponent as TrashIcon } from "../../../static/icons/trash.svg";
import {
  Actions,
  Cell,
  Row,
  WhiteButton
} from "../../../styles/styledcomponets";

interface IWorkElemProps {
  work: Partial<WorkMain>;
  deleteHandler: (id: string) => void;
  workIDHandler: (id: string, state: States) => void;
}

enum States {
  LISTING = 0,
  ADDING = 1,
  EDITING = 2
}

const ThirdAuthorWorkElem: FC<IWorkElemProps> = ({
  work,
  deleteHandler,
  workIDHandler
}) => {
  return (
    <Row key={work.id}>
      <Cell>{work.title?.en}</Cell>
      <Cell>
        {work?.authors?.[0]?.author?.name?.en}{" "}
        {work?.authors?.[0]?.author?.surname?.en}
      </Cell>
      <Cell>{work?.type?.join(", ") || ""}</Cell>
      <Cell>{work?.year}</Cell>
      <Cell>
        <Actions>
          <WhiteButton
            margin="auto 5px auto 5px"
            onClick={() => workIDHandler(work.id || "", States.EDITING)}
          >
            Edit
          </WhiteButton>
          <TrashIcon onClick={() => deleteHandler(work.id!)} />
        </Actions>
      </Cell>
    </Row>
  );
};

export default ThirdAuthorWorkElem;
