import { siteAuthors } from "@balthasarspeyr/common";
import React, { FC } from "react";
import AuthorShortWorksStages from "./AuthorShortWorksStages";

const ShortWorksStagesCMSLayout: FC = () => {
  return (
    <div>
      <AuthorShortWorksStages author={siteAuthors.balthasar.id} />
      <AuthorShortWorksStages author={siteAuthors.speyr.id} />
    </div>
  );
};

export default ShortWorksStagesCMSLayout;
