import React, { FC, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import ThirdAuthorWorkElem from "./ThirdAuthorWorkElem";
import { LoaderLarge } from "../../Loaders";
import arraySort from "array-sort";
import { cloneDeep } from "@apollo/client/utilities";

import {
  SITELANGS,
  WorkMain,
  WorksList,
  WORKTYPE
} from "@balthasarspeyr/common";
import { InputTextComponent } from "../../InputTextComponent";
import { Row, Table, Title } from "../../../styles/styledcomponets";

enum States {
  LISTING = 0,
  ADDING = 1,
  EDITING = 2
}

interface IWorksData {
  getThirdAuthorsWorks: Omit<WorksList, "data"> & {
    data: WorkMain[];
  };
}

const WORKS = gql`
  query getThirdAuthorsWorks {
    getThirdAuthorsWorks {
      data {
        originalLang
        id
        labels {
          label {
           ${Object.values(SITELANGS).join("\n")}
          }
        }
        title {
          en
        }
        type
        year
        authors {
          author {
            name {
             ${Object.values(SITELANGS).join("\n")}
            }
            surname {
             ${Object.values(SITELANGS).join("\n")}
            }
          }
        }
      }
    }
  }
`;

const DELETE_WORK = gql`
  mutation deleteWork($id: ID!) {
    deleteWork(id: $id) {
      ok
    }
  }
`;

const ThirdAuthorsWorksListComponent: FC<{
  workIDHandler: (id: string, state: States) => void;
}> = ({ workIDHandler }) => {
  const [deleteWork] = useMutation<
    { deleteWork: { ok: boolean } },
    { id: string }
  >(DELETE_WORK, {
    onCompleted({ deleteWork }) {
      if (deleteWork.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const deleteWorkHandler = (id: string): void => {
    deleteWork({
      variables: {
        id
      }
    });
  };

  const [filter, setFilter] = useState<string>("");
  const [sort, setSort] = useState<{ key: string; reverse: boolean }>({
    key: "year",
    reverse: false
  });
  const { loading, error, data, refetch } = useQuery<IWorksData>(WORKS, {
    fetchPolicy: "network-only"
  });
  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  const filteredWorks = data?.getThirdAuthorsWorks.data.filter(
    (work: WorkMain) => {
      if (filter === "") return true;
      try {
        return (
          work.authors.some((author) => {
            return Object.keys(author.author.name).some(
              (lang) =>
                author.author?.alias?.[lang]
                  ?.toLowerCase()
                  .includes(filter.toLowerCase().trim()) ||
                `${author.author?.name?.[lang]} ${author.author?.surname?.[lang]}`
                  ?.toLowerCase()
                  .includes(filter.toLowerCase().trim())
            );
          }) ||
          (work.title &&
            Object.keys(work.title).some((lang) =>
              work.title?.[lang]
                ?.toLowerCase()
                .includes(filter.toLowerCase().trim())
            )) ||
          (work.labels &&
            work.labels.some((label) =>
              Object.keys(label.label).some((lang) =>
                label.label?.[lang]
                  ?.toLowerCase()
                  .includes(filter.toLowerCase().trim())
              )
            )) ||
          work.year?.toString().includes(filter.trim())
        );
      } catch (e) {
        return true;
      }
    }
  );
  const works = arraySort(cloneDeep(filteredWorks), sort.key, {
    reverse: sort.reverse
  });

  return (
    <div>
      <InputTextComponent
        placeholder="search"
        helper="Filter by title, author, year or label"
        onChange={(value) => setFilter(value)}
        value={filter}
        margin="20px 0px 20px 0px"
        width="300px"
      />
      <Table>
        <thead>
          <Row borderBottom="1px solid #aaa">
            <Title
              width="400px"
              onClick={() =>
                setSort({ key: "title.en", reverse: !sort.reverse })
              }
            >
              Title
            </Title>

            <Title width="200px">Author</Title>
            <Title width="200px">Types</Title>
            <Title
              width="50px"
              onClick={() => setSort({ key: "year", reverse: !sort.reverse })}
            >
              Year
            </Title>
            <Title width="19%" />
          </Row>
        </thead>
        <tbody>
          {works.map((work) => (
            <ThirdAuthorWorkElem
              key={work.id}
              work={work}
              deleteHandler={deleteWorkHandler}
              workIDHandler={workIDHandler}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ThirdAuthorsWorksListComponent;
