import React, { FC } from "react";
import ThirdAuthorWorkGeneralDataComponent from "./ThirdAuthorWorkDataComponent";
import { MainWorkInput } from "../../../types";

const ThirdAuthorWorkEditorComponent: FC<{
  workCMSData: MainWorkInput;
  updateData: (data: Partial<MainWorkInput>) => void;
}> = ({ workCMSData, updateData }) => {
  return (
    <React.Fragment>
      <ThirdAuthorWorkGeneralDataComponent
        updateData={updateData}
        title="General Data"
        componentData={{
          authors: workCMSData.authors,
          baselref: workCMSData.baselref,
          year: workCMSData.year,
          originalLang: workCMSData.originalLang,
          type: workCMSData.type,
          labels: workCMSData.labels,
          title: workCMSData.title
        }}
      />
    </React.Fragment>
  );
};

export default ThirdAuthorWorkEditorComponent;
