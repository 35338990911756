import {
  TEXTFORMATS,
  PUBLICATIONLANGS,
  BookInputData,
  WorkMain,
  sitePressHouse,
  JSON2Contents,
  SingleLangContentsEntry,
  contents2string,
  PublicationInputData,
  Publication
} from "@balthasarspeyr/common";
import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import BoxWithTitleContainer from "../../BoxWithTitleContainer";
import {
  InputTextComponent,
  TextAreaComponent
} from "../../InputTextComponent";
import { Input, RedButton, WhiteButton } from "../../../styles/styledcomponets";
import AddTranslators from "../AddTranslators";
import FileUploaderComponent from "../../FileUploader";
import { useQuery, gql, useMutation } from "@apollo/client";
import { LoaderLarge } from "../../Loaders";
import { PublicationLanguageSelect } from "../../LanguageSelect";
import { PressHouseSelect } from "../../presshouses/PressHouseSelect";
import AddContainedWorks from "../AddContainedWorks";
import { v4 as uuidv4 } from "uuid";

import SingleLangContentsComponent from "../SingleLangContentsComponent";
import MDEditor from "@uiw/react-md-editor";
import CoverUploader from "../../CoverUploader";

enum STATUS {
  LISTING,
  EDITING,
  ADDING
}

const ADD_PUBLICATION = gql`
  mutation addPublication($publication: PublicationInput!) {
    addPublication(publication: $publication) {
      id
    }
  }
`;

const UPDATE_PUBLICATION = gql`
  mutation updatePublication($id: ID!, $publication: PublicationInput!) {
    updatePublication(id: $id, publication: $publication) {
      id
    }
  }
`;

const GET_PUBLICATION = gql`
  query getPublication($id: ID!) {
    getPublication(id: $id) {
      public
      list
      featured
      isbn
      pressHouse {
        id
      }
      translators {
        id
      }
      title
      subtitle
      caption
      abstract
      contents
      pages
      image
      lang
      year
      DOI
      purchaseLinks
      downloadLinks {
        url
        format
      }
      formats
      bibref
      text
      containedWorks {
        work {
          id
        }
        full
        parts
      }
    }
  }
`;

const BookEdit: FC<{
  publicationID?: string;
  cancel: () => void;
  setStatus: (status: STATUS) => void;
  action: "add" | "edit";
}> = ({ cancel, setStatus, publicationID, action }) => {
  const contentsTemplate: SingleLangContentsEntry = {
    id: "0",
    sign: "",
    children: [
      {
        id: uuidv4(),
        sign: "1",
        text: "",
        children: []
      }
    ],
    text: ""
  };

  const newPublication: BookInputData = {
    public: false,
    list: false,
    featured: false,
    isbn: "",
    title: "",
    subtitle: "",
    caption: "",
    abstract: "",
    pages: 0,
    contents: contents2string(contentsTemplate),
    pressHouse: sitePressHouse.id,
    translators: [],
    lang: Object.keys(PUBLICATIONLANGS)[0] as keyof typeof PUBLICATIONLANGS,
    year: new Date().getFullYear(),
    DOI: "",
    purchaseLinks: [],
    downloadLinks: [],
    readLink: "",
    formats: [],
    bibref: "",
    containedWorks: [],
    text: ""
  };

  const [publicationData, setPublicationData] = useState<
    BookInputData | undefined
  >(publicationID ? undefined : newPublication);

  const [work, setWork] = useState<WorkMain | undefined>(undefined);
  const [editing, setEditing] = useState<boolean>(false);
  const { loading, error, data } = useQuery(GET_PUBLICATION, {
    variables: { id: publicationID },
    skip: !publicationID,
    fetchPolicy: "network-only"
  });

  const [add] = useMutation<
    { addPublication: Publication },
    { publication: BookInputData }
  >(ADD_PUBLICATION, {
    onCompleted() {
      setStatus(STATUS.LISTING);
    },
    onError(error) {
      console.log(error);
    }
  });

  const [update] = useMutation<
    { updatePublication: Publication },
    { id: string; publication: BookInputData }
  >(UPDATE_PUBLICATION, {
    onCompleted() {
      setStatus(STATUS.LISTING);
    },
    onError(error) {
      console.log(error);
    }
  });

  useEffect(() => {
    if (data?.getPublication) {
      const publication = {
        ...data.getPublication,
        pressHouse: data.getPublication.pressHouse.id,
        translators: data.getPublication.translators.map((t) => t.id),
        containedWorks: data.getPublication.containedWorks.map((c) => ({
          ...c,
          work: c.work.id
        }))
      };
      setPublicationData(publication);
    }
  }, [data]);

  const valueChange = (value: string | boolean | [string], key: string) => {
    if (publicationData) {
      if (key === "lang") {
        setPublicationData({
          ...publicationData,
          title:
            publicationData?.title !== ""
              ? publicationData?.title
              : work?.title[(value as PUBLICATIONLANGS) || "de"],
          subtitle:
            publicationData?.subtitle !== ""
              ? publicationData?.subtitle
              : work?.subtitle?.[(value as PUBLICATIONLANGS) || "de"],
          caption:
            publicationData?.caption !== ""
              ? publicationData?.caption
              : work?.caption?.[(value as PUBLICATIONLANGS) || "de"],
          [key as keyof typeof PUBLICATIONLANGS]: value as PUBLICATIONLANGS
        });
      } else if (key === "year") {
        setPublicationData({
          ...publicationData,
          year: parseInt(value as string)
        });
      } else if (key === "pages") {
        setPublicationData({
          ...publicationData,
          pages: parseInt(value as string)
        });
      } else {
        setPublicationData({
          ...publicationData,
          [key]: value
        });
      }
    }
  };

  const updateTranslators = (translators: string[]) => {
    publicationData &&
      setPublicationData({
        ...publicationData,
        translators
      });
  };

  if (publicationID && loading) return <LoaderLarge />;

  if (!publicationID || publicationData)
    return (
      <div>
        <WhiteButton
          onClick={() => {
            action === "add" &&
              add({
                variables: {
                  publication: { ...(publicationData as BookInputData) }
                }
              });
            action === "edit" &&
              publicationID &&
              update({
                variables: {
                  id: publicationID,
                  publication: { ...(publicationData as BookInputData) }
                }
              });
          }}
        >
          Save
        </WhiteButton>
        <RedButton onClick={cancel}>Cancel</RedButton>
        <BoxWithTitleContainer title="Contained Works">
          <AddContainedWorks
            updateContainedWorks={(cw) =>
              setPublicationData({
                ...publicationData!,
                containedWorks: [...cw]
              })
            }
            containedWorks={publicationData?.containedWorks || []}
          />
        </BoxWithTitleContainer>
        <BoxWithTitleContainer title="Title and Cover">
          <div style={{ display: "flex" }}>
            <CoverUploader
              title={publicationData?.title || "Title"}
              works={publicationData?.containedWorks.map((w) => w.work) || []}
              url={publicationData?.image || ""}
              width={110}
              setImageURL={(image) => {
                valueChange(image, "image");
              }}
            />
            <div style={{ width: "100%" }}>
              <InputTextComponent
                width="90%"
                helper="Title"
                placeholder="Title"
                value={publicationData?.title || ""}
                onChange={(value) => valueChange(value, "title")}
              />
              <InputTextComponent
                width="90%"
                helper="Subtitle"
                placeholder="Subtitle"
                value={publicationData?.subtitle || ""}
                onChange={(value) => valueChange(value, "subtitle")}
              />
              <InputTextComponent
                width="90%"
                helper="Caption"
                placeholder="Caption"
                value={publicationData?.caption || ""}
                onChange={(value) => valueChange(value, "caption")}
              />
            </div>
          </div>
        </BoxWithTitleContainer>
        <BoxWithTitleContainer title="General Data">
          <GeneralData>
            <AddTranslators
              translators={publicationData?.translators || []}
              updateTranslators={updateTranslators}
            />
            <Div>
              <TextAreaComponent
                margin="0 0 20px 0"
                width="250px"
                height="80px"
                helper="Bibliographic Reference"
                placeholder="bibref"
                value={publicationData?.bibref || ""}
                onChange={(value) => {
                  valueChange(value, "bibref");
                }}
              />
              <InputTextComponent
                width="auto"
                helper="Publication year"
                placeholder="year"
                value={String(
                  publicationData?.year || new Date().getFullYear()
                )}
                onChange={(value) => {
                  valueChange(value, "year");
                }}
              />
            </Div>

            <Div>
              <Input
                width="auto"
                height="auto"
                onClick={() => setEditing(true)}
                type="checkbox"
                onChange={(e) => {
                  valueChange(e.target.checked, "public");
                }}
                checked={publicationData?.public || false}
              />
              Public (required to be visible on Works Section)
              <br />
              <Input
                width="auto"
                height="auto"
                onClick={() => setEditing(true)}
                type="checkbox"
                onChange={(e) => {
                  valueChange(e.target.checked, "list");
                }}
                checked={publicationData?.list || false}
              />
              Required to be visible on Publications Section (also needs to be
              Public)
              <br />
              <Input
                width="auto"
                height="auto"
                onClick={() => setEditing(true)}
                type="checkbox"
                onChange={(e) => {
                  valueChange(e.target.checked, "featured");
                }}
                checked={publicationData?.featured || false}
              />
              Featured
              <br />
              <InputTextComponent
                width="auto"
                helper="DOI"
                placeholder="DOI"
                value={publicationData?.DOI || ""}
                onChange={(value) => {
                  valueChange(value, "DOI");
                }}
              />
              <InputTextComponent
                width="auto"
                helper="isbn"
                placeholder="isbn"
                value={publicationData?.isbn || ""}
                onChange={(value) => {
                  valueChange(value, "isbn");
                }}
              />
              <InputTextComponent
                width="auto"
                helper="Purchase Link"
                placeholder="purchase link"
                value={
                  publicationData?.purchaseLinks &&
                  publicationData?.purchaseLinks?.length > 0
                    ? publicationData?.purchaseLinks?.at(0)
                    : ""
                }
                onChange={(value) => {
                  valueChange([value], "purchaseLinks");
                }}
              />
            </Div>
            <Div>
              <PublicationLanguageSelect
                value={
                  publicationData?.lang || Object.keys(PUBLICATIONLANGS)[0]
                }
                onChange={(e) => {
                  valueChange(e.target.value, "lang");
                }}
              />
              <PressHouseSelect
                value={publicationData?.pressHouse}
                onChange={(e) => {
                  valueChange(e.target.value, "pressHouse");
                }}
              />
              <InputTextComponent
                width="auto"
                helper="Number of pages"
                placeholder="number"
                value={String(publicationData?.pages || 0)}
                onChange={(value) => {
                  valueChange(value, "pages");
                }}
              />
            </Div>
          </GeneralData>
        </BoxWithTitleContainer>
        <BoxWithTitleContainer title="Abstract">
          <MDEditor
            textareaProps={{
              spellCheck: true,
              autoCorrect: "on",
              autoComplete: "on"
            }}
            value={publicationData?.abstract || ""}
            onChange={(value) => {
              valueChange(value as string, "abstract");
            }}
          />
        </BoxWithTitleContainer>
        <BoxWithTitleContainer title="Text (only for works who are solely prefaces or epilogues)">
          <MDEditor
            textareaProps={{
              spellCheck: true,
              autoCorrect: "on",
              autoComplete: "on"
            }}
            height={200}
            value={publicationData?.text || ""}
            onChange={(value) => {
              valueChange(value as string, "text");
            }}
          ></MDEditor>
        </BoxWithTitleContainer>
        <BoxWithTitleContainer title="Contents">
          {
            <SingleLangContentsComponent
              data={
                JSON2Contents(
                  publicationData?.contents || ""
                ) as SingleLangContentsEntry
              }
              updateContents={(contents) => {
                valueChange(contents2string(contents), "contents");
              }}
            />
          }
        </BoxWithTitleContainer>
        <BoxWithTitleContainer title="Uploads">
          <Uploads>
            {Object.values(TEXTFORMATS).map((format) => {
              return (
                <Div key={format}>
                  <FileUploaderComponent
                    text={"Upload " + format}
                    width="fit-content"
                    height="fit-content"
                    margin="0"
                    padding="0"
                    setFileURL={(url) => {
                      const download = publicationData?.downloadLinks.find(
                        (dw) => dw.format === format
                      );
                      if (download) {
                        download.url = url;
                      } else {
                        publicationData?.downloadLinks.push({
                          format,
                          url
                        });
                      }

                      publicationData &&
                        setPublicationData({
                          ...publicationData
                        });
                    }}
                    type={format}
                    // name={encodeURIComponent(
                    //   `${
                    //     work?.title?.en?.replace(/\s/g, "").substring(1, 5) ||
                    //     "shortWork"
                    //   }-${publicationData?.year || ""}-${randomstring.generate(
                    //     5
                    //   )}.${format}`.replace(/\s/g, "")
                    // )}
                  />

                  {publicationData?.downloadLinks?.find(
                    (dwn) => dwn.format === format
                  )?.url &&
                    publicationData.downloadLinks?.find(
                      (dwn) => dwn.format === format
                    )?.url !== "" && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          publicationData.downloadLinks?.find(
                            (dwn) => dwn.format === format
                          )?.url
                        }
                      >
                        {" "}
                        Download{" "}
                      </a>
                    )}
                </Div>
              );
            })}
          </Uploads>
        </BoxWithTitleContainer>
      </div>
    );

  return <div>You should never see this</div>;
};

export default BookEdit;

const Uploads = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;
const Div = styled.div`
  //border: 1px solid black;
  width: fit-content;
  margin: 0 20px 0 0; ;
`;

const GeneralData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
