import styled from "@emotion/styled";
import React, { FC } from "react";
import EditedAuthorEdit from "./EditedAuthorEdit";
import EditedAuthorAdd from "./EditedAuthorAdd";
import { EditedAuthor, SITELANGS } from "@balthasarspeyr/common";
import { useQuery, useMutation, gql } from "@apollo/client";
import cloneDeep from "clone-deep";
import { TitlesHeader } from "../../styles/styledcomponets";
import { EditedAuthorInput } from "../../types";
import { LoaderLarge } from "../Loaders";

type EditedAuthorsData = {
  getEditedAuthors: Omit<
    EditedAuthor,
    "translations" | "anthologies" | "prefaces" | "eqpilogues"
  >[];
};

const EDITEDAUTHORS = gql`
  query getEditedAuthors {
    getEditedAuthors {
      id
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      surname {
       ${Object.values(SITELANGS).join("\n")}
      }
      alias {
       ${Object.values(SITELANGS).join("\n")}
      }
      image
    }
  }
`;

const ADD_EDITEDAUTHOR = gql`
  mutation addEditedAuthor($editedAuthor: EditedAuthorInput!) {
    addEditedAuthor(editedAuthor: $editedAuthor) {
      id
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      surname {
       ${Object.values(SITELANGS).join("\n")}
      }
      alias {
       ${Object.values(SITELANGS).join("\n")}
      }
      image
    }
  }
`;

const DELETE_EDITEDAUTHOR = gql`
  mutation deleteEditedAuthor($id: ID!) {
    deleteEditedAuthor(id: $id) {
      ok
    }
  }
`;

const EditedAuthorsCMSLayout: FC = () => {
  const [addEditedAuthor] = useMutation<
    { addEditedAuthor: EditedAuthor },
    { editedAuthor: EditedAuthorInput }
  >(ADD_EDITEDAUTHOR, {
    onCompleted({ addEditedAuthor }) {
      if (addEditedAuthor) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const [deleteEditedAuthor] = useMutation<
    { deleteEditedAuthor: { ok: boolean } },
    { id: string }
  >(DELETE_EDITEDAUTHOR, {
    onCompleted({ deleteEditedAuthor }) {
      if (deleteEditedAuthor.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const { loading, error, data, refetch } =
    useQuery<EditedAuthorsData>(EDITEDAUTHORS);

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <Table width="100%">
        <thead>
          <Row>
            <Title width="50px">&nbsp;</Title>
            <Title width="20%">Name</Title>
            <Title width="20%">Surname</Title>
            <Title width="20%">Alias</Title>
            <Title width="25%">&nbsp;</Title>
          </Row>
        </thead>
        <tbody>
          {data &&
            data.getEditedAuthors.map((editedAuthor) => (
              <EditedAuthorEdit
                refetchHandler={() => refetch()}
                deleteHandler={() =>
                  deleteEditedAuthor({ variables: { id: editedAuthor.id! } })
                }
                key={editedAuthor.id!}
                editedAuthorProp={cloneDeep(editedAuthor)}
              />
            ))}

          <EditedAuthorAdd
            addHandler={(editedAuthor: EditedAuthorInput) => {
              addEditedAuthor({ variables: { editedAuthor } });
            }}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default EditedAuthorsCMSLayout;

const EditedAuthorsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const Table = styled.table`
  border: 0;
`;

const Row = styled.tr``;
const Title = styled.th<{ width: string }>`
  font-size: 0.9rem;
  font-weight: bold;
  width: ${(props) => props.width};
  border-bottom: 1px solid black;
`;
