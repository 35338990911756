import { gql, useQuery } from "@apollo/client";
import React, { FC } from "react";
import SelectInputComponent from "../SelectInputComponent";

const GET_PRESS_HOUSES = gql`
  query getPressHouses {
    getPressHouses {
      data {
        id
        name
      }
    }
  }
`;

export const PressHouseSelect: FC<{
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  hideHelper?: boolean;
}> = ({ value, onChange, hideHelper = false }) => {
  const { data, loading, error } = useQuery(GET_PRESS_HOUSES);
  if (error) return <div>Error</div>;
  if (loading) return <div>Loading...</div>;
  return (
    <SelectInputComponent
      width="auto"
      helper="Press House"
      hideHelper={hideHelper}
      value={value || data.getPressHouses.data[0].id}
      onChange={onChange}
      Elem={data.getPressHouses.data.map((pressHouse) => ({
        key: pressHouse.id,
        value: pressHouse.name,
      }))}
    />
  );
};
