import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { colors, fonts, transition, zIndexes } from "../theme";
import { IPositionArrowType, IPositionType } from "./Tooltip";

interface ITooltipErrorProps {
  className?: string;
  arrowPosition?: IPositionArrowType;
  position?: IPositionType;
  transform?: string;
  text: string | JSX.Element;
}

const TooltipText: FC<ITooltipErrorProps> = ({
  arrowPosition = "middle",
  children,
  text,
  position = "bottom",
  transform,
  ...props
}) => (
  <TextWrap {...props}>
    {children}
    <Text transform={transform}>
      <Arrow position={position} arrowPosition={arrowPosition} />
      <div>
        {text}
      </div>
    </Text>
  </TextWrap>
);

export default TooltipText;

const Arrow = styled.div<{ arrowPosition: IPositionArrowType; position: IPositionType }>`
  background-color: inherit;
  height: 9px;
  position: absolute;
  width: 9px;

  ${props => {
    switch (props.position) {
      case "bottom":
        return css`
          border-top-left-radius: 3px;
          left: ${props.arrowPosition === "middle"
            ? "50%"
            : props.arrowPosition === "left"
            ? "9px"
            : "calc(100% - 19px)"};
          top: 0;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case "left":
        return css`
          border-top-right-radius: 3px;
          right: 0;
          top: 50%;
          transform: translate(50%, -50%) rotate(45deg);
        `;
      case "right":
        return css`
          border-bottom-left-radius: 3px;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case "top":
        return css`
          border-bottom-right-radius: 3px;
          left: ${props.arrowPosition === "middle"
            ? "50%"
            : props.arrowPosition === "left"
            ? "9px"
            : "calc(100% - 19px)"};
          bottom: 0;
          transform: translate(-50%, 50%) rotate(45deg);
        `;
    }
  }}
`;

const Text = styled.div<{ transform?: string }>`
  ${fonts.family.theinhardt}
  ${fonts.size.smallExtra}

  background-color: ${colors.blue[3]};
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.white};
  cursor: default;
  margin: 10px 0;
  padding: 10px 20px;
  position: absolute;
  z-index: ${zIndexes.modal};

  ${props => props.transform && `transform: ${props.transform};`}

  > div:last-of-type {
    align-items: center;
    display: flex;
  }
`;

const TextWrap = styled.div`
  cursor: pointer;
  position: relative;

  * {
    white-space: nowrap!important;
  }

  > div:last-of-type {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ${transition}s, opacity ${transition}s linear;
  }

  :hover > div:last-of-type {
    visibility: visible;
    opacity: 1;
    transition: opacity ${transition}s linear;
  }
`;
