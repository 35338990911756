import { SITELANGS, LangTexts } from "@balthasarspeyr/common";
import styled from "@emotion/styled";
// import { useEffect } from "hoist-non-react-statics/node_modules/@types/react";
import React, { FC, useState, useEffect } from "react";
import { Input } from "../styles/styledcomponets";

const MultilingualInput: FC<{
  multilang: boolean;
  onChange: (value: LangTexts) => void;
  editing?: boolean;
  value?: LangTexts;
  width?: string;
  height?: string;
  border?: string;
  marginTop?: string;
  marginBottom?: string;
}> = ({
  multilang,
  onChange,
  value,
  editing: edit,
  width,
  height,
  border,
  marginTop,
  marginBottom,
}) => {
  const cleanText = Object.keys(SITELANGS).reduce((acc, lang) => {
    acc[lang] = "";
    return acc;
  }, {} as LangTexts);

  const updateValue = (value: string, lang: string) => {
    setText({
      ...text,
      [lang]: value,
    });

    onChange({
      ...text,
      [lang]: value,
    });
  };

  const [text, setText] = useState<LangTexts>(
    value || {
      ...cleanText,
    }
  );

  const [editing, setEditing] = useState<boolean>(edit || true);

  useEffect(() => {
    setText(
      value || {
        ...cleanText,
      }
    );
  }, [value]);

  return (
    <Div>
      {multilang &&
        Object.keys(SITELANGS).map((lang) => (
          <Input
            placeholder={`${SITELANGS[lang]} text`}
            key={lang}
            value={text[lang]}
            onChange={(e) => {
              setEditing(true);
              updateValue(e.target.value, lang);
            }}
            {...{ width, height, border, marginTop, marginBottom, editing }}
          />
        ))}
      {!multilang && (
        <Input
          value={text?.en || ""}
          onChange={(e) => {
            updateValue(e.target.value, "en");
          }}
          {...{ width, height, border, marginTop, marginBottom, editing }}
        />
      )}
    </Div>
  );
};

export default MultilingualInput;

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;
