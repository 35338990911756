import styled from "@emotion/styled";
import React, { FC } from "react";
import { breakpoints, colors } from "../theme";
import Button, { IButtonProps } from "./Button";
import Icon, { IIconProps } from "./Icon";

const ButtonIcon: FC<IButtonProps & IIconProps> = ({ children, name, ...props }) => (
  <StyledButton {...props}>
    <span>{children}</span>
    <Icon name={name} />
  </StyledButton>
);

export default ButtonIcon;

const StyledButton = styled(Button)`
  padding: 0;

  span {
    align-items: center;
    display: flex;
    flex: 1;
    border-right: 1px solid ${colors.gold[3]};
    min-height: 22px;
    padding: 10px 15px 8px 15px;
  }

  svg {
    min-width: 20px;
    padding: 10px;
  }

  @media screen and (min-width: ${breakpoints.mobile}px) {
    padding: 0;

    span {
      padding: 10px 20px 8px 20px;
    }
  }
`;
