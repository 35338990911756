import {
  Author,
  AUTHORROLE,
  EditedAuthor,
  LangTexts,
  News,
  Recipient,
  WORKTYPE,
  WorkLetter,
  WorkEditedWork,
  ShortWorksStage,
  WorkShortWork,
  ContentsEntry,
  WorkMain,
  Publication,
  PressHouse
} from "@balthasarspeyr/common";

export enum AuthorMatch {
  EXACT_MATCH = "EXACT_MATCH",
  ANY_OF_THEM = "ANY_OF_THEM",
  ALL_OF_THEM = "ALL_OF_THEM"
}

/********************************************************** */
/*********************** CMS TYPES ************************ */
/********************************************************** */
export type AddRecipientInput = Omit<
  Recipient,
  "id" | "letters" | "description" | "url"
> & {
  description: Array<{
    author: string;
    description: LangTexts;
    correspondenceLanguage: string;
  }>;
};

export type AddNewsInput = Omit<News, "id">;
export type UpdateNewsInput = Omit<News, "id">;

export type WorkLetterInput = Omit<
  WorkLetter,
  | "id"
  | "createdBy"
  | "lastUpdatedBy"
  | "createdAt"
  | "updatedAt"
  | "authors"
  | "letterRecipient"
  | "originalLangPubs"
  | "allPubs"
  | "relatedWorks"
  | "numPublications"
  | "labels"
  | "url"
> & {
  authors: Array<{
    id: string;
    role: AUTHORROLE;
  }>;
  letterRecipient: string;
  labels: Array<string>;
};

export type ShortWorkInput = Omit<
  WorkShortWork,
  | "id"
  | "createdBy"
  | "lastUpdatedBy"
  | "createdAt"
  | "updatedAt"
  | "authors"
  | "originalLangPubs"
  | "allPubs"
  | "relatedWorks"
  | "numPublications"
  | "shortWorksStage"
  | "labels"
  | "url"
> & {
  authors: Array<{
    id: string;
    role: AUTHORROLE;
  }>;
  shortWorksStage: string;
  labels: Array<string>;
};

export type EditedWorkInput = Omit<
  WorkEditedWork,
  | "id"
  | "editedAuthor"
  | "contents"
  | "authors"
  | "originalLangPubs"
  | "allPubs"
  | "relatedWorks"
  | "numPublications"
  | "url"
  | "createdBy"
  | "lastUpdatedBy"
  | "createdAt"
  | "updatedAt"
  | "labels"
> & {
  editedAuthor: string;
  contents: string;
  authors: Array<{
    id: string;
    role: AUTHORROLE;
  }>;
  labels: Array<string>;
};

export type MainWorkInput = Omit<
  WorkMain,
  | "id"
  | "contents"
  | "authors"
  | "originalLangPubs"
  | "allPubs"
  | "relatedWorks"
  | "numPublications"
  | "url"
  | "createdBy"
  | "lastUpdatedBy"
  | "createdAt"
  | "updatedAt"
  | "labels"
> & {
  contents: string;
  authors: Array<{
    id: string;
    role: AUTHORROLE;
  }>;
  labels: Array<string>;
};

export type IAddAuthorInput = Omit<Author, "id" | "works">;

export type EditedAuthorInput = Omit<
  EditedAuthor,
  "id" | "anthologies" | "translations" | "prefaces" | "epilogues" | "allWorks"
>;

export type ShortWorksStageInput = Omit<
  ShortWorksStage,
  "id" | "articles" | "contributions" | "author" | "url"
> & {
  author: string;
};

export interface IAddPressHouseInput {
  name: string;
  country?: string[];
  city?: string[];
  website: string;
}

// export interface IPressHouse {
//   id: string;
//   name: string;
//   website: string;
//   country?: string[];
//   city?: string[];
//   publications?: Publication[];
// }

export interface IUser {
  id: string;
  email: string;
  name: string;
  surname: string;
  token?: string;
  password?: string;
  role: UserRole[];
  authorized: boolean;
}

// export interface IWork {
//   id: string;
//   authors: Array<{ author: Author; role: AuthorRole }>;
//   year: number;
//   baselref: string;
//   contents: string;
//   title: LangTexts;
//   subtitle: LangTexts;
//   caption: LangTexts;
//   description: LangTexts;
//   excerpt: LangTexts;
//   published: boolean;
//   children: IWork[];
//   originalLang: Langs;
// }

export interface IWorkInputData {
  authors: Array<{ id: string; role: string }>;
  year: number;
  baselref: string;
  contents: ContentsEntry;
  title: LangTexts;
  subtitle: LangTexts;
  caption: LangTexts;
  description: LangTexts;
  excerpt: LangTexts;
  published: boolean;
  children: string[];
  originalLang: string;
}

export enum Langs {
  en = "en",
  es = "es",
  fr = "fr",
  it = "it",
  de = "de",
  jpn = "jpn"
}

export enum AuthorCategory {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY"
}

export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
  BOOKS = "BOOKS"
}

export interface IContainedWork {
  id: string;
  parts?: string[];
  full: boolean;
}

// export interface IBook {
//   id: string;
//   authors: Array<Author>;
//   translators: Array<Author>;
//   year: number;
//   baselref: string;
//   contents: string;
//   title: LangTexts;
//   subtitle: LangTexts;
//   caption: LangTexts;
//   description: LangTexts;
//   excerpt: LangTexts;
//   public: boolean;
//   list: boolean;
//   editions: IBook[];
//   lang: Langs;
//   image: string;
//   pages?: number;
//   isbn?: string;
//   pressHouse: PressHouse;
//   purchaseLinks?: string[];
//   downloadLinks?: string[];
//   readLink?: string;
//   formats: Array<string>;
//   containedWorks: IContainedWork[];
// }

// export interface IBookInputData {
//   translators: Array<Author>;
//   contents: ContentsEntry;
//   title: LangTexts;
//   subtitle: LangTexts;
//   caption: LangTexts;
//   description: LangTexts;
//   excerpt: LangTexts;
//   public: boolean;
//   list: boolean;
//   editions: IBook[];
//   lang: string;
//   image?: string;
//   year?: number;
//   pages?: number;
//   isbn?: string;
//   pressHouse?: string;
//   purchaseLinks?: string[];
//   downloadLinks?: string[];
//   readLink?: string;
//   formats: Array<string>;
//   containedWorks: IContainedWork[];
// }
