import styled from "@emotion/styled";
import { fonts } from "../theme";

const FormField = styled.div`
  padding: 10px 0;

  > label {
    ${fonts.family.theinhardt}
    ${fonts.size.smallExtra}

    display: block;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 6px;
  }

  textarea {
    min-height: 120px;
  }
`;

export default FormField;
