import styled from "@emotion/styled";
import React, { FC, useState, useEffect } from "react";
import { theme } from "../../styles/theme";
import { LangTexts, SITELANGS } from "@balthasarspeyr/common";
import { gql, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import BoxWithTitleContainer from "../BoxWithTitleContainer";
import MultiLingualContainer from "../MultiLingualContainer";
import MDEditorComponent from "../MDEditorComponent";
import {
  BlackButton,
  Cell,
  Input,
  RedButton,
  Row,
  SelectInput,
  Table,
  Title,
  WhiteButton
} from "../../styles/styledcomponets";
import { ShortWorksStageInput } from "../../types";

const langs: string[] = Object.values(SITELANGS);

interface ShortWorksStageEditProps {
  author: string;
  closeHandler: () => void;
  addHandler: (shortWorksStage: ShortWorksStageInput) => void;
}

const ShortWorksStageAdd: FC<ShortWorksStageEditProps> = ({
  author,
  addHandler,
  closeHandler
}) => {
  const emptyShortWorksStage: ShortWorksStageInput = {
    author,
    name: Object.values(SITELANGS).reduce(
      (acc, lang) => ({ ...acc, [lang]: "" }),
      {}
    ),
    abstract: Object.values(SITELANGS).reduce(
      (acc, lang) => ({ ...acc, [lang]: "" }),
      {}
    ),
    description: Object.values(SITELANGS).reduce(
      (acc, lang) => ({ ...acc, [lang]: "" }),
      {}
    ),
    order: 0
  };

  const [shortWorksStage, setShortWorksStage] =
    useState<ShortWorksStageInput>(emptyShortWorksStage);

  const [shortWorksStageAbstract, setShortWorksStageAbstract] =
    useState<LangTexts>({
      ...emptyShortWorksStage.abstract
    });

  const [shortWorksStageDescription, setShortWorksStageDescription] =
    useState<LangTexts>({
      ...emptyShortWorksStage.description
    });

  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const valueChange = (value, key: string, lang?: string) => {
    if (key !== "order") {
      shortWorksStage[key][lang] = value;
    } else {
      shortWorksStage[key] = Number(value);
    }

    setShortWorksStage({ ...shortWorksStage });
    setPendingChange(true);
  };

  const onSave = () => {
    addHandler({
      ...shortWorksStage,
      abstract: shortWorksStageAbstract,
      description: shortWorksStageDescription
    });
    setPendingChange(false);
    closeHandler();
  };

  return (
    <div>
      <Actions>
        {pendingChange ? (
          <BlackButton
            onClick={() => {
              onSave();
            }}
          >
            Save
          </BlackButton>
        ) : null}
        {pendingChange ? (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Close without saving
          </RedButton>
        ) : (
          <WhiteButton
            onClick={() => {
              closeHandler();
            }}
          >
            Back to ShortWorksStages List
          </WhiteButton>
        )}
      </Actions>
      <Table>
        <thead>
          <Row>
            <Title width="40%">Name</Title>
            <Title width="20%">&nbsp;</Title>
            <Title width="5%">Order</Title>
          </Row>
        </thead>
        <tbody>
          {langs.map((lang) => (
            <Row key={`${lang}`}>
              <Cell>
                <Input
                  type="text"
                  name="name"
                  width="90%"
                  marginBottom="5px"
                  placeholder={`Stage name in ${lang}`}
                  editing={true}
                  onChange={(e) => valueChange(e.target.value, "name", lang)}
                  value={shortWorksStage.name[lang] || ""}
                />
              </Cell>
              <Cell>{lang}</Cell>
              {lang == "en" && (
                <Cell>
                  <Input
                    type="number"
                    name="order"
                    width="90%"
                    editing={true}
                    onChange={(e) => valueChange(e.target.value, "order")}
                    value={shortWorksStage.order || 0}
                  />
                </Cell>
              )}
            </Row>
          ))}
        </tbody>
      </Table>
      <MultiLingualContainer
        langWidth="100"
        title="Abstract"
        updateData={(data) => {
          setPendingChange(true);
          setShortWorksStageAbstract({ ...(data as LangTexts) });
        }}
        componentData={{ ...shortWorksStageAbstract }}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <MultiLingualContainer
        langWidth="100"
        title="Description"
        updateData={(data) => {
          setPendingChange(true);
          setShortWorksStageDescription({ ...(data as LangTexts) });
        }}
        componentData={{ ...shortWorksStageDescription }}
        Component={MDEditorComponent}
        componentProps={{ height: "200px" }}
      />
      <Actions>
        {pendingChange ? (
          <BlackButton
            onClick={() => {
              onSave();
            }}
          >
            Save
          </BlackButton>
        ) : null}
        {pendingChange ? (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Close without saving
          </RedButton>
        ) : (
          <RedButton
            onClick={() => {
              closeHandler();
            }}
          >
            Back to ShortWorksStages List
          </RedButton>
        )}
      </Actions>
    </div>
  );
};

export default ShortWorksStageAdd;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
