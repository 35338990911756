import React, { FC, useEffect, useState } from "react";
import {
  AUTHORROLE,
  PUBLICATIONLANGS,
  SITELANGS,
  WORKTYPE
} from "@balthasarspeyr/common";
import BoxWithTitleContainer from "../../BoxWithTitleContainer";
import styled from "@emotion/styled";
import { InputTextComponent } from "../../InputTextComponent";
import { MainWorkInput } from "../../../types";
import AddAuthors from "../AddAuthors";
import { PublicationLanguageSelect } from "../../LanguageSelect";
import { Checkbox } from "@balthasarspeyr/ui";
import LabelsSelector from "../../labels/LabelsSelector";

interface IAuthorRole {
  role: AUTHORROLE;
  id: string;
}

const ThirdAuthorWorkDataComponent: FC<{
  title: string;
  componentData: Partial<MainWorkInput>;
  updateData: (data: Partial<MainWorkInput>) => void;
}> = ({ title: containerTitle, componentData, updateData }) => {
  const availableTypes = Object.values(WORKTYPE).filter(
    (t) => t !== WORKTYPE.EDITED_WORK
  );

  const [addedAuthors, setAddedAuthors] = useState<Array<IAuthorRole>>(
    componentData.authors || []
  );

  const [year, setYear] = useState<string>(
    componentData.year?.toString() || ""
  );

  const [originalLang, setOriginalLang] = useState<string>(
    componentData.originalLang || PUBLICATIONLANGS.de
  );

  const [title, setTitle] = useState<string>(componentData.title?.en || "");
  const [type, setType] = useState<WORKTYPE[]>(componentData.type || []);
  const [labels, setLabels] = useState<string[]>(componentData.labels || []);

  // if a list of authors are passed as argument, set them as added
  useEffect(() => {
    setAddedAuthors(componentData.authors || []);
    setYear(componentData.year?.toString() || "");
    setOriginalLang(componentData.originalLang || PUBLICATIONLANGS.de);
    setType(componentData.type || []);
    setLabels(componentData.labels || []);
    setTitle(componentData.title?.en || "");
  }, [componentData]);

  const ComponentJSX = (
    <Container>
      <AuthorDiv>
        <InputTextComponent
          margin="0px 20px 10px 0px"
          padding="0px 0px 0px 0px"
          width="350px"
          placeholder="Title"
          value={title}
          helper="Title"
          onChange={(value) => {
            updateData({
              title: Object.values(SITELANGS).reduce(
                (acc, lang) => ({ ...acc, [lang]: value }),
                {} as Record<SITELANGS, string>
              )
            });
            setTitle(value);
          }}
        />
        <br />
        <AddAuthors
          authors={addedAuthors}
          updateAuthors={(authors: IAuthorRole[]) => {
            updateData({ authors });
            setAddedAuthors([...authors]);
          }}
        />
      </AuthorDiv>

      <div>
        <LabelsSelector
          labels={labels}
          showHelper={true}
          onChange={(value) => {
            updateData({ labels: value });
            setLabels(value);
          }}
        />
      </div>
      <div>
        <PublicationLanguageSelect
          value={originalLang}
          onChange={(e) => {
            updateData({ originalLang: e.target.value });
            setOriginalLang(e.target.value);
          }}
        />

        <br />
        <InputTextComponent
          margin="0px 20px 10px 0px"
          padding="0px 0px 0px 0px"
          width="200px"
          placeholder="Year"
          value={year}
          helper="First Publication Year"
          onChange={(value) => {
            updateData({ year: Number(value) });
            setYear(value);
          }}
        />
      </div>
      <div>
        {availableTypes.map((worktype) => (
          <Checkbox
            key={worktype}
            label={worktype}
            onChange={(e) => {
              const types = e.target.checked
                ? [...type, worktype]
                : type.filter((t) => t !== worktype);
              setType(types);
              updateData({ type: types });
            }}
            checked={type.includes(worktype)}
          />
        ))}
      </div>
    </Container>
  );

  return (
    <BoxWithTitleContainer title={containerTitle}>
      {ComponentJSX}
    </BoxWithTitleContainer>
  );
};

export default ThirdAuthorWorkDataComponent;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AuthorDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  width: 30%;
`;
