import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import RecipientEdit from "./RecipientEdit";
import { AddRecipientInput } from "../../types";
import {
  LangTexts,
  Recipient,
  siteAuthors,
  SITELANGS
} from "@balthasarspeyr/common";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  WhiteButton,
  Table,
  Row,
  Title,
  Cell
} from "../../styles/styledcomponets";
import { LoaderLarge } from "../Loaders";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { theme } from "../../styles/theme";
import { cloneDeep } from "@apollo/client/utilities";
import RecipientAdd from "./RecipientAdd";

enum State {
  EDITING = "editing",
  ADDING = "adding",
  LISTING = "listing"
}

type RecipientData = Omit<Recipient, "letters">;

type RecipientsQueryData = {
  getRecipients: RecipientData[];
};

const RECIPIENTS = gql`
  query getRecipients {
    getRecipients {
      id
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      surname {
       ${Object.values(SITELANGS).join("\n")}
      }
      alias {
       ${Object.values(SITELANGS).join("\n")}
      }
      abstract {
       ${Object.values(SITELANGS).join("\n")}
      }
      image
      description {
        author {
          id
          name {
            en
          }
          surname {
            en
          }
        }
        description {
         ${Object.values(SITELANGS).join("\n")}
        }
        correspondenceLanguage
      }
    }
  }
`;

const ADD_RECIPIENT = gql`
  mutation addRecipient($recipient: RecipientInput!) {
    addRecipient(recipient: $recipient) {
      id
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      surname {
       ${Object.values(SITELANGS).join("\n")}
      }
      alias {
       ${Object.values(SITELANGS).join("\n")}
      }
      image
      abstract {
       ${Object.values(SITELANGS).join("\n")}
      }
      description {
        author {
          id
          name {
            en
          }
          surname {
            en
          }
        }
        description {
         ${Object.values(SITELANGS).join("\n")}
        }
        correspondenceLanguage
      }
    }
  }
`;

const DELETE_RECIPIENT = gql`
  mutation deleteRecipient($id: ID!) {
    deleteRecipient(id: $id) {
      ok
    }
  }
`;

const RecipientsCMSLayout: FC = () => {
  const [addRecipient] = useMutation<
    { addRecipient: Recipient },
    { recipient: AddRecipientInput }
  >(ADD_RECIPIENT, {
    onCompleted({ addRecipient }) {
      if (addRecipient) {
        refetch();
        console.info("Added!!!");
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const [deleteRecipient] = useMutation<
    { deleteRecipient: { ok: boolean } },
    { id: string }
  >(DELETE_RECIPIENT, {
    onCompleted({ deleteRecipient }) {
      if (deleteRecipient.ok) {
        refetch();
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const [state, setState] = useState<State>(State.LISTING);
  const [recipient, setRecipient] = useState<RecipientData | undefined>(
    undefined
  );

  const blankLangs: LangTexts = Object.values(SITELANGS).reduce((acc, lang) => {
    acc[lang] = "";
    return acc;
  }, {} as LangTexts);

  const newRecipient: AddRecipientInput = {
    name: { ...blankLangs },
    surname: { ...blankLangs },
    alias: { ...blankLangs },
    abstract: { ...blankLangs },
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNB0cMh8tYuR-5M1dNRL73rBm2zDfXIO5TlyGbie8--GYnz7-e4ZAPPfq4Bb2fPW_Snm0&usqp=CAU",
    description: [
      {
        author: siteAuthors.balthasar.id,
        description: { ...blankLangs },
        correspondenceLanguage: "de"
      },
      {
        author: siteAuthors.speyr.id,
        description: { ...blankLangs },
        correspondenceLanguage: "de"
      }
    ]
  };

  const { loading, error, data, refetch } = useQuery<RecipientsQueryData>(
    RECIPIENTS,
    {
      fetchPolicy: "network-only"
    }
  );

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  if (state === State.LISTING) {
    return (
      <div>
        <WhiteButton
          onClick={() => {
            setState(State.ADDING);
          }}
        >
          Add new recipient
        </WhiteButton>
        <Table width="100%">
          <thead>
            <Row>
              <Title width="50px">&nbsp;</Title>
              <Title width="250px">Name</Title>
              <Title width="250px">Surname</Title>
              <Title width="25%">&nbsp;</Title>
            </Row>
          </thead>
          <tbody>
            {data &&
              data.getRecipients.map((recipientIT: RecipientData) => (
                <Row key={recipientIT.id}>
                  <Cell>
                    {recipientIT.image && recipientIT.image !== "" && (
                      <Img src={recipientIT.image} />
                    )}
                  </Cell>
                  <Cell>{recipientIT.name.en}</Cell>
                  <Cell>{recipientIT.surname.en}</Cell>
                  <Cell>
                    <Actions>
                      <WhiteButton
                        margin="auto 5px auto 5px"
                        onClick={() => {
                          setRecipient(recipientIT);
                          setState(State.EDITING);
                        }}
                      >
                        Edit
                      </WhiteButton>
                      <TrashIcon
                        onClick={() => {
                          if (
                            confirm(
                              "Are you sure you want to delete this recipient? (you will remove all his works and publications)"
                            )
                          ) {
                            deleteRecipient({
                              variables: { id: recipientIT.id! }
                            });
                          }
                        }}
                      />
                    </Actions>
                  </Cell>
                </Row>
              ))}
          </tbody>
        </Table>
        <WhiteButton
          onClick={() => {
            setState(State.ADDING);
          }}
        >
          Add new recipient
        </WhiteButton>
      </div>
    );
  } else if (state === State.EDITING && recipient) {
    return (
      <RecipientEdit
        recipientProp={recipient}
        refetchHandler={() => refetch()}
        closeHandler={() => setState(State.LISTING)}
      />
    );
  } else if (state === State.ADDING) {
    return (
      <RecipientAdd
        recipientProp={cloneDeep(newRecipient)}
        addHandler={(recipient: AddRecipientInput) => {
          addRecipient({ variables: { recipient } });
        }}
        closeHandler={() => setState(State.LISTING)}
      />
    );
  }

  return <div></div>;
};

export default RecipientsCMSLayout;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;

const Img = styled.img`
  border: 1px solid #e9e3d2;
  border-radius: 50%;
  height: 30px;
  padding: 0;
  margin: 0 20px 0 0;
  width: 30px;
`;
