import React, { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import noImage from "../static/icons/person.svg";
import { useMutation, gql } from "@apollo/client";

const AvatarUploader: FC<{
  setImageURL: (url: string) => void;
  url: string;
  width?: number;
}> = ({ url: urlProp, width, setImageURL }) => {
  const [url, setURL] = useState<string | undefined>(urlProp);

  const SINGLE_UPLOAD = gql`
    mutation ($type: String!, $name: String!) {
      singleUpload(type: $type, name: $name) {
        fileName
        url
      }
    }
  `;

  useEffect(() => {
    setURL(urlProp);
  }, [urlProp]);

  const setFileURL: (imageURL: string) => void = (imageURL: string) => {
    setImageURL(imageURL);
    setURL(imageURL);
  };

  const [uploadFile, { loading, error }] = useMutation<
    {
      singleUpload: {
        fileName;
        url;
      };
    },
    { type: string; name?: string }
  >(SINGLE_UPLOAD, {
    onError(error) {
      console.log(error);
    }
  });

  const onChange = async ({
    target: {
      validity,
      files: [file]
    }
  }: any) => {
    if (validity.valid) {
      const res = await uploadFile({
        variables: { type: "image", name: file.name }
      });
      // use url as google storage put-signed url to upload file
      try {
        const response = await fetch(res.data?.singleUpload.url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream"
          },
          body: file
        });
        console.log("File upload successful");
        setFileURL(res.data?.singleUpload.fileName);
        return res;
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (event) => {
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <>
      {(!url || url === "") && <Img onClick={handleClick} src={noImage} />}
      {url && url !== "" && <Img onClick={handleClick} src={url} />}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onChange}
        style={{ display: "none" }}
      />
    </>
  );
};

export default AvatarUploader;

const Img = styled.img`
  border: 1px solid #e9e3d2;
  border-radius: 50%;
  height: 30px;
  padding: 0;
  margin: 0 20px 0 0;
  width: 30px;
  cursor: pointer;
`;
