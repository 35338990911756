import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import ShortWorksStageEdit from "./ShortWorksStageEdit";
import { ShortWorksStageInput } from "../../types";
import {
  LangTexts,
  ShortWorksStage,
  siteAuthors,
  SITELANGS
} from "@balthasarspeyr/common";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  WhiteButton,
  Table,
  Row,
  Title,
  Cell,
  H2
} from "../../styles/styledcomponets";
import { LoaderLarge } from "../Loaders";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { theme } from "../../styles/theme";
import { cloneDeep } from "@apollo/client/utilities";
import ShortWorksStageAdd from "./ShortWorksStageAdd";
// import ShortWorksStageAdd from "./ShortWorksStageAdd";

enum State {
  EDITING = "editing",
  ADDING = "adding",
  LISTING = "listing"
}

type ShortWorksStagesQueryData = {
  getShortWorksStages: Array<ShortWorksStageInput & { id: string }>;
};

const SHORTWORKSSTAGE = gql`
  query getShortWorksStages($author: ID) {
    getShortWorksStages(author: $author) {
      id
      order
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      abstract {
       ${Object.values(SITELANGS).join("\n")}
      }
      description {
       ${Object.values(SITELANGS).join("\n")}
      }
    }
  }
`;

const ADD_SHORTWORKSSTAGE = gql`
  mutation addShortWorksStage($shortWorksStage: ShortWorksStageInput!) {
    addShortWorksStage(shortWorksStage: $shortWorksStage) {
      id
      order
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      author {
        id
        name {
          en
        }
        surname {
          en
        }
      }
      abstract {
       ${Object.values(SITELANGS).join("\n")}
      }
      description {
       ${Object.values(SITELANGS).join("\n")}
      }
    }
  }
`;

const DELETE_SHORTWORKSSTAGE = gql`
  mutation deleteShortWorksStage($id: ID!) {
    deleteShortWorksStage(id: $id) {
      ok
    }
  }
`;

const AuthorShortWorksStages: FC<{ author: string }> = ({ author }) => {
  const [addShortWorksStage] = useMutation<
    { addShortWorksStage: ShortWorksStage },
    { shortWorksStage: ShortWorksStageInput }
  >(ADD_SHORTWORKSSTAGE, {
    onCompleted({ addShortWorksStage }) {
      if (addShortWorksStage) {
        refetch();
        console.info("Added!!!");
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const [deleteShortWorksStage] = useMutation<
    { deleteShortWorksStage: { ok: boolean } },
    { id: string }
  >(DELETE_SHORTWORKSSTAGE, {
    onCompleted({ deleteShortWorksStage }) {
      if (deleteShortWorksStage.ok) {
        refetch();
        console.info("Deleted!!!");
      }
    },
    onError(error) {
      console.error(error);
    }
  });

  const [state, setState] = useState<State>(State.LISTING);
  const [shortWorksStage, setShortWorksStage] = useState<
    (ShortWorksStageInput & { id: string }) | undefined
  >(undefined);

  const blankLangs: LangTexts = Object.values(SITELANGS).reduce(
    (acc, lang) => ({ ...acc, [lang]: "" }),
    {}
  );
  const { loading, error, data, refetch } = useQuery<ShortWorksStagesQueryData>(
    SHORTWORKSSTAGE,
    {
      variables: { author },
      fetchPolicy: "no-cache"
    }
  );

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  if (state === State.LISTING) {
    return (
      <div>
        <H2>
          {Object.values(siteAuthors).find((a) => a.id === author)?.name.en}{" "}
          {Object.values(siteAuthors).find((a) => a.id === author)?.surname.en}{" "}
          short works stages
        </H2>
        <Table width="100%">
          <thead>
            <Row>
              <Title width="30%">Name</Title>
              <Title width="30%">Order</Title>
              <Title width="25%">&nbsp;</Title>
            </Row>
          </thead>
          <tbody>
            {data &&
              data.getShortWorksStages.map(
                (shortWorksStageIT: ShortWorksStageInput & { id: string }) => (
                  <Row key={shortWorksStageIT.id}>
                    <Cell>{shortWorksStageIT.name.en}</Cell>
                    <Cell>{shortWorksStageIT.order}</Cell>
                    <Cell>
                      <Actions>
                        <WhiteButton
                          margin="auto 5px auto 5px"
                          onClick={() => {
                            setShortWorksStage({
                              ...shortWorksStageIT,
                              author
                            });
                            setState(State.EDITING);
                          }}
                        >
                          Edit
                        </WhiteButton>
                        <TrashIcon
                          onClick={() => {
                            if (
                              confirm(
                                "Are you sure you want to delete this stage? (you will remove all its works and publications)"
                              )
                            ) {
                              deleteShortWorksStage({
                                variables: { id: shortWorksStageIT.id! }
                              });
                            }
                          }}
                        />
                      </Actions>
                    </Cell>
                  </Row>
                )
              )}
          </tbody>
        </Table>
        <WhiteButton
          onClick={() => {
            setState(State.ADDING);
          }}
        >
          Add new stage
        </WhiteButton>
      </div>
    );
  } else if (state === State.EDITING && shortWorksStage) {
    return (
      <ShortWorksStageEdit
        shortWorksStageProp={shortWorksStage}
        refetchHandler={() => refetch()}
        closeHandler={() => setState(State.LISTING)}
      />
    );
  } else if (state === State.ADDING) {
    return (
      <ShortWorksStageAdd
        addHandler={(shortWorksStage: ShortWorksStageInput) => {
          addShortWorksStage({ variables: { shortWorksStage } });
          refetch();
        }}
        closeHandler={() => setState(State.LISTING)}
        author={author}
      />
    );
  }

  return <div></div>;
};

export default AuthorShortWorksStages;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
