import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../../styles/theme";
import { UserRole } from "../../types";

interface UserAddProps {
  addHandle: (user: {
    name: string;
    surname: string;
    email: string;
    role: [UserRole];
    password: string;
    authorized: boolean;
  }) => void;
}

const UserAdd: FC<UserAddProps> = ({ addHandle }) => {
  const [user, setUser] = useState({
    name: "",
    surname: "",
    email: "",
    role: ["USER"],
    password: "",
  });
  const cleanUser = {
    name: "",
    surname: "",
    email: "",
    role: ["USER"],
    password: "",
  };
  const [pendingChange, setPendingChange] = useState(false);

  const valueChange = (key, event) => {
    const newUser = { ...user };
    newUser[key] = event.target.value;
    setUser(newUser);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setUser({ ...cleanUser });
  };

  const onAdd = () => {
    addHandle({
      name: user.name,
      surname: user.surname,
      email: user.email,
      role: [UserRole.USER],
      password: user.password,
      authorized: true,
    });
    setUser({ ...cleanUser });
  };

  return (
    <div>
      <UserEntry id="adduser">
        <Column
          type="text"
          onChange={(e) => valueChange("name", e)}
          value={user.name}
        />
        <Column
          type="text"
          onChange={(e) => valueChange("surname", e)}
          value={user.surname}
        />
        <Column
          type="text"
          onChange={(e) => valueChange("email", e)}
          value={user.email}
        />
        <Column type="text" value={user.role} />
        <Column
          type="text"
          onChange={(e) => valueChange("password", e)}
          value={user.password}
        />
        <Actions>
          <Add onClick={() => onAdd()}>Add</Add>

          {pendingChange && (
            <Discard
              onClick={() => {
                onDiscard();
              }}
            >
              Discard
            </Discard>
          )}
        </Actions>
      </UserEntry>
    </div>
  );
};

export default UserAdd;

const UserEntry = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  border-bottom: 1px solid ${theme.colors.gray7};
  padding-top: 5px;
  padding-bottom: 5px;
  height: 2rem;
`;

const Column = styled.input`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
  background-color: ${theme.colors.brandWhite};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
`;

const Add = styled.button`
  background-color: ${theme.colors.brandBlack};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;

const Discard = styled.button`
  background-color: ${theme.colors.brandRed};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;
