import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import ShortWorkEdit from "./ShortWorkEdit";
import ShortWorkAdd from "./ShortWorkAdd";
import {
  Author,
  ShortWorksMap,
  siteAuthors,
  SITELANGS,
  Work,
  WORKTYPE
} from "@balthasarspeyr/common";
import { useQuery, useMutation, gql } from "@apollo/client";
import cloneDeep from "clone-deep";
import { ShortWorkInput } from "../../../types";
import { LoaderLarge } from "../../Loaders";
import {
  Input,
  H2,
  H3,
  Table,
  Row,
  Title
} from "../../../styles/styledcomponets";

type ShortWorksData = {
  getShortWorksMap: ShortWorksMap;
};

const SHORTWORKSMAP = gql`
  query getShortWorksMap($author: ID!) {
    getShortWorksMap(author: $author) {
      author {
        id
        name {
          en
        }
        surname {
          en
        }
      }
      shortWorksStages {
        id
        name {
          en
        }
        author {
          id
          name {
            en
          }
          surname {
            en
          }
        }
        articles {
          id
          type
          title {
           ${Object.values(SITELANGS).join("\n")}
          }
          subtitle {
           ${Object.values(SITELANGS).join("\n")}
          }
          baselref
          year
          public
          originalLang
          labels {
            id
          }
        }
        contributions {
          id
          type
          title {
           ${Object.values(SITELANGS).join("\n")}
          }
          subtitle {
           ${Object.values(SITELANGS).join("\n")}
          }
          baselref
          year
          public
          originalLang
          labels {
            id
          }
        }
      }
    }
  }
`;

const ADD_WORK = gql`
  mutation addWork($work: WorkInput!) {
    addWork(work: $work) {
      id
    }
  }
`;

const DELETE_WORK = gql`
  mutation deleteWork($id: ID!) {
    deleteWork(id: $id) {
      ok
    }
  }
`;

const AuthorShortWorks: FC<{ author: Partial<Author> }> = ({ author }) => {
  const Titles = (
    <Row borderBottom="1px solid #aaa">
      <Title width="20%">Title</Title>
      <Title width="20%">Subtitle</Title>
      <Title width="60px">Year</Title>
      <Title width="100px">Language</Title>
      <Title width="70px">Ref.</Title>
      <Title width="50px">Public</Title>
      <Title width="120px">Labels</Title>
      <Title width="*">&nbsp;</Title>
    </Row>
  );

  const [addWork] = useMutation<{ addWork: Work }, { work: ShortWorkInput }>(
    ADD_WORK,
    {
      onCompleted({ addWork }) {
        if (addWork) {
          refetch();
        }
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  const [deleteWork] = useMutation<
    { deleteWork: { ok: boolean } },
    { id: string }
  >(DELETE_WORK, {
    onCompleted({ deleteWork }) {
      if (deleteWork.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const [stageFilter, setStageFilter] = useState("");

  const [visibleStage, setVisibleStage] = useState<string | null>(null);

  const { loading, error, data, refetch } = useQuery<ShortWorksData>(
    SHORTWORKSMAP,
    {
      variables: { author: author.id! },
      fetchPolicy: "no-cache"
    }
  );

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <H2>
        {author.name?.en} {author.surname?.en}
      </H2>
      <Input
        border="1px solid #ccc"
        marginBottom="10px"
        value={stageFilter}
        onChange={(e) => setStageFilter(e.target.value)}
        placeholder="Filter by stage text"
        width="200px"
      />
      <H3>Articles</H3>
      {data &&
        data.getShortWorksMap &&
        data.getShortWorksMap.shortWorksStages
          .filter((stage) =>
            stage.name.en?.toLowerCase().includes(stageFilter.toLowerCase())
          )
          .map((stage) => (
            <React.Fragment key={stage.id}>
              {visibleStage === stage.id && (
                <React.Fragment>
                  <H3 onClick={() => setVisibleStage(null)}>{stage.name.en}</H3>
                  <Table width="100%">
                    <thead>{Titles}</thead>
                    <tbody>
                      {stage.articles.map((article) => (
                        <ShortWorkEdit
                          author={author.id!}
                          refetchHandler={() => refetch()}
                          deleteHandler={() =>
                            deleteWork({
                              variables: { id: article.id! }
                            })
                          }
                          key={article.id!}
                          shortWorkProp={cloneDeep({
                            ...article,
                            labels:
                              article.labels?.map((label) => label.id) || []
                          })}
                        />
                      ))}

                      <ShortWorkAdd
                        addHandler={(work: ShortWorkInput) => {
                          addWork({ variables: { work } });
                        }}
                        author={author.id!}
                        shortWorksStage={stage.id}
                        type={[WORKTYPE.ARTICLE]}
                      />
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
              {visibleStage !== stage.id && (
                <StageItem onClick={() => setVisibleStage(stage.id)}>
                  {stage.name.en}
                  {" (" + stage.articles.length + " articles )"}
                </StageItem>
              )}
            </React.Fragment>
          ))}
      <H3>Contributions</H3>
      {data &&
        data.getShortWorksMap &&
        data.getShortWorksMap.shortWorksStages
          .filter((stage) =>
            stage.name.en?.toLowerCase().includes(stageFilter.toLowerCase())
          )
          .map((stage) => (
            <React.Fragment key={stage.id}>
              {visibleStage === stage.id && (
                <React.Fragment>
                  <H3 onClick={() => setVisibleStage(null)}>{stage.name.en}</H3>
                  <Table width="100%">
                    <thead>{Titles}</thead>
                    <tbody>
                      {stage.contributions.map((contribution) => (
                        <ShortWorkEdit
                          author={author.id!}
                          refetchHandler={() => refetch()}
                          deleteHandler={() =>
                            deleteWork({
                              variables: { id: contribution.id! }
                            })
                          }
                          key={contribution.id!}
                          shortWorkProp={cloneDeep({
                            ...contribution,
                            labels:
                              contribution.labels?.map((label) => label.id) ||
                              []
                          })}
                        />
                      ))}

                      <ShortWorkAdd
                        addHandler={(work: ShortWorkInput) => {
                          addWork({ variables: { work } });
                        }}
                        author={author.id!}
                        shortWorksStage={stage.id}
                        type={[WORKTYPE.CONTRIBUTION]}
                      />
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
              {visibleStage !== stage.id && (
                <StageItem onClick={() => setVisibleStage(stage.id)}>
                  {stage.name.en}
                  {" (" + stage.contributions.length + " contributions )"}
                </StageItem>
              )}
            </React.Fragment>
          ))}
    </div>
  );
};

const ShortWorksCMSLayout: FC = () => {
  return (
    <div>
      <AuthorShortWorks author={siteAuthors.balthasar} />
      <AuthorShortWorks author={siteAuthors.speyr} />
    </div>
  );
};

export default ShortWorksCMSLayout;

const StageItem = styled.div`
  font-size: 0.9rem;
  cursor: pointer;
`;
