import { SITELANGS } from "@balthasarspeyr/common";
import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../../styles/theme";
import { Langs } from "../../types";
import { EditedAuthorInput } from "../../types";
import AvatarUploader from "../AvatarUploader";

interface EditedAuthorAddProps {
  addHandler: (editedAuthor: EditedAuthorInput) => void;
}

const langs: Langs[] = Object.keys(Langs).map((key) => Langs[key]);

const EditedAuthorAdd: FC<EditedAuthorAddProps> = ({ addHandler }) => {
  const [editedAuthor, setEditedAuthor] = useState<EditedAuthorInput>({
    name: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    surname: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    alias: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    image: undefined
  });
  const cleanEditedAuthor: EditedAuthorInput = {
    name: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    surname: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    alias: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    image: undefined
  };
  const [pendingChange, setPendingChange] = useState<boolean>(false);
  const [multilang, setMultilang] = useState<boolean>(false);

  const changeToSingleLang = () => {
    // all languages same value (take english value as default)
    const editedAuthorKeys = ["name", "surname", "alias"];
    editedAuthorKeys.forEach((key) => {
      Object.keys(editedAuthor[key]).forEach(
        (lang) => (editedAuthor[key][lang] = editedAuthor[key]["en"])
      );
    });
    setEditedAuthor(editedAuthor);
    setMultilang(false);
  };

  const valueChange = (value, key, lang?) => {
    const newEditedAuthor: EditedAuthorInput = { ...editedAuthor };

    if (key === "image") {
      newEditedAuthor["image"] = value;
    } else if (multilang) {
      newEditedAuthor[key][lang] = value;
    } else {
      // all languages same value
      Object.keys(newEditedAuthor[key]).forEach(
        (lang) => (newEditedAuthor[key][lang] = value)
      );
    }

    setEditedAuthor(newEditedAuthor);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setEditedAuthor({ ...cleanEditedAuthor });
  };

  const onAdd = () => {
    addHandler(editedAuthor);
    setEditedAuthor({ ...cleanEditedAuthor });
  };

  return (
    <React.Fragment>
      {langs.map(
        (lang) =>
          (multilang || lang === "en") && (
            <Row key={`new${lang}`}>
              {lang === Langs.en && (
                <AvatarUploader
                  setImageURL={(url) => valueChange(url, "image")}
                  url={editedAuthor.image || ""}
                />
              )}
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "name", lang)}
                  value={editedAuthor.name![lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "surname", lang)}
                  value={editedAuthor.surname![lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  placeholder={lang}
                  type="text"
                  onChange={(e) => valueChange(e.target.value, "alias", lang)}
                  value={editedAuthor.alias![lang] || ""}
                />
              </Cell>

              {lang === "en" ? (
                <Cell>
                  <Actions>
                    {multilang ? (
                      <LangsButton onClick={() => changeToSingleLang()}>
                        Set single lang. (en)
                      </LangsButton>
                    ) : (
                      <LangsButton onClick={() => setMultilang(true)}>
                        Set multiple langs.
                      </LangsButton>
                    )}
                    <Add
                      onClick={(e) => {
                        onAdd();
                      }}
                    >
                      Add
                    </Add>

                    {pendingChange ? (
                      <Discard
                        onClick={() => {
                          onDiscard();
                        }}
                      >
                        Discard
                      </Discard>
                    ) : null}
                  </Actions>
                </Cell>
              ) : (
                <Cell>
                  <Actions />
                </Cell>
              )}
            </Row>
          )
      )}
    </React.Fragment>
  );
};

export default EditedAuthorAdd;

const Row = styled.tr``;

const Input = styled.input`
  width: 95%;
  background-color: ${theme.colors.brandWhite};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
`;

const Add = styled.button`
  background-color: ${theme.colors.brandBlack};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;

const LangsButton = styled.button`
  background-color: ${theme.colors.brandWhite};
  color: ${theme.colors.brandLang};
  font-weight: bold;
  border-radius: 8px;
  border: 1px solid black;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Discard = styled.button`
  background-color: ${theme.colors.brandRed};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;

const Cell = styled.td`
  border: 0;
  padding: 0;
`;
