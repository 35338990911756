import { AUTHORROLE } from "@balthasarspeyr/common";
import React, { FC } from "react";
import SelectInputComponent from "./SelectInputComponent";

const AuthorRoleSelect: FC<{
  value?: string;
  margin?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  hideHelper?: boolean;
}> = ({ value, margin, onChange, hideHelper = false }) => {
  return (
    <SelectInputComponent
      margin={margin || "0px 5px 0px 5px"}
      width="auto"
      helper="Author Role"
      hideHelper={hideHelper}
      value={value || AUTHORROLE[0]}
      onChange={onChange}
      Elem={Object.keys(AUTHORROLE).map((key) => {
        return {
          key: key,
          value: AUTHORROLE[key]
        };
      })}
    />
  );
};

export default AuthorRoleSelect;
