import {
  TEXTFORMATS,
  LetterPublicationInputData,
  PUBLICATIONLANGS,
  WorkLetter
} from "@balthasarspeyr/common";
import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import BoxWithTitleContainer from "../../BoxWithTitleContainer";
import {
  InputTextComponent,
  TextAreaComponent
} from "../../InputTextComponent";
import { Input, RedButton, WhiteButton } from "../../../styles/styledcomponets";
import MDEditor from "@uiw/react-md-editor";
import AddTranslators from "../AddTranslators";
import FileUploaderComponent from "../../FileUploader";
import randomstring from "randomstring";
import { useQuery, gql } from "@apollo/client";
import { LoaderLarge } from "../../Loaders";
import { PublicationLanguageSelect } from "../../LanguageSelect";

const GET_PUBLICATION = gql`
  query getPublication($id: ID!) {
    getPublication(id: $id) {
      title
      public
      featured
      list
      pressHouse {
        id
      }
      translators {
        id
      }
      lang
      year
      DOI
      purchaseLinks
      downloadLinks {
        url
        format
      }
      formats
      bibref
      text
    }
  }
`;

const LetterEdit: FC<{
  publication?: LetterPublicationInputData;
  work: WorkLetter;
  publicationID?: string;
  save: (publication: LetterPublicationInputData) => void;
  cancel: () => void;
}> = ({ work, save, cancel, publication, publicationID }) => {
  const [publicationData, setPublicationData] = useState<
    LetterPublicationInputData | undefined
  >(publication);
  const [text, setText] = useState<string>(publication?.text || "");
  const [editing, setEditing] = useState<boolean>(false);
  const { loading, error, data } = useQuery(GET_PUBLICATION, {
    variables: { id: publicationID },
    skip: !publicationID,
    fetchPolicy: "network-only"
  });

  const title = {
    en: "Letter to",
    fr: "Lettre à",
    it: "Lettera a",
    de: "Brief an",
    es: "Carta a",
    jpn: "に手紙"
  };

  useEffect(() => {
    if (data?.getPublication) {
      const publication = {
        ...data.getPublication,
        pressHouse: data.getPublication.pressHouse.id,
        translators: data.getPublication.translators.map((t) => t.id),
        title: `${title[data.getPublication.lang]} ${
          work.letterRecipient?.name?.en
        } ${work.letterRecipient?.surname?.en}`
      };
      setPublicationData(publication);
      setText(publication.text);
    }
  }, [data]);

  const valueChange = (value: string | boolean, key: string) => {
    if (publicationData) {
      if (key === "lang") {
        setPublicationData({
          ...publicationData,
          lang: value as keyof typeof PUBLICATIONLANGS,
          title: `${title[value as string] || title.en} ${
            work.letterRecipient?.name?.en
          } ${work.letterRecipient?.surname?.en}`
        });
      } else if (key !== "year") {
        setPublicationData({
          ...publicationData,
          [key]: value,
          title: `${title[publicationData.lang] || title.en} ${
            work.letterRecipient?.name?.en
          } ${work.letterRecipient?.surname?.en}`
        });
      } else {
        setPublicationData({
          ...publicationData,
          title: `${title[publicationData.lang] || title.en} ${
            work.letterRecipient?.name?.en
          } ${work.letterRecipient?.surname?.en}`,
          year: parseInt(value as string)
        });
      }
    }
  };

  const updateTranslators = (translators: string[]) => {
    publicationData &&
      setPublicationData({
        ...publicationData,
        translators
      });
  };

  if (publicationID && loading) return <LoaderLarge />;

  if (!publicationID || publicationData)
    return (
      <Box>
        <WhiteButton
          onClick={() => {
            save({ ...publicationData!, text });
          }}
        >
          Save
        </WhiteButton>
        <RedButton onClick={cancel}>Cancel</RedButton>
        <BoxWithTitleContainer title="General Data">
          <GeneralData>
            <Div>
              <InputTextComponent
                width="fit-content"
                readOnly={true}
                helper="Author"
                placeholder="Author"
                value={
                  work.authors[0]?.author?.name?.en +
                  " " +
                  work.authors[0]?.author?.surname?.en
                }
              />
              <InputTextComponent
                width="fit-content"
                readOnly={true}
                helper="Recipient"
                placeholder="Recipient"
                value={
                  work.letterRecipient?.name?.en +
                  " " +
                  work.letterRecipient?.surname?.en
                }
              />
              <InputTextComponent
                width="fit-content"
                readOnly={true}
                helper="Date"
                placeholder="Date"
                value={work.letterDate}
              />
            </Div>
            <AddTranslators
              translators={publicationData?.translators || []}
              updateTranslators={updateTranslators}
            />
            <Div>
              <TextAreaComponent
                margin="0 0 20px 0"
                width="250px"
                height="80px"
                helper="Bibliographic Reference"
                placeholder="bibref"
                value={publicationData?.bibref || ""}
                onChange={(value) => {
                  valueChange(value, "bibref");
                }}
              />
              <InputTextComponent
                width="auto"
                helper="Publication year"
                placeholder="year"
                value={String(
                  publicationData?.year || new Date().getFullYear()
                )}
                onChange={(value) => {
                  valueChange(value, "year");
                }}
              />
            </Div>

            <Div>
              <Input
                width="auto"
                height="auto"
                editing={editing}
                onClick={() => setEditing(true)}
                placeholder="Public"
                type="checkbox"
                onChange={(e) => {
                  valueChange(e.target.checked, "public");
                }}
                checked={publicationData?.public || false}
              />
              Visible on Works Section
              <br />
              <Input
                width="auto"
                height="auto"
                editing={editing}
                onClick={() => setEditing(true)}
                placeholder="Public"
                type="checkbox"
                onChange={(e) => {
                  valueChange(e.target.checked, "list");
                }}
                checked={publicationData?.list || false}
              />
              Visible on Publications Section
              <br />
              <Input
                width="auto"
                height="auto"
                onClick={() => setEditing(true)}
                type="checkbox"
                onChange={(e) => {
                  valueChange(e.target.checked, "featured");
                }}
                checked={publicationData?.featured || false}
              />
              Featured
              <br />
              <InputTextComponent
                width="auto"
                helper="DOI"
                placeholder="DOI"
                value={publicationData?.DOI || ""}
                onChange={(value) => {
                  valueChange(value, "DOI");
                }}
              />
              <PublicationLanguageSelect
                value={
                  publicationData?.lang || Object.keys(PUBLICATIONLANGS)[0]
                }
                onChange={(e) => {
                  valueChange(e.target.value, "lang");
                }}
              />
            </Div>
          </GeneralData>
        </BoxWithTitleContainer>
        <BoxWithTitleContainer title="Text">
          <MDEditor
            textareaProps={{
              spellCheck: true,
              autoCorrect: "on",
              autoComplete: "on"
            }}
            height={200}
            value={text || ""}
            onChange={(value) => {
              setText(value as string);
            }}
          ></MDEditor>
        </BoxWithTitleContainer>
        <BoxWithTitleContainer title="Uploads">
          <Uploads>
            {Object.values(TEXTFORMATS).map((format) => {
              return (
                <Div key={format}>
                  <FileUploaderComponent
                    text={"Upload " + format}
                    width="fit-content"
                    height="fit-content"
                    margin="0"
                    padding="0"
                    setFileURL={(url) => {
                      const download = publicationData?.downloadLinks.find(
                        (dw) => dw.format === format
                      );
                      if (download) {
                        download.url = url;
                      } else {
                        publicationData?.downloadLinks.push({
                          format,
                          url
                        });
                      }

                      publicationData &&
                        setPublicationData({
                          ...publicationData
                        });
                    }}
                    type={format}
                    // name={encodeURIComponent(
                    //   `${work.letterRecipient?.surname.en || "letter"}-${
                    //     publicationData?.year || ""
                    //   }-${randomstring.generate(5)}.${format}`.replace(
                    //     /\s/g,
                    //     ""
                    //   )
                    // )}
                  />

                  {publicationData?.downloadLinks?.find(
                    (dwn) => dwn.format === format
                  )?.url &&
                    publicationData.downloadLinks?.find(
                      (dwn) => dwn.format === format
                    )?.url !== "" && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          publicationData.downloadLinks?.find(
                            (dwn) => dwn.format === format
                          )?.url
                        }
                      >
                        {" "}
                        Download{" "}
                      </a>
                    )}
                </Div>
              );
            })}
          </Uploads>
        </BoxWithTitleContainer>
      </Box>
    );

  return <div>This should not happen</div>;
};

export default LetterEdit;

const Uploads = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;
const Div = styled.div`
  //border: 1px solid black;
  width: fit-content;
  margin: 0 20px 0 0; ;
`;

const Box = styled.div`
  background-color: #eeedef;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 92%;
  position: absolute;
  padding: 10px;
  top: 20px;
  height: 90%;
  overflow-y: auto;
  margin-left: -15px;
`;

const GeneralData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
