import {
  ContentsEntry,
  PUBLICATIONTYPE,
  Publication,
  SingleLangContentsEntry,
  WORKTYPE,
  Work
} from "..";

export const publicationType = (
  publication: Partial<Publication> | undefined
): PUBLICATIONTYPE => {
  //if only has 1 contained work and that work is not full, return Extract type
  if (
    publication?.containedWorks?.length === 1 &&
    !publication.containedWorks[0].full
  ) {
    return PUBLICATIONTYPE.EXCERPT;
  }

  const types = (publication?.containedWorks || []).flatMap(
    (work) => work.work.type as unknown as PUBLICATIONTYPE[]
  );
  const uniqueTypes = [...new Set(types)];
  if (uniqueTypes.length === 1) {
    return uniqueTypes[0];
  }
  if (uniqueTypes.includes(PUBLICATIONTYPE.MAINWORK)) {
    return PUBLICATIONTYPE.MAINWORK;
  }
  if (
    [
      PUBLICATIONTYPE.PREFACE,
      PUBLICATIONTYPE.TRANSLATION,
      PUBLICATIONTYPE.ANTHOLOGY
    ].some((type) => uniqueTypes.includes(type))
  ) {
    return PUBLICATIONTYPE.EDITED_WORK;
  }
  if (uniqueTypes.length >= 0) {
    return uniqueTypes[0];
  }

  return PUBLICATIONTYPE.MAINWORK;
};

export const workType = (work: Partial<Work> | undefined): WORKTYPE => {
  const types = work?.type || [];
  const uniqueTypes = [...new Set(types)];
  if (uniqueTypes.length === 1) {
    return uniqueTypes[0];
  }
  if (uniqueTypes.includes(WORKTYPE.MAINWORK)) {
    return WORKTYPE.MAINWORK;
  }
  if (
    [WORKTYPE.PREFACE, WORKTYPE.TRANSLATION, WORKTYPE.ANTHOLOGY].some((type) =>
      uniqueTypes.includes(type)
    )
  ) {
    return WORKTYPE.EDITED_WORK;
  }
  if (uniqueTypes.length >= 0) {
    return uniqueTypes[0];
  }

  return WORKTYPE.MAINWORK;
};

export const contents2JSON = (
  contents: ContentsEntry | SingleLangContentsEntry
): Record<string, unknown> => {
  return {
    id: contents.id,
    text: contents.text,
    sign: contents.sign,
    children: contents.children.map((child) => contents2JSON(child))
  };
};

export const JSON2Contents = (
  json: string
): ContentsEntry | SingleLangContentsEntry => {
  return JSON.parse(json);
};

export const contents2string = (
  contents: ContentsEntry | SingleLangContentsEntry
): string => {
  return JSON.stringify(contents2JSON(contents));
};
