import React, { FC } from "react";
import CMSLayout from "./components/CMSLayout";
import { ApolloClient, DefaultOptions, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { RecoilRoot } from "recoil";

// @ts-ignore
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL
});

console.log(`URI URL: ${process.env.REACT_APP_API_URL}`);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || ""
    }
  };
});

// const defaultOptions: DefaultOptions = {
//   watchQuery: {
//     fetchPolicy: "no-cache",
//     errorPolicy: "ignore",
//   },
//   query: {
//     fetchPolicy: "no-cache",
//     errorPolicy: "all",
//   },
// };

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false })
  //  defaultOptions: defaultOptions,
});

const App: FC = () => {
  return (
    <ApolloProvider client={client}>
      <RecoilRoot>
        <CMSLayout />
      </RecoilRoot>
    </ApolloProvider>
  );
};

export default App;
