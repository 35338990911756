import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { Cell, Input, Row } from "../../styles/styledcomponets";
import { theme } from "../../styles/theme";
import { IAddPressHouseInput } from "../../types";

interface PressHouseAddProps {
  addHandler: (author: IAddPressHouseInput) => void;
}

const PressHouseAdd: FC<PressHouseAddProps> = ({ addHandler }) => {
  const [pressHouse, setPressHouse] = useState<IAddPressHouseInput>({
    name: "",
    country: [""],
    website: ""
  });

  const cleanPressHouse: IAddPressHouseInput = {
    name: "",
    country: [""],
    website: ""
  };
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const valueChange = (value, key) => {
    const newPressHouse: IAddPressHouseInput = { ...pressHouse };
    // category field has no lang
    newPressHouse[key] = value;
    setPressHouse(newPressHouse);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setPressHouse({ ...cleanPressHouse });
  };

  const onAdd = () => {
    addHandler(pressHouse);
    setPressHouse({ ...cleanPressHouse });
  };

  return (
    <Row>
      <Cell>&nbsp;</Cell>
      <Cell>
        <Input
          width="95%"
          placeholder="name"
          type="text"
          onChange={(e) => valueChange(e.target.value, "name")}
          value={pressHouse.name || ""}
        />
      </Cell>
      <Cell>
        <Input
          width="95%"
          placeholder="website"
          type="text"
          onChange={(e) => valueChange(e.target.value, "website")}
          value={pressHouse.website || ""}
        />
      </Cell>
      <Cell>
        <Input
          width="95%"
          placeholder="Country"
          type="text"
          onChange={(e) => valueChange([e.target.value], "country")}
          value={pressHouse.country?.[0] || ""}
        />
      </Cell>
      <Cell>
        <Actions>
          <Add
            onClick={(e) => {
              onAdd();
            }}
          >
            Add
          </Add>

          {pendingChange ? (
            <Discard
              onClick={() => {
                onDiscard();
              }}
            >
              Discard
            </Discard>
          ) : null}
        </Actions>
      </Cell>
    </Row>
  );
};

export default PressHouseAdd;

const Actions = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
`;

const Add = styled.button`
  background-color: ${theme.colors.brandBlack};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;
const Discard = styled.button`
  background-color: ${theme.colors.brandRed};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;
