import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FC, useEffect, useRef } from "react";
import { adjustColorOpacity, colors, fonts } from "../theme";
import Icon from "./Icon";

export interface ITableRowProps {
  className?: string;
  columns: [JSX.Element]|[JSX.Element, JSX.Element];
  dragging?: boolean;
  hideLevel?: boolean;
  level?: number;
  onContainerClientRect?: (rect: DOMRect) => void;
  position?: number;
  wrap?: boolean;
  noBorderRow?: boolean;
}

const TableRow: FC<ITableRowProps> = ({ columns, dragging, hideLevel = false, level = 0, onContainerClientRect, position, noBorderRow, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (position !== undefined && dragging === undefined) console.warn("Dragging not set");
    if (!onContainerClientRect) return;
  
    const getClientRect = () => ref.current && onContainerClientRect?.(ref.current.getBoundingClientRect());

    getClientRect();
    window.addEventListener("resize", getClientRect);
    return () => {
      window.removeEventListener("resize", getClientRect);
    };
  }, []);

  return (
    <Row dragging={dragging} hideLevel={hideLevel} level={level} twoColumns={columns.length > 1} position={position} ref={ref} noBorderRow={noBorderRow} {...props}>
      {Array.from({ length: level }).map((_l, i) => (
        <LevelIcon hidden={hideLevel} last={i === level - 1} name="return" key={i} />
      ))}
      {columns.map((column, index) => (
        <div key={index}>
          {column}
        </div>
      ))}
    </Row>
  );
}
export default TableRow;

const LevelIcon = styled(Icon)<{ hidden: boolean; last: boolean }>`
  color: ${colors.gold[1]};
  height: 20px;
  margin-right: 5px;
  margin-top: 3px;
  min-height: 20px;
  min-width: ${props => props.last ? 20 : 10}px;
  padding-right: 0;
  visibility: ${props => (!props.last || props.hidden) ? "hidden" : "visible"};
  width: ${props => props.last ? 20 : 10}px;
`;

const Row = styled.div<{ dragging?: boolean, hideLevel: boolean, level: number, twoColumns: boolean, position?: number; wrap?: boolean; noBorderRow?: boolean; }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${props => props.twoColumns ? "row" : "column"};
  justify-content: center;
  min-height: 40px;
  padding: ${props => props.twoColumns ? 0 : "5px 20px"};
  position: relative;

  > div {
    align-items: center;
    display: flex;
    position: relative;

    ${props => props.wrap ? css`
      * {
        text-align: left;
        white-space: normal;
      }
    ` : css`
      *:not(b):not(div):not(text) {
        overflow: hidden;
        padding-right: 2px;
        text-overflow: ellipsis;
        white-space: nowrap;

        > p {
          display: contents;
        }
      }
    `}

    b {
      ${fonts.size.small}
      line-height: unset;
      color: ${adjustColorOpacity(colors.blue[4], 0.5)};
      margin: 2px 5px -2px;
    }

    text {
      text-align: left;
      width: calc(100% - 30px);
      ${props => props.hideLevel && "margin: 0 15px;"}

      * {
        margin-right: 0!important;
        padding-right: 0!important;
        white-space: unset!important;
      }
    }

    ${props => props.twoColumns && css`
      box-sizing: border-box;
      padding: 0 20px;

      ${props.dragging && css`
        user-select: none;
      `}

      :first-of-type {
        ${props.noBorderRow ? null : css`border-right: solid 1px ${colors.gold[props.dragging ? 0 : 3]};`}
        width: calc(${(props.position || 0.5) * 100}% - 1px);
      }

      :last-of-type {
        width: ${(1 - (props.position || 0.5)) * 100}%;
      }
    `}
  }

  :not(:last-of-type) {
    border-bottom: solid 1px ${colors.gold[3]};
  }

  ${props => props.level > 0 && css`
    align-items: flex-start;
    flex-flow: row;
    width: unset!important;

    > div {
      align-self: center;
      flex: 1;
      padding-left: 0;

      ${!props.wrap && css`
        overflow: hidden;
      `}

      span {
        margin-right: 5px;
      }
    }
  `}
`;
