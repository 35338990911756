import { Icon } from "@balthasarspeyr/ui";
import styled from "@emotion/styled";
import React, { FC, forwardRef } from "react";

const Link: FC<{ children: React.ReactNode }> = forwardRef(
  ({ children, ...props }, _ref) => (
    <Container {...props}>
      <Children>{children}</Children>
      <Icon name="arrow" />
    </Container>
  )
);

Link.displayName = "Link";

export default Link;

const Children = styled.div`
  overflow: hidden;
  padding-right: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  svg {
    min-width: 20px;
  }
`;
