import styled from "@emotion/styled";
import React, { ComponentProps, FC, forwardRef, HTMLAttributes } from "react";
import { adjustColorOpacity, colors, fonts } from "../theme";
import TooltipError from "./TooltipError";

export interface IInputProps {
  error?: string;
  onCloseError?: () => void;
}

const Input: FC<
  IInputProps &
    Omit<ComponentProps<"input">, "ref"> &
    HTMLAttributes<HTMLInputElement>
> = forwardRef((props, _ref) => {
  const { error, onCloseError, ...rest } = props;

  return (
    <TooltipError error={error} onClose={onCloseError}>
      <StyledInput
        error={!!error}
        {...rest}
      />
    </TooltipError>
  );
});

Input.displayName = "Input";

export default Input;

const StyledInput = styled.input<{ error?: boolean }>`
  ${fonts.family.theinhardt}
  ${fonts.size.smallExtra}
  background-color: ${(props) => (props.error ? adjustColorOpacity(colors.red[0], 0.2) : "white")};;
  border: solid 1px ${(props) => (props.error ? colors.red[0] : colors.gold[2])};
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: ${(props) => (props.error ? colors.red[0] : colors.black)};
  height: 40px;
  padding: 10px 20px;
  width: 100%;

  &::placeholder {
    color: ${(props) => (props.error ? colors.red[0] : adjustColorOpacity(colors.black, 0.6))};
    font-style: italic;
  }

  :focus {
    border-color: ${(props) => (props.error ? colors.red : colors.black)};
    outline: none;
  }

  :disabled {
    background-color: ${colors.grey[2]};
    border-color: ${colors.grey[1]};
    pointer-events: none;
  }
`;
