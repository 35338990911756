import styled from "@emotion/styled";
import { theme } from "./theme";

export const Actions = styled.div`
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;
export const TitlesHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  border-bottom: 1px solid ${theme.colors.brandBlack};
  margin-bottom: 10px;
`;

export const Rowx = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  border-bottom: 0px;
  margin-bottom: 10px;
`;

export const Column = styled.div<{ width: string }>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${(props) => props.width};
  width: ${(props) => props.width};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

export const Titlex = styled.div<{ width: string }>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${(props) => props.width};
  font-weight: bold;
`;

export const WhiteButton = styled.button<{ margin?: string }>`
  background-color: #fff;
  color: ${theme.colors.brandBlack};
  font-weight: bold;
  font-size: 0.7rem;
  border-radius: 8px;
  border: 1px solid black;
  outline: none;
  height: 23px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  flex-basis: auto;
  cursor: pointer;
  margin: ${(props) => props.margin || "0 5px 0 5px"};
`;

export const RedButton = styled.button<{ margin?: string }>`
  background-color: ${theme.colors.brandRed};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  font-size: 0.7rem;
  border-radius: 8px;
  border: 1px solid black;
  outline: none;
  height: 25px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  flex-basis: auto;
  cursor: pointer;
  margin: ${(props) => props.margin || "0 5px 0 5px"};
`;

export const BlackButton = styled.button<{ margin?: string }>`
  background-color: ${theme.colors.brandBlack};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  font-size: 0.7rem;
  border-radius: 8px;
  border: 1px solid black;
  outline: none;
  height: 25px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  margin: ${(props) => props.margin || "0 5px 0 5px"};
`;

export const Input = styled.input<{
  width?: string;
  height?: string;
  border?: string;
  marginTop?: string;
  marginBottom?: string;
  editing?: boolean;
}>`
  background-color: ${(props) =>
    props.readOnly
      ? "inherit"
      : props.editing
      ? theme.colors.brandWhite
      : "inherit"};
  border: ${(props) => (props.readOnly ? "0px" : props.border || "0px")};
  height: 25px;
  font-size: 0.8rem;
  margin-top: ${(props) => props.marginTop || "auto"};
  margin-bottom: ${(props) => props.marginBottom || "auto"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "25px"};
  outline: ${(props) => (props.readOnly ? "none" : props.border || "0")};
  border-radius: 1px;
`;

Input.defaultProps = {
  editing: true,
};

export const SelectInput = styled.select<{
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
}>`
  background-color: ${theme.colors.brandWhite};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "25px"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  border: 0px;
  font-size: 0.8rem;
`;
export const Table = styled.table<{
  marginBottom?: string;
  borderBottom?: string;
}>`
  border: 0;
  border-collapse: collapse;
  margin-bottom: ${(props) => props.marginBottom || "10px"};
  border-bottom: ${(props) =>
    props.borderBottom || "1px solid ${theme.colors.brandBlack"};
`;

export const TextArea = styled.textarea<{
  width?: string;
  height?: string;
  border?: string;
  margin: string;
  readOnly?: boolean;
  padding?: string;
}>`
  background-color: ${theme.colors.brandWhite};
  border: ${(props) => (props.readOnly ? "0px" : props.border || "0px")};
  font-size: 0.8rem;
  margin: ${(props) => props.margin || "auto"};
  width: ${(props) => props.width || "500px"};
  height: ${(props) => props.height || "25px"};
  outline: ${(props) => (props.readOnly ? "none" : "1px grey solid")};
  border-radius: 1px;
  padding: ${(props) => props.padding || "0"};
`;

export const Row = styled.tr<{ height?: string; borderBottom?: string }>`
  border-bottom: ${(props) => props.borderBottom || "none"};
  height: ${(props) => props.height || "auto"};
  vertical-align: top;
`;

export const Title = styled.th<{ width?: string; textAlign?: string }>`
  font-size: 0.9rem;
  font-weight: bold;
  width: ${(props) => props.width || "auto"};
  text-align: ${(props) => props.textAlign || "left"};
`;

export const Cell = styled.td<{ textAlign?: string; padding?: string }>`
  border: 0;
  padding: ${(props) => props.padding || "0"};
  text-align: ${(props) => props.textAlign || "left"};
  vertical-align: top;
`;

export const H3 = styled.h3`
  cursor: pointer;
`;

export const H2 = styled.h2`
  margin-bottom: 5px;
`;

export const Helper = styled.div`
  font-size: 0.7em;
  margin: "2px 2px 1px 0px";
  color: ${theme.colors.brandBlack};
`;
