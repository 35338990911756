import styled from "@emotion/styled";
import { colors } from "../theme";

const Table = styled.div`
  border: solid 1px ${colors.gold[3]};
  border-radius: 3px;
  margin-top: 30px;
  position: relative;
  width: 100%;
  z-index: unset;
`;

export default Table;
