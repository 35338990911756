import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import ContentsEntryComponent from "./ContentsEntry";
import { cloneDeep } from "@apollo/client/utilities";
import { ContentsEntry } from "@balthasarspeyr/common";

const findAndUpdate = (
  contents: ContentsEntry,
  entry: ContentsEntry
): ContentsEntry => {
  // if it is among the children, return the updated object.
  if (contents.children.find((cont) => cont.id === entry.id)) {
    return {
      ...contents,
      children: contents.children.map((cont) => {
        if (cont.id !== entry.id) return cont;
        else return entry;
      })
    };
    // if not, look on children (of children)
  } else {
    return {
      ...contents,
      children: contents.children.map((child) => findAndUpdate(child, entry))
    };
  }
};

const ContentsComponent: FC<{
  data: ContentsEntry;
  updateLang: (data: ContentsEntry) => void;
  language: string;
}> = ({ data, language, updateLang }) => {
  const update = (entry: ContentsEntry) => {
    const newContents = cloneDeep(data).children.map((c) =>
      findAndUpdate(c, entry)
    );
    updateLang({
      ...data,
      children: newContents
    });
  };

  const setParents = (entry: ContentsEntry) => {
    entry.children.forEach((child) => {
      child.parent = entry;
      setParents(child);
    });
  };

  data.children.forEach((entry) => {
    entry.parent = data;
    setParents(entry);
  });

  const right = (previous: ContentsEntry, toMove: ContentsEntry) => {
    previous.children.push(cloneDeep(toMove));
    const index = data.children.indexOf(toMove);
    data.children.splice(index, 1);
    updateLang({
      ...data,
      children: [...data.children]
    });
  };

  const up = (parent: ContentsEntry, toMove: ContentsEntry) => {
    const index = parent.children.indexOf(toMove);
    const aux = parent.children[index - 1];
    parent.children[index - 1] = toMove;
    parent.children[index] = aux;
    updateLang({
      ...data,
      children: [...parent.children]
    });
  };

  const down = (parent: ContentsEntry, toMove: ContentsEntry) => {
    const index = parent.children.indexOf(toMove);
    const aux = parent.children[index + 1];
    parent.children[index + 1] = toMove;
    parent.children[index] = aux;
    updateLang({
      ...data,
      children: [...parent.children]
    });
  };

  const remove = (parent: ContentsEntry, toMove: ContentsEntry) => {
    const index = parent.children.indexOf(toMove);
    parent.children.splice(index, 1);
    updateLang({
      ...data,
      children: [...parent.children]
    });
  };

  return (
    <Contents>
      {data.children.map((child: ContentsEntry, index: number) => {
        if (data.children.length === 1)
          return (
            <ContentsEntryComponent
              notifyParent={(e) => {
                update(e);
              }}
              language={language}
              entry={child}
              key={child.id}
              last={true}
            />
          );
        else if (index === 0)
          return (
            <ContentsEntryComponent
              notifyParent={(e) => {
                update(e);
              }}
              entry={child}
              key={child.id}
              moveDown={() => down(data, child)}
              language={language}
            />
          );
        else if (index > 0 && index < data.children.length - 1)
          return (
            <ContentsEntryComponent
              notifyParent={(e) => {
                update(e);
              }}
              entry={child}
              key={child.id}
              moveRight={() => right(data.children[index - 1], child)}
              moveDown={() => down(data, child)}
              moveUp={() => up(data, child)}
              removeEntry={() => remove(data, child)}
              language={language}
            />
          );
        else
          return (
            <ContentsEntryComponent
              notifyParent={(e) => {
                update(e);
              }}
              entry={child}
              key={child.id}
              moveRight={() => right(data.children[index - 1], child)}
              moveUp={() => up(data, child)}
              last={true}
              removeEntry={() => remove(data, child)}
              language={language}
            />
          );
      })}
    </Contents>
  );
};

export default ContentsComponent;

const Contents = styled.div`
  font-size: 0.8rem;
  select,
  option {
    font-size: 0.8rem;
  }
`;
