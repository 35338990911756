import styled from "@emotion/styled";
import React, { FC } from "react";
import PressHouseEdit from "./PressHouseEdit";
import PressHouseAdd from "./PressHouseAdd";
import { useQuery, useMutation, gql } from "@apollo/client";
import cloneDeep from "clone-deep";
import {
  Titlex,
  TitlesHeader,
  Table,
  Row,
  Title
} from "../../styles/styledcomponets";
import { IAddPressHouseInput } from "../../types";
import { LoaderLarge } from "../Loaders";
import { PressHouse, PressHouseList } from "@balthasarspeyr/common";

interface IPresHouseData {
  getPressHouses: PressHouse[];
}

const PRESSHOUSES = gql`
  query getPressHouses {
    getPressHouses {
      data {
        id
        name
        website
        country
        publications {
          id
        }
      }
    }
  }
`;

const ADD_PRESSHOUSE = gql`
  mutation addPressHouse($pressHouse: PressHouseInput!) {
    addPressHouse(pressHouse: $pressHouse) {
      id
      name
      website
      country
    }
  }
`;

const DELETE_PRESSHOUSE = gql`
  mutation deletePressHouse($id: ID!) {
    deletePressHouse(id: $id) {
      ok
    }
  }
`;

const PressHouseCMSLayout: FC = () => {
  const [addPressHouse] = useMutation<
    { addPressHouse: PressHouse },
    { pressHouse: IAddPressHouseInput }
  >(ADD_PRESSHOUSE, {
    onCompleted({ addPressHouse }) {
      if (addPressHouse) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const [deletePressHouse] = useMutation<
    { deletePressHouse: { ok: boolean } },
    { id: string }
  >(DELETE_PRESSHOUSE, {
    onCompleted({ deletePressHouse }) {
      if (deletePressHouse.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const { loading, error, data, refetch } = useQuery<{
    getPressHouses: PressHouseList;
  }>(PRESSHOUSES);

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <Table width="100%">
        <thead>
          <Title>Num. of Pubs.</Title>
          <Title width="25%">Name</Title>
          <Title width="25%">Website</Title>
          <Title width="20%">Country</Title>
          <Title width="20%">&nbsp;</Title>
        </thead>
        <tbody>
          {data &&
            data.getPressHouses.data.map((pressHouse) => (
              <React.Fragment key={pressHouse.id}>
                <PressHouseEdit
                  refetchHandler={() => refetch()}
                  deleteHandler={() =>
                    deletePressHouse({ variables: { id: pressHouse.id! } })
                  }
                  key={pressHouse.id!}
                  pressHouseProp={cloneDeep(pressHouse)}
                />
              </React.Fragment>
            ))}
          <PressHouseAdd
            addHandler={(pressHouse: IAddPressHouseInput) => {
              addPressHouse({ variables: { pressHouse } });
            }}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default PressHouseCMSLayout;
