import React, { FC } from "react";
import styled from "@emotion/styled";
import { theme } from "../styles/theme";
import { Helper, SelectInput } from "../styles/styledcomponets";

interface IInputTextProps {
  helper?: string;
  defaultValue?: string;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  Elem: Array<{ key: string; value: string }>;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  hideHelper?: boolean;
}

const SelectInputComponent: FC<IInputTextProps> = ({
  onChange,
  helper,
  defaultValue,
  width,
  height,
  padding,
  margin,
  Elem,
  value,
  hideHelper = false
}) => {
  return (
    <Div {...{ margin, padding, width, height }}>
      {!hideHelper && helper && (
        <Helper {...{ margin, padding, width, height }}>{helper}</Helper>
      )}
      <SelectInput
        width={width}
        defaultValue={defaultValue || Elem[0].key}
        onChange={onChange}
        value={value}
      >
        {Elem.map((elem) => (
          <option key={elem.key} value={elem.key}>
            {elem.value}
          </option>
        ))}
      </SelectInput>
    </Div>
  );
};

export default SelectInputComponent;

const Div = styled.div<{
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
}>`
  width: "fit-content";
  height: ${(props) => props.height || "fit-content"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
`;
