import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../../styles/theme";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { IUser } from "../../types";

import { gql, useMutation } from "@apollo/client";

const UPDATE_USER = gql`
  mutation updateUser($email: String!, $user: UpdateUserInput!) {
    updateUser(email: $email, user: $user) {
      id
      email
      name
      surname
      role
    }
  }
`;

interface UserEditProps {
  userProp: IUser;
  deleteHandle: () => void;
}

interface IUpdateUserInput {
  name?: string;
  surname?: string;
  email?: string;
  role?: string[];
  authorized?: boolean;
  password?: string;
}

const UserEdit: FC<UserEditProps> = ({ userProp, deleteHandle }) => {
  const [editing, setEditing] = useState(false);
  const [user, setUser] = useState({ ...userProp, password: "hidden" });
  const [backUser, setBackUser] = useState({ ...userProp, password: "hidden" });
  const [pendingChange, setPendingChange] = useState(false);

  const [updateUser] = useMutation<
    { updateUser: IUser },
    { email: string; user: IUpdateUserInput }
  >(UPDATE_USER, {
    onCompleted({ updateUser }) {
      if (updateUser) {
        setEditing(false);
        setPendingChange(false);
        setBackUser({ ...user });
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const valueChange = (key, event) => {
    const newUser = { ...user };
    newUser[key] = event.target.value;
    setUser(newUser);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setEditing(false);
    setPendingChange(false);
    setUser(backUser);
  };

  const onSave = () => {
    const userToSave = {
      name: user.name,
      surname: user.surname,
      email: user.email,
    };

    //update password only if it has been changed
    if (user.password !== "hidden") userToSave["password"] = user.password;

    updateUser({
      variables: {
        email: user.email,
        user: userToSave,
      },
    });
  };

  return (
    <div>
      <UserEntry>
        <Column
          type="text"
          editing={editing}
          onClick={() => setEditing(true)}
          onChange={(e) => valueChange("name", e)}
          value={user.name}
        />
        <Column
          type="text"
          editing={editing}
          onClick={() => setEditing(true)}
          onChange={(e) => valueChange("surname", e)}
          value={user.surname}
        />
        <Column
          type="text"
          editing={editing}
          onClick={() => setEditing(true)}
          onChange={(e) => valueChange("email", e)}
          value={user.email}
        />
        <Column
          editing={editing}
          onClick={() => setEditing(true)}
          //onChange={(e) => valueChange("role", e)}
          value={user.role}
        />
        <Password
          editing={editing}
          onClick={() => setEditing(true)}
          onChange={(e) => valueChange("password", e)}
          value={user.password}
        />
        <Actions editing={editing}>
          {pendingChange ? (
            <Save
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Save>
          ) : null}
          {pendingChange ? (
            <Discard
              onClick={() => {
                onDiscard();
              }}
            >
              Discard
            </Discard>
          ) : null}
          <TrashIcon onClick={() => deleteHandle()} />
        </Actions>
      </UserEntry>
    </div>
  );
};

export default UserEdit;

const UserEntry = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  border-bottom: 1px solid ${theme.colors.gray7};
  padding-top: 5px;
  padding-bottom: 5px;
  height: 2rem;
`;

interface IColumnProps {
  editing: boolean;
}
const Column = styled.input<IColumnProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
  background-color: ${(props) =>
    props.editing ? theme.colors.brandWhite : theme.colors.gray6};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

const Password = styled.input<IColumnProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
  font-style: ${(props) => (props.editing ? "normal" : "italic")};
  background-color: ${(props) =>
    props.editing ? theme.colors.brandWhite : theme.colors.gray6};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

interface IActionsProps {
  editing: boolean;
}
const Actions = styled.div<IActionsProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;

const Save = styled.button`
  background-color: ${theme.colors.brandBlack};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;

const Discard = styled.button`
  background-color: ${theme.colors.brandRed};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;
