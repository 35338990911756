import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import EditedWorkElem from "./EditedWorkElem";
import { LoaderLarge } from "../../Loaders";
import arraySort from "array-sort";
import { cloneDeep } from "@apollo/client/utilities";

import {
  SITELANGS,
  WorkEditedWork,
  WorksList,
  WORKTYPE
} from "@balthasarspeyr/common";
import { InputTextComponent } from "../../InputTextComponent";
import { Row, Table, Title } from "../../../styles/styledcomponets";

enum States {
  LISTING = 0,
  ADDING = 1,
  EDITING = 2
}

interface IWorksData {
  getWorks: Omit<WorksList, "data"> & {
    data: WorkEditedWork[];
  };
}

const WORKS = gql`
  query getWorks($filter: WorksFilter) {
    getWorks(filter: $filter) {
      data {
        originalLang
        baselref
        id
        title {
         ${Object.values(SITELANGS).join("\n")}
        }
        subtitle {
         ${Object.values(SITELANGS).join("\n")}
        }
        caption {
         ${Object.values(SITELANGS).join("\n")}
        }
        type
        year
        editedAuthor {
          id
          name {
           ${Object.values(SITELANGS).join("\n")}
          }
          surname {
           ${Object.values(SITELANGS).join("\n")}
          }
        }
        authors {
          author {
            name {
             ${Object.values(SITELANGS).join("\n")}
            }
            surname {
             ${Object.values(SITELANGS).join("\n")}
            }
          }
        }
      }
    }
  }
`;

const DELETE_WORK = gql`
  mutation deleteWork($id: ID!) {
    deleteWork(id: $id) {
      ok
    }
  }
`;

const EditedWorksListComponent: FC<{
  workIDHandler: (id: string, state: States) => void;
}> = ({ workIDHandler }) => {
  const [deleteWork] = useMutation<
    { deleteWork: { ok: boolean } },
    { id: string }
  >(DELETE_WORK, {
    onCompleted({ deleteWork }) {
      if (deleteWork.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const deleteWorkHandler = (id: string): void => {
    deleteWork({
      variables: {
        id
      }
    });
  };

  // useEffect(() => {
  //   refetch();
  // }, []);

  const [filter, setFilter] = useState<string>("");
  const [sort, setSort] = useState<{ key: string; reverse: boolean }>({
    key: "year",
    reverse: false
  });
  const { loading, error, data, refetch } = useQuery<IWorksData>(WORKS, {
    variables: {
      filter: {
        types: [
          WORKTYPE.EPILOGUE,
          WORKTYPE.PREFACE,
          WORKTYPE.ANTHOLOGY,
          WORKTYPE.TRANSLATION
        ]
      }
    },
    fetchPolicy: "network-only"
  });
  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  const filteredWorks = data?.getWorks.data.filter((work: WorkEditedWork) => {
    if (filter === "") return true;
    try {
      return (
        work.authors.some((author) => {
          return Object.keys(author.author.name).some(
            (lang) =>
              author.author?.alias?.[lang]
                ?.toLowerCase()
                .includes(filter.toLowerCase().trim()) ||
              `${author.author?.name?.[lang]} ${author.author?.surname?.[lang]}`
                ?.toLowerCase()
                .includes(filter.toLowerCase().trim())
          );
        }) ||
        (work.editedAuthor &&
          Object.keys(work.editedAuthor.name).some((lang) =>
            work.editedAuthor.name[lang]
              ?.toLowerCase()
              .includes(filter.toLowerCase().trim())
          )) ||
        (work.editedAuthor?.surname &&
          Object.keys(work.editedAuthor.surname).some((lang) =>
            work.editedAuthor.surname?.[lang]
              ?.toLowerCase()
              .includes(filter.toLowerCase().trim())
          )) ||
        (work.title &&
          Object.keys(work.title).some((lang) =>
            work.title?.[lang]
              ?.toLowerCase()
              .includes(filter.toLowerCase().trim())
          )) ||
        (work.subtitle &&
          Object.keys(work.subtitle).some((lang) =>
            work.subtitle?.[lang]
              ?.toLowerCase()
              .includes(filter.toLowerCase().trim())
          )) ||
        (work.caption &&
          Object.keys(work.caption).some((lang) =>
            work.caption?.[lang]
              ?.toLowerCase()
              .includes(filter.toLowerCase().trim())
          )) ||
        work.year?.toString().includes(filter.trim()) ||
        work.baselref?.includes(filter.trim())
      );
    } catch (e) {
      return true;
    }
  });
  const works = arraySort(cloneDeep(filteredWorks), sort.key, {
    reverse: sort.reverse
  });

  return (
    <div>
      <InputTextComponent
        placeholder="search"
        helper="Filter by title, subtitle, caption, author, Basel ref. or year"
        onChange={(value) => setFilter(value)}
        value={filter}
        margin="20px 0px 20px 0px"
        width="300px"
      />
      <Table>
        <thead>
          <Row borderBottom="1px solid #aaa">
            <Title
              width="250px"
              onClick={() =>
                setSort({ key: "name.en", reverse: !sort.reverse })
              }
            >
              Edited Author
            </Title>
            <Title
              width="500px"
              onClick={() =>
                setSort({ key: "title.en", reverse: !sort.reverse })
              }
            >
              Title
            </Title>
            <Title
              width="250px"
              onClick={() =>
                setSort({ key: "authors[0].name.en", reverse: !sort.reverse })
              }
            >
              Editor
            </Title>
            <Title
              width="50px"
              onClick={() => setSort({ key: "year", reverse: !sort.reverse })}
            >
              Year
            </Title>
            <Title width="19%" />
          </Row>
        </thead>
        <tbody>
          {works.map((work) => (
            <EditedWorkElem
              key={work.id}
              work={work}
              deleteHandler={deleteWorkHandler}
              workIDHandler={workIDHandler}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default EditedWorksListComponent;
