import React, { FC, useState } from "react";

import { siteAuthors } from "@balthasarspeyr/common";
import BooksList from "./BooksList";
import BookEdit from "./BookEdit";
import { WhiteButton } from "../../../styles/styledcomponets";

enum STATUS {
  LISTING,
  EDITING,
  ADDING
}

const BookCMSLayout: FC = () => {
  const [status, setStatus] = useState(STATUS.LISTING);
  const [publication, setPublication] = useState<string | undefined>(undefined);

  return (
    <div>
      {status === STATUS.EDITING && publication && (
        <BookEdit
          setStatus={setStatus}
          action="edit"
          cancel={() => setStatus(STATUS.LISTING)}
          publicationID={publication}
        />
      )}
      {status === STATUS.ADDING && (
        <BookEdit
          action="add"
          setStatus={setStatus}
          cancel={() => setStatus(STATUS.LISTING)}
        />
      )}
      {status === STATUS.LISTING && (
        <>
          <WhiteButton onClick={() => setStatus(STATUS.ADDING)}>
            Add new publication
          </WhiteButton>
          <BooksList
            setStatus={setStatus}
            setPublication={setPublication}
            authors={[siteAuthors.balthasar]}
          />
          <BooksList
            setStatus={setStatus}
            setPublication={setPublication}
            authors={[siteAuthors.speyr]}
          />
        </>
      )}
    </div>
  );
};

export default BookCMSLayout;
