import styled from "@emotion/styled";
import React, { FC, useRef } from "react";
import { createPortal } from "react-dom";
import Icon from "./Icon";
import ScrollButton from "./ScrollButton";
import { adjustColorOpacity, animations, breakpoints, colors, transition, zIndexes } from "../theme";

export interface IModalProps {
  className?: string;
  header?: string | JSX.Element;
  onClose?: () => void;
  scroll?: boolean;
  show?: boolean;
  showClose?: boolean;
}

const Modal: FC<IModalProps> = ({ className, header, onClose, scroll, show = true, showClose, children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (typeof window === 'undefined' || !show) ? null :
    createPortal(
      <Container onMouseDown={onClose}>
        <Background />
        <Content className={className} onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}>
          <Header>
            <h3>{header}</h3>
            {showClose && onClose && (
              <CloseButton onClick={onClose}>
                <Icon name="close" />
              </CloseButton>
            )}
          </Header>
          <Children className={scroll ? "scrollbar" : undefined} ref={scrollRef} scroll={scroll}>{children}</Children>
          {scroll && (
            <ModalScrollButton
              onClick={() =>
                scrollRef.current?.scrollTo({
                  top: 0,
                  behavior: "smooth"
                })
              }
            />
          )}
        </Content>
      </Container>
      , document.body
    )
};

export default Modal;

const Background = styled.div`
  background: ${adjustColorOpacity(colors.white, 0.5)};
  backdrop-filter: blur(6px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const Children = styled.div<{ scroll?: boolean }>`
  background-color: ${colors.white};
  padding: 30px 30px 40px;

  ${props => props.scroll && "overflow: scroll;"}

  @media screen and (min-width: ${breakpoints.tablet}px) {
    padding: 30px 60px 40px;
  }
`;

const CloseButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

const Container = styled.div`
  animation: ${animations.fadeIn} ${transition}s ease-out forwards;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: fixed;
  overflow: auto;
  top: 0;
  width: 100vw;
  z-index: ${zIndexes.modal};
`;

const Content = styled.div`
  border-radius: 3px;
  box-shadow: 0 20px 40px 0 ${adjustColorOpacity(colors.black, 0.2)};
  position: relative;
  margin: auto 0;
  overflow: hidden;
  width: 600px;
  z-index: ${zIndexes.modal + 1};
`;

const Header = styled.div`
  align-items: center;
  background-color: ${adjustColorOpacity(colors.white, 0.7)};
  border-bottom: 1px solid ${colors.gold[3]};
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  padding: 5px 20px 5px 30px;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    padding: 0 20px 0 60px;
  }
`;

const ModalScrollButton = styled(ScrollButton)`
  bottom: 10px;
  position: absolute;
  right: 20px;
  z-index: ${zIndexes.loading};
`;
