import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FC, useEffect, useRef, useState } from "react";
import { colors, transition } from "../theme";
import Icon from "./Icon";
import TableRow, { ITableRowProps } from "./TableRow";

interface ITableRowCollapsibleProps {
  initialCollapsed?: boolean;
  withTransition?: boolean;
}

const TableRowCollapsible: FC<ITableRowCollapsibleProps & ITableRowProps> = ({
  children,
  columns,
  initialCollapsed = false,
  withTransition = false,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const collapsedRef = useRef<boolean>(initialCollapsed);
  const collapsedTop = useRef<number>(0);
  const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed);

  const onCollapsed = () => {
    if (!ref.current) return;
    ref.current.style.maxHeight =
      (collapsedRef.current
        ? 0
        : (ref.current.firstElementChild?.scrollHeight || 0) + 1) /*border*/ +
      "px";
  };

  const onCollapsibleButton = () => {
    collapsedTop.current = window.scrollY;
    collapsedRef.current = !collapsed;
    setCollapsed(!collapsed);

    window.dispatchEvent(new Event("collapsed"));
    window.scrollTo({ top: collapsedTop.current });
    withTransition
      ? setTimeout(
          () => window.dispatchEvent(new Event("scroll")),
          transition * 1000
        )
      : window.dispatchEvent(new Event("scroll"));
  };

  useEffect(() => {
    !initialCollapsed && onCollapsed();

    window.addEventListener("collapsed", onCollapsed);
    window.addEventListener("resize", onCollapsed);

    return () => {
      window.removeEventListener("collapsed", onCollapsed);
      window.removeEventListener("resize", onCollapsed);
    };
  }, []);

  return (
    <>
      <StyledTableRow
        collapsed={collapsed}
        columns={
          columns.map((column, index) => (
            <>
              <div id={`collapsed-${collapsed}`} key={`collapsed-${index}`}>
                {index === 0 && !!children && (
                  <CollapsibleButton
                    onClick={onCollapsibleButton}
                    twoColumns={columns.length > 1}
                  >
                    <Icon name={collapsed ? "plus" : "minus"} />
                  </CollapsibleButton>
                )}
                <React.Fragment key={`${collapsed}-${index}`}>{column}</React.Fragment>
              </div>
            </>
          )) as [JSX.Element] | [JSX.Element, JSX.Element]
        }
        hasChildren={!!children}
        twoColumns={columns.length > 1}
        withTransition={withTransition}
        {...props}
      />
      {!!children && (
        <Content
          collapsed={collapsed}
          ref={ref}
          withTransition={withTransition}
        >
          <div>{children}</div>
        </Content>
      )}
    </>
  );
};

export default TableRowCollapsible;

const Content = styled.div<{ collapsed: boolean; withTransition: boolean }>`
  :not(:last-of-type) > div:last-of-type {
    border-bottom: solid 1px ${colors.gold[3]};
  }

  ${(props) => props.collapsed && css`
    max-height: 0;
    overflow: hidden;
  `};

  ${props => props.withTransition && css`
    transition: max-height ${transition}s ease-out;
  `}
`;

const CollapsibleButton = styled.div<{ twoColumns: boolean }>`
  align-items: center;
  border-right: solid 1px ${colors.gold[3]};
  color: ${colors.gold[1]};
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  min-height: 40px;
  min-width: 25px;
  padding: 0 !important;
  width: 25px;

  ${(props) => props.twoColumns && "margin-left: -20px;"}
`;

const StyledTableRow = styled(TableRow)<{
  collapsed: boolean;
  hasChildren: boolean;
  twoColumns: boolean;
  withTransition: boolean;
}>`
  > * {
    ${props => props.hasChildren && "font-weight: bold;"}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  > div > div {
    align-items: stretch;
    display: flex;
    height: 100%;
    width: 100%;

    > *:last-of-type:not(:first-of-type) {
      padding: 5px 0;
      width: calc(100% - 36px);
    }

    :not(h3):not(h2) {
      line-height: 30px;
    }

    p {
      align-self: center;
    }
  }

  h3 {
    line-height: 34px;
  }

  :nth-last-of-type(${props => props.hasChildren ? 2 : 1}) {
    border-bottom-width: ${(props) => (props.collapsed ? 0 : 1)}px;
    transition: border-bottom-width
      ${(props) => (props.withTransition ? transition : 0)}s ease-out;
  }

  ${(props) =>
    !props.twoColumns &&
    css`
      padding: 0 20px 0 0;
    `};
`;
