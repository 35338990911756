import styled from "@emotion/styled";
import React, { memo, FC } from "react";
import {
  Basket,
  Angle,
  Arrow,
  Buy,
  Close,
  CloseSmall,
  Collapse,
  Download,
  Drop,
  Gear,
  Lens,
  LessThan,
  LessThanSmall,
  LinkExternal,
  Location,
  Menu,
  Minus,
  Moon,
  Options,
  OrderDown,
  OrderInactive,
  OrderUp,
  Person,
  Plus,
  Read,
  Resize,
  Return,
  Send,
  Success,
  Sun,
  TrinityLogo,
  Twitter,
  IncreaseFont,
  DecreaseFont,
  BasketWhite,
  Trash
} from "../assets/icons";

import { colors } from "../theme";

export interface IIconProps {
  className?: string;
  name: string;
  negative?: boolean;
}

/**
 * Icon component that renders an svg from a catalog of icons
 */
const IconSVG: FC<IIconProps> = ({ className, name }) => {
  const rest = { className } as any;

  switch (name) {
    case "trash":
      return <Trash {...rest} />;
    case "basket-white":
      return <BasketWhite {...rest} />;
    case "basket":
      return <Basket {...rest} />;
    case "increase-font":
      return <IncreaseFont {...rest} />;
    case "decrease-font":
      return <DecreaseFont {...rest} />;
    case "angle":
      return <Angle {...rest} />;
    case "arrow":
      return <Arrow {...rest} />;
    case "buy":
      return <Buy {...rest} />;
    case "close":
      return <Close {...rest} />;
    case "close-small":
      return <CloseSmall {...rest} />;
    case "collapse":
      return <Collapse {...rest} />;
    case "download":
      return <Download {...rest} />;
    case "drop":
      return <Drop {...rest} />;
    case "gear":
      return <Gear {...rest} />;
    case "lens":
      return <Lens {...rest} />;
    case "less-than":
      return <LessThan {...rest} />;
    case "less-than-small":
      return <LessThanSmall {...rest} />;
    case "link-external":
      return <LinkExternal {...rest} />;
    case "location":
      return <Location {...rest} />;
    case "menu":
      return <Menu {...rest} />;
    case "minus":
      return <Minus {...rest} />;
    case "moon":
      return <Moon {...rest} />;
    case "options":
      return <Options {...rest} />;
    case "order-down":
      return <OrderDown {...rest} />;
    case "order-inactive":
      return <OrderInactive {...rest} />;
    case "order-up":
      return <OrderUp {...rest} />;
    case "person":
      return <Person {...rest} />;
    case "plus":
      return <Plus {...rest} />;
    case "read":
      return <Read {...rest} />;
    case "resize":
      return <Resize {...rest} />;
    case "return":
      return <Return {...rest} />;
    case "send":
      return <Send {...rest} />;
    case "success":
      return <Success {...rest} />;
    case "sun":
      return <Sun {...rest} />;
    case "trinitylogo":
      return <TrinityLogo {...rest} />;
    case "twitter":
      return <Twitter {...rest} />;
    default:
      // tslint:disable-next-line:no-console
      console.warn("Icon not found");
      return null;
  }
};

const Icon: FC<IIconProps> = ({ ...props }) => <StyledIcon {...props} />;

const StyledIcon = styled(IconSVG)<{ negative?: boolean }>`
  color: ${(props) => (props.negative ? colors.white : "inherit")};
`;

export default memo(Icon);
