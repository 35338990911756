import styled from "@emotion/styled";
import React, { FC } from "react";
import { colors } from "../theme";

export interface ITableHeadProps {
  className?: string;
  header: JSX.Element;
}

const TableHead: FC<ITableHeadProps> = ({ children, header, ...props }) => (  
  <Head {...props}>
    {header}
    {children}
  </Head>
);

export default TableHead;

const Head = styled.div`
  align-items: center;
  border-bottom: solid 1px ${colors.gold[3]};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 14px;
  text-align: left;

  *:not(button):not(div):not(h2):not(h2 > *) {
    overflow: hidden;
    padding-right: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const OrderButton = styled.div`
  align-self: center;
  cursor: pointer;
  height: 20px;
  margin: 0;
  margin-left: 10px;
`;
