import styled from "@emotion/styled";
import React, { FC } from "react";
import SingleLangContentsEntryComponent from "./SingleLangContentsEntryComponent";
import { cloneDeep } from "@apollo/client/utilities";
import { SingleLangContentsEntry } from "@balthasarspeyr/common";

const findAndUpdate = (
  contents: SingleLangContentsEntry,
  entry: SingleLangContentsEntry
): SingleLangContentsEntry => {
  // if it is among the children, return the updated object.
  if (contents.children.find((cont) => cont.id === entry.id)) {
    return {
      ...contents,
      children: contents.children.map((cont) => {
        if (cont.id !== entry.id) return cont;
        else return entry;
      })
    };
    // if not, look on children (of children)
  } else {
    return {
      ...contents,
      children: contents.children.map((child) => findAndUpdate(child, entry))
    };
  }
};

const SingleLangContentsComponent: FC<{
  data: SingleLangContentsEntry;
  updateContents: (data: SingleLangContentsEntry) => void;
}> = ({ data, updateContents }) => {
  const update = (entry: SingleLangContentsEntry) => {
    const newContents = cloneDeep(data).children.map((c) =>
      findAndUpdate(c, entry)
    );
    updateContents({
      ...data,
      children: newContents
    });
  };

  const setParents = (entry: SingleLangContentsEntry) => {
    entry.children.forEach((child) => {
      child.parent = entry;
      setParents(child);
    });
  };

  data.children.forEach((entry) => {
    entry.parent = data;
    setParents(entry);
  });

  const right = (
    previous: SingleLangContentsEntry,
    toMove: SingleLangContentsEntry
  ) => {
    previous.children.push(cloneDeep(toMove));
    const index = data.children.indexOf(toMove);
    data.children.splice(index, 1);
    updateContents({
      ...data,
      children: [...data.children]
    });
  };

  const up = (
    parent: SingleLangContentsEntry,
    toMove: SingleLangContentsEntry
  ) => {
    const index = parent.children.indexOf(toMove);
    const aux = parent.children[index - 1];
    parent.children[index - 1] = toMove;
    parent.children[index] = aux;
    updateContents({
      ...data,
      children: [...parent.children]
    });
  };

  const down = (
    parent: SingleLangContentsEntry,
    toMove: SingleLangContentsEntry
  ) => {
    const index = parent.children.indexOf(toMove);
    const aux = parent.children[index + 1];
    parent.children[index + 1] = toMove;
    parent.children[index] = aux;
    updateContents({
      ...data,
      children: [...parent.children]
    });
  };

  const remove = (
    parent: SingleLangContentsEntry,
    toMove: SingleLangContentsEntry
  ) => {
    const index = parent.children.indexOf(toMove);
    parent.children.splice(index, 1);
    updateContents({
      ...data,
      children: [...parent.children]
    });
  };

  return (
    <Contents>
      {data.children.map((child: SingleLangContentsEntry, index: number) => {
        if (data.children.length === 1)
          return (
            <SingleLangContentsEntryComponent
              notifyParent={(e) => {
                update(e);
              }}
              entry={child}
              key={child.id}
              last={true}
            />
          );
        else if (index === 0)
          return (
            <SingleLangContentsEntryComponent
              notifyParent={(e) => {
                update(e);
              }}
              entry={child}
              key={child.id}
              moveDown={() => down(data, child)}
            />
          );
        else if (index > 0 && index < data.children.length - 1)
          return (
            <SingleLangContentsEntryComponent
              notifyParent={(e) => {
                update(e);
              }}
              entry={child}
              key={child.id}
              moveRight={() => right(data.children[index - 1], child)}
              moveDown={() => down(data, child)}
              moveUp={() => up(data, child)}
              removeEntry={() => remove(data, child)}
            />
          );
        else
          return (
            <SingleLangContentsEntryComponent
              notifyParent={(e) => {
                update(e);
              }}
              entry={child}
              key={child.id}
              moveRight={() => right(data.children[index - 1], child)}
              moveUp={() => up(data, child)}
              last={true}
              removeEntry={() => remove(data, child)}
            />
          );
      })}
    </Contents>
  );
};

export default SingleLangContentsComponent;

const Contents = styled.div`
  font-size: 0.8rem;
  select,
  option {
    font-size: 0.8rem;
  }
`;
