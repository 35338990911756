import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../styles/theme";

const BoxWithTitleContainer: FC<{ title: string }> = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};

export default BoxWithTitleContainer;

const Container = styled.div`
  border: 1px solid ${theme.colors.black};
  padding: 20px;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  position: relative;
  top: -41px;
  margin-bottom: -40px;
  z-index: 1;
  background-color: inherit;
  width: fit-content;
  height: fit-content;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
`;
