import styled from "@emotion/styled";
import React, { FC, useRef, useState } from "react";
import { ReactReader } from "react-reader";
import { breakpoints, colors } from "../theme";
import Icon from "./Icon";
import Modal, { IModalProps } from "./Modal";

interface IEpubReaderProps extends IModalProps {
  loadingView: JSX.Element;
  title: string;
  url: string;
}

const EpubReader: FC<IEpubReaderProps> = ({
  loadingView,
  onClose,
  title,
  url,
  ...props
}) => {
  const [location, setLocation] = useState<string | number>();
  const [theme, setTheme] = useState<"dark" | "light">("light");
  const [fontSize, setFontSize] = useState<number>(120);
  const renditionRef = useRef<any>(null);

  const locationChanged = (epubcifi: string | number) => {
    // Used by epubjs to point to a location in an epub. It looks like this: epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
    setLocation(epubcifi);
  };

  // useEffect(() => {
  //   renditionRef.current?.themes.select(theme);
  // }, [theme]);

  //   const ownStyles = {
  //   ...ReactReaderStyle,
  //   arrow: {
  //     ...ReactReaderStyle.arrow,
  //     height: "1em",
  //     "margin-top": "-1em"
  //   }
  // }

  return (
    <EpubModal theme={theme} {...props}>
      <ReactReader
        //readerStyles={ownStyles}
        epubOptions={{ allowScriptedContent: true, allowPopups: true } as any}
        getRendition={(rendition) => {
          renditionRef.current = {
            ...rendition
          };
          renditionRef.current.themes.register("light", {
            section: {
              background: colors.white,
              color: colors.black
            }
          });
          renditionRef.current.themes.register("dark", {
            section: {
              background: colors.black,
              color: colors.white
            }
          });
          renditionRef.current.themes.select(theme);
          renditionRef.current.themes.fontSize(`${fontSize}%`);
        }}
        loadingView={loadingView}
        location={location}
        locationChanged={locationChanged}
        showToc={false}
        url={url}
        key={`${theme}-${fontSize}`}
      />
      <EpubHeader>
        <div>
          <Icon name="read" />
          <h3>{title}</h3>
        </div>
        <div>
          {fontSize < 250 && (
            <EpubHeaderButton
              theme={theme}
              onClick={() => setFontSize(fontSize * 1.1)}
            >
              <Icon name={"increase-font"} />
            </EpubHeaderButton>
          )}
          {fontSize > 70 && (
            <EpubHeaderButton
              theme={theme}
              onClick={() => setFontSize(fontSize / 1.05)}
            >
              <Icon name={"decrease-font"} />
            </EpubHeaderButton>
          )}
          <EpubHeaderButton
            theme={theme}
            onClick={() => setTheme(theme === "light" ? "dark" : "light")}
          >
            <Icon name={theme === "light" ? "moon" : "sun"} />
          </EpubHeaderButton>
          <EpubHeaderButton onClick={onClose} theme={theme}>
            <Icon name="close" />
          </EpubHeaderButton>
        </div>
      </EpubHeader>
    </EpubModal>
  );
};

export default EpubReader;

const EpubHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.gold[3]};
  box-sizing: border-box;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: 0 20px 0 40px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  > div:first-of-type {
    align-items: center;
    display: flex;
    overflow: hidden;
    svg {
      margin-right: 10px;
      min-width: 20px;
    }
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  > div:last-of-type {
    align-items: center;
    display: flex;
  }
`;

const EpubHeaderButton = styled.div<{
  theme: "dark" | "light";
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-left: 10px;
  width: 40px;
  svg {
    fill: ${(props) => (props.theme === "dark" ? colors.white : colors.black)};
  }
`;

const EpubModal = styled(Modal)<{ theme: "dark" | "light" }>`
  height: 100%;
  width: 100%;
  * {
    background-color: ${(props) =>
      props.theme === "light" ? colors.white : colors.black}!important;
    color: ${(props) =>
      props.theme === "light" ? colors.black : colors.white}!important;
  }
  > div:first-of-type {
    display: none;
  }
  > div:last-of-type {
    height: 100%;
    padding: 0;
    position: relative;
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.tablet}px) {
    height: calc(100% - 20px);
    margin: 10px;
  }
`;
