import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { InputTextComponent } from "../InputTextComponent";
import { WhiteButton } from "../../styles/styledcomponets";
import { useQuery, gql } from "@apollo/client";
import { LoaderLarge } from "../Loaders";
import { Author, Label } from "@balthasarspeyr/common";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { theme } from "../../styles/theme";
const LABELS = gql`
  query getLabels {
    getLabels {
      id
      label {
        en
      }
      public
    }
  }
`;

const LabelsSelector: FC<{
  labels: string[];
  onChange: (labels: string[]) => void;
  showHelper?: boolean;
  editing?: boolean;
}> = ({
  labels: labelProps,
  onChange: updateLabels,
  showHelper = false,
  editing: editingProp,
}) => {
  const { data, loading, error } = useQuery(LABELS, {
    fetchPolicy: "network-only",
  });

  const [filter, setFilter] = useState<string>("");
  const [labels, setLabels] = useState<Label[]>([]);
  const [editing, setEditing] = useState<boolean>(editingProp || false);

  useEffect(() => {
    if (data) {
      const labels = data.getLabels.filter((label) =>
        labelProps.includes(label.id)
      );
      setLabels(labels);
    }
  }, [data, labelProps]);

  if (loading) return <LoaderLarge />;
  if (error) return <p>Error :( </p>;

  return (
    <Div onClick={() => setEditing(true)}>
      {showHelper && <strong>Labels</strong>}
      <Labels>
        {labels.map((label) => (
          <LabelTag key={label.id}>
            {label.label.en}{" "}
            <div>
              <TrashIcon
                onClick={() => {
                  updateLabels(
                    labels
                      .filter((t) => t.id !== label.id)
                      .map((label) => label.id)
                  );
                }}
              />
            </div>
          </LabelTag>
        ))}
      </Labels>
      {(editing || labels.length === 0) && (
        <InputTextComponent
          showHelper={showHelper}
          width="auto"
          placeholder="search label"
          helper=""
          value={filter}
          onChange={(value) => {
            setFilter(value);
          }}
        />
      )}
      {(editing || labels.length === 0) &&
        filter.length >= 1 &&
        data.getLabels
          .filter((label: Label) =>
            label.label.en?.toLowerCase().includes(filter.toLowerCase())
          )
          .map((label: Label) => (
            <div key={label.id}>
              {label.label.en}
              <WhiteButton
                margin="5px 0 0 5px"
                onClick={() => {
                  setFilter("");
                  updateLabels(
                    [...labels, label].map((label: Label) => label.id)
                  );
                }}
              >
                Add
              </WhiteButton>
            </div>
          ))}
    </Div>
  );
};

export default LabelsSelector;

const Div = styled.div`
  width: 95%;
  margin: 0;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  height: fit-content;
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    min-width: 9px;
    width: 9px;
    min-height: 9px;
    height: 9px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    :hover {
      fill: ${theme.colors.brandRed};
    }
  }
  margin: 0px;
  padding: 0px;
`;

const LabelTag = styled.div`
  font-size: 0.7rem;
  space: nowrap;
  display: flex;
  flex-direction: row;
  border: 1px solid ${theme.colors.gold01};
  border-radius: 8px;
  padding: 2px;
  margin: 2px;
  background-color: ${theme.colors.gold04};
  height: fit-content;
  div {
    margin-left: 5px;
  }
`;
