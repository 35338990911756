import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import WorkLetterEdit from "./WorkLetterEdit";
import WorkLetterAdd from "./WorkLetterAdd";
import { Author, LettersMap, siteAuthors, Work } from "@balthasarspeyr/common";
import { useQuery, useMutation, gql } from "@apollo/client";
import cloneDeep from "clone-deep";
import { WorkLetterInput } from "../../../types";
import { LoaderLarge } from "../../Loaders";
import {
  Input,
  H2,
  H3,
  Table,
  Row,
  Title,
} from "../../../styles/styledcomponets";

type WorkLettersData = {
  getLettersMap: LettersMap;
};

const LETTERSMAP = gql`
  query getLettersMap($author: ID!) {
    getLettersMap(author: $author) {
      recipients {
        id
        name {
          en
        }
        surname {
          en
        }
        letters {
          id
          baselref
          letterPlace
          letterDate
          public
          originalLang
          year
          labels {
            id
          }
        }
      }
    }
  }
`;

const ADD_WORK = gql`
  mutation addWork($work: WorkInput!) {
    addWork(work: $work) {
      id
    }
  }
`;

const DELETE_WORK = gql`
  mutation deleteWork($id: ID!) {
    deleteWork(id: $id) {
      ok
    }
  }
`;

const AuthorWorkLetters: FC<{ author: Partial<Author> }> = ({ author }) => {
  const [addWork] = useMutation<{ addWork: Work }, { work: WorkLetterInput }>(
    ADD_WORK,
    {
      onCompleted({ addWork }) {
        if (addWork) {
          refetch();
        }
      },
      onError(error) {
        console.log(error);
      },
    }
  );

  const [deleteWork] = useMutation<
    { deleteWork: { ok: boolean } },
    { id: string }
  >(DELETE_WORK, {
    onCompleted({ deleteWork }) {
      if (deleteWork.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const [nameFilter, setNameFilter] = useState("");

  const [visibleRecipient, setVisibleRecipient] = useState<string | null>(null);

  const { loading, error, data, refetch } = useQuery<WorkLettersData>(
    LETTERSMAP,
    {
      variables: { author: author.id! },
      fetchPolicy: "no-cache",
    }
  );

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <H2>
        Letters by {author.name?.en} {author.surname?.en}
      </H2>
      <Input
        border="1px solid #ccc"
        marginBottom="10px"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        placeholder="Filter by name or surname"
        width="200px"
      />
      {data &&
        data.getLettersMap &&
        data.getLettersMap.recipients
          .filter(
            (recipient) =>
              recipient.name.en
                ?.toLowerCase()
                .includes(nameFilter.toLowerCase()) ||
              recipient.surname.en
                ?.toLowerCase()
                .includes(nameFilter.toLowerCase())
          )
          .map((recipient) => (
            <React.Fragment key={recipient.id}>
              {visibleRecipient === recipient.id && (
                <React.Fragment>
                  <H3 onClick={() => setVisibleRecipient(null)}>
                    To {recipient.name.en} {recipient.surname.en}
                  </H3>
                  <Table width="100%">
                    <thead>
                      <Row borderBottom="1px solid #aaa">
                        <Title width="100px%">From (place)</Title>
                        <Title width="15%">Date</Title>
                        <Title width="10%">Language</Title>
                        <Title width="70px">Basel Ref.</Title>
                        <Title width="250px">Labels</Title>
                        <Title width="30px">Public</Title>
                        <Title width="*">&nbsp;</Title>
                      </Row>
                    </thead>
                    <tbody>
                      {recipient.letters.map((letter) => (
                        <WorkLetterEdit
                          author={author.id!}
                          recipient={recipient.id}
                          refetchHandler={() => refetch()}
                          deleteHandler={() =>
                            deleteWork({
                              variables: { id: letter.id! },
                            })
                          }
                          key={letter.id!}
                          workLetterProp={cloneDeep({
                            ...letter,
                            labels: letter.labels.map((label) => label.id),
                          })}
                        />
                      ))}

                      <WorkLetterAdd
                        addHandler={(work: WorkLetterInput) => {
                          addWork({ variables: { work } });
                        }}
                        author={author.id!}
                        recipient={recipient.id}
                      />
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
              {visibleRecipient !== recipient.id && (
                <RecipientItem
                  onClick={() => setVisibleRecipient(recipient.id)}
                >
                  {recipient.name.en} {recipient.surname.en}
                  {" (" + recipient.letters.length + " letters )"}
                </RecipientItem>
              )}
            </React.Fragment>
          ))}
    </div>
  );
};

const WorkLettersCMSLayout: FC = () => {
  return (
    <div>
      <AuthorWorkLetters author={siteAuthors.balthasar} />
      <AuthorWorkLetters author={siteAuthors.speyr} />
    </div>
  );
};

export default WorkLettersCMSLayout;

const RecipientItem = styled.div`
  font-size: 0.9rem;
  cursor: pointer;
`;
