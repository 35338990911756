import mongoose from "mongoose";

/********* ENUMS (NOT TO EXPORT) ********/

export enum AUTHORROLE {
  Author = "Author",
  Editor = "Editor",
  Translator = "Translator",
  Contributor = "Contributor",
  Reviewer = "Reviewer",
  Preface = "Preface",
  Epilogue = "Epilogue",
  Anthologist = "Anthologist"
}

enum USERROLE {
  USER = "USER",
  ADMIN = "ADMIN",
  BOOKS = "BOOKS"
}

export enum SITELANGS {
  en = "en",
  es = "es",
  fr = "fr",
  it = "it",
  de = "de",
  jpn = "jpn"
}

export enum PUBLICATIONLANGS {
  ara = "Arabic",
  bul = "Bulgarian",
  cat = "Catalan",
  ces = "Czech",
  "zho-Hant" = "Chinese (Traditional)",
  "zho-Hans" = "Chinese (Simplified)",
  kor = "Korean",
  hrv = "Croatian",
  dan = "Danish",
  fr = "French",
  jpn = "Japanese",
  ell = "Greek",
  en = "English",
  it = "Italian",
  lat = "Latin",
  lit = "Lithuanian",
  nld = "Dutch",
  nor = "Norwegian",
  pol = "Polish",
  por = "Portuguese",
  ron = "Romanian",
  rus = "Russian",
  slk = "Slovak",
  slv = "Slovenian",
  es = "Spanish",
  swe = "Swedish",
  de = "German",
  ukr = "Ukrainian",
  hun = "Hungarian"
}

export enum WORKTYPE {
  MAINWORK = "Mainwork",
  TRANSLATION = "Translation",
  ANTHOLOGY = "Anthology",
  ARTICLE = "Article",
  CONTRIBUTION = "Contribution",
  PREFACE = "Preface",
  EPILOGUE = "Epilogue",
  LETTER = "Letter",
  EDITED_WORK = "EditedWork"
}

export enum PUBLICATIONTYPE {
  MAINWORK = "Mainwork",
  TRANSLATION = "Translation",
  ANTHOLOGY = "Anthology",
  ARTICLE = "Article",
  CONTRIBUTION = "Contribution",
  PREFACE = "Preface",
  EPILOGUE = "Epilogue",
  LETTER = "Letter",
  EDITED_WORK = "EditedWork",
  EXCERPT = "Excerpt"
}

export enum FEATUREDTAB {
  NEW = "new",
  NEWBOOKS = "newBooks",
  PRINTEDBOOKS = "printedBooks"
}

enum PREPOSTTYPE {
  PREFACE = "Preface",
  EPILOGUE = "Epilogue"
}

/********* CONSTANTS (FROM ENUMS) ***********/

export const Langs: { [key: string]: string } = Object.keys(SITELANGS).reduce(
  (obj, key) => {
    return { ...obj, [key]: (SITELANGS as any)[key] };
  },
  {}
);

export const PrePostType: { [key: string]: string } = Object.keys(
  PREPOSTTYPE
).reduce((obj, key) => {
  return { ...obj, [key]: (PREPOSTTYPE as any)[key] };
}, {});

/******************************* Types   **************** */

/** texts in the available languages */
export type LangTexts = { [key in SITELANGS]?: string };

/** CMS Users */
export type User = {
  id: string;
  email: string;
  name: string;
  surname: string;
  token?: string;
  role: string;
  authorized?: boolean;
};

/** Single New for the landing page */
export type News = {
  id: string;
  title: LangTexts;
  body: LangTexts;
  url?: LangTexts;
  linkText?: LangTexts;
  start: string;
  end: string;
};

/** List of News for the landing page */
export type NewsList = ListCommon & { data: [News] };

/*** Author of a work ***/
export type Author = {
  id: string;
  name: LangTexts;
  surname: LangTexts;
  alias: LangTexts;
  works?: Work[];
  numWorks?: number;
  numTranslations?: number;
  image?: string;
  covercolors?: { [key in WORKTYPE]: string };
};

// type for Work Index of Contents
export type ContentsEntry = {
  id: string;
  text: LangTexts;
  sign: string;
  children: ContentsEntry[];
  parent?: ContentsEntry;
};

export type SingleLangContentsEntry = {
  id: string;
  text: string;
  sign: string;
  children: SingleLangContentsEntry[];
  parent?: SingleLangContentsEntry;
};

export type PressHouse = {
  id: string;
  name: string;
  website: string;
  country?: string[];
  city?: string[];
  publications?: Array<Partial<Publication>>;
};

export type PressHouseList = ListCommon & { data: [PressHouse] };

export type Recipient = {
  id: string;
  name: LangTexts;
  surname: LangTexts;
  image?: string;
  alias?: LangTexts;
  abstract?: LangTexts;
  description?: Array<{
    author: Author;
    description: LangTexts;
    correspondenceLanguage: string;
  }>;

  letters: WorkLetter[];
  url: LangTexts;
};

export type LettersMap = {
  author: Author;
  recipients: Recipient[];
};

export type ShortWorksMap = {
  author: Author;
  shortWorksStages: ShortWorksStage[];
};

export type EditedWorksMap = {
  author: Author;
  editedAuthor: EditedAuthor[];
};

export type EditedAuthor = {
  id: string;
  name: LangTexts;
  surname?: LangTexts;
  alias?: LangTexts;
  image?: string;
  anthologies: Work[];
  translations: Work[];
  prefaces: Work[];
  epilogues: Work[];
  allWorks: Work[];
};

export type ShortWorksStage = {
  id: string;
  author: Author;
  name: LangTexts;
  articles: WorkShortWork[];
  contributions: WorkShortWork[];
  order: number;
  abstract?: LangTexts;
  description?: LangTexts;
  url: LangTexts;
};

export type CommonWorks = {
  id: string;
  type: WORKTYPE[];
  title?: LangTexts;
  authors: Array<{ author: Author; role: AUTHORROLE }>;
  year: number;
  baselref: string;
  public: boolean;
  originalLang: string;
  labels: Label[];
  originalLangPubs: Publication[];
  allPubs: Publication[];
  relatedWorks: Work[];
  numPublications: number;
  createdBy: User;
  lastUpdatedBy: User;
  createdAt: string;
  updatedAt: string;
};

export type WorkLetter = CommonWorks & {
  letterRecipient: Recipient;
  letterPlace: string;
  letterDate: string;
};

export type WorkShortWork = CommonWorks & {
  title: LangTexts;
  shortWorksStage: ShortWorksStage;
  subtitle?: LangTexts;
};

export type WorkEditedWork = CommonWorks & {
  contentsStyle?: string;
  editedAuthor: EditedAuthor;
  title: LangTexts;
  subtitle?: LangTexts;
  caption?: LangTexts;
  abstract?: LangTexts;
  description?: LangTexts;
  excerpt?: LangTexts;
  contents?: ContentsEntry;
  literature?: LangTexts;
  videos?: LangTexts;
  url: LangTexts;
};

export type WorkMain = CommonWorks & {
  title: LangTexts;
  subtitle?: LangTexts;
  caption?: LangTexts;
  abstract?: LangTexts;
  description?: LangTexts;
  excerpt?: LangTexts;
  contentsStyle?: string;
  contents?: ContentsEntry;
  literature?: LangTexts;
  videos?: LangTexts;
  url: LangTexts;
};

export type Work = WorkLetter | WorkShortWork | WorkEditedWork | WorkMain;

export type WorkInput = Omit<
  WorkLetter & WorkShortWork & WorkEditedWork & WorkMain,
  | "authors"
  | "originalLangPubs"
  | "allPubs"
  | "relatedWorks"
  | "numPublications"
  | "createdBy"
  | "lastUpdatedBy"
  | "letterRecipient"
  | "shortWorksStage"
  | "editedAuthor"
  | "letterDate"
  | "labels"
> & {
  labels: mongoose.Types.ObjectId[];
  authors: { id: mongoose.Types.ObjectId; role: AUTHORROLE }[];
  createdBy: mongoose.Types.ObjectId;
  lastUpdatedBy: mongoose.Types.ObjectId;
  letterRecipient?: mongoose.Types.ObjectId;
  letterDate?: Date;
  shortWorksStage?: mongoose.Types.ObjectId;
  editedAuthor?: mongoose.Types.ObjectId;
};

export type ListCommon = {
  totalNumber: number;
  page: number;
  totalPages: number;
  pageSize: number;
};

export type WorksList = ListCommon & { data: Work[] };

export type ContainedWork = {
  work: Work;
  parts?: string[];
  full: boolean;
};

export enum TEXTFORMATS {
  PDF = "pdf",
  EPUB = "epub",
  MOBI = "mobi"
}

export enum PUBSORDER {
  ASC = "add-asc",
  DESC = "add-desc",
  AZ = "name-az",
  ZA = "name-za"
}

export type DownloadLog = {
  id: string;
  publication: Publication;
  format: TEXTFORMATS;
  timestamp: string;
  country: string;
};

type DownloadFormatSummary = {
  format: string;
  count: number;
  lastDownload: string;
};

export type DownloadLogSummary = {
  publication: Publication;
  downloads: DownloadFormatSummary[];
};

export enum PUBSCREATION {
  LAST_MONTH = "last-month",
  LAST_SIX_MONTHS = "last-six-months",
  LAST_YEAR = "last-year"
}

export enum UPLOADFILETYPES {
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  PDF = "pdf",
  EPUB = "epub",
  MOBI = "mobi"
}

export type CommonPublications = {
  id: string;
  stock?: number;
  title: string;
  public: boolean;
  list: boolean;
  featured: boolean;
  translators: Array<Author>;
  image?: string;
  lang: keyof typeof PUBLICATIONLANGS;
  year: number;
  DOI?: string;
  isbpn?: string;
  pressHouse: PressHouse;
  purchaseLinks: string[];
  downloadLinks: Array<{ url: string; format: TEXTFORMATS; isbn?: string }>;
  readLink?: string;
  formats: Array<string>;
  bibref?: string;
  containedWorks: ContainedWork[];
  relatedPubs: Publication[];
  url: string;
};

export type GenericBook = CommonPublications & {
  isbn?: string;
  contents?: string;
  subtitle?: string;
  caption?: string;
  pages?: number;
  abstract?: string;
  text?: string;
  image?: string;
};

export type MainPublication = GenericBook;
export type EditedBook = GenericBook;

export type Letter = CommonPublications & {
  text: string;
};

export type ShortWork = CommonPublications & {
  text: string;
  subtitle?: string;
};

export type Excerpt = GenericBook & {
  text: string;
  subtitle?: string;
};

export type Article = CommonPublications & {
  year: string;
  text: string;
};

export type Contribution = CommonPublications & {
  year: string;
  text: string;
};

export type Publication = (
  | Letter
  | Article
  | Contribution
  | EditedBook
  | MainPublication
) & {
  type: WORKTYPE;
};

export type PublicationFilter = {
  authors: string[];
  langs: SITELANGS[];
  types: WORKTYPE[];
  labels: string[];
};

export type FeaturedPublications = {
  [key in FEATUREDTAB]: ListCommon & { data: Publication[] };
};

export type PublicationsList = ListCommon & { data: [Publication] };

// export type CommonPublicationsInputData = Omit<
//   CommonPublications,
//   "translators" | "pressHouse" | "url"
// > & {
//   translators: Array<string>;
//   pressHouse: string;
// };

export type PublicationInputData = Omit<
  CommonPublications &
    Letter &
    Article &
    Contribution &
    EditedBook &
    MainPublication,
  "translators" | "pressHouse" | "containedWorks" | "id" | "url" | "relatedPubs"
> & {
  translators: Array<string>;
  pressHouse: string;
  containedWorks: Array<{ work: string; parts: Array<string>; full: boolean }>;
};

export type LetterPublicationInputData = Omit<
  Letter,
  "translators" | "pressHouse" | "containedWorks" | "id" | "url" | "relatedPubs"
> & {
  translators: Array<string>;
  pressHouse: string;
  containedWorks: Array<{ work: string; parts: Array<string>; full: boolean }>;
};

export type BookInputData = Omit<
  GenericBook,
  "translators" | "pressHouse" | "containedWorks" | "id" | "url" | "relatedPubs"
> & {
  translators: Array<string>;
  pressHouse: string;
  containedWorks: Array<{ work: string; parts?: Array<string>; full: boolean }>;
};

export type ShortWorkPublicationInputData = Omit<
  ShortWork,
  "translators" | "pressHouse" | "containedWorks" | "id" | "url" | "relatedPubs"
> & {
  translators: Array<string>;
  pressHouse: string;
  containedWorks: Array<{ work: string; parts: Array<string>; full: boolean }>;
};

export type Label = {
  label: LangTexts;
  id: string;
  public: boolean;
};

export type IContentsEntry = {
  id: string;
  text: LangTexts;
  sign: string;
  children: IContentsEntry[];
  parent?: IContentsEntry;
};

export type DeliveryAddress = {
  name: string;
  email: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  receiverVAT: string;
};

export type CartItem = {
  publication: string;
  quantity: number;
  title: string;
};

export type OrderData = {
  // ordenante
  status?: OrderStatus;
  ordererEmail: string;
  cart: Array<CartItem>;
  deliveryAddress: DeliveryAddress;
  comments: string;
  policy: Policy;
};

export type Policy = {
  privacy: boolean;
  shipmentInfo: boolean;
  subscribe: boolean;
};
export enum OrderStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  ABANDONED = "ABANDONED"
}
