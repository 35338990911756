import React, { FC } from "react";

import { siteAuthors } from "@balthasarspeyr/common";
import ShortWorkPubList from "./ShortWorkPubList";

const ShortWorkPubCMSLayout: FC = () => {
  return (
    <div>
      <ShortWorkPubList author={siteAuthors.balthasar} />
      <ShortWorkPubList author={siteAuthors.speyr} />
    </div>
  );
};

export default ShortWorkPubCMSLayout;
