import { SITELANGS, PUBLICATIONLANGS } from "@balthasarspeyr/common";
import React, { FC } from "react";
import SelectInputComponent from "./SelectInputComponent";

export const PublicationLanguageSelect: FC<{
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  hideHelper?: boolean;
}> = ({ value, onChange, hideHelper = false }) => {
  return (
    <SelectInputComponent
      width="auto"
      helper="Language"
      hideHelper={hideHelper}
      value={value || PUBLICATIONLANGS[0]}
      onChange={onChange}
      Elem={Object.keys(PUBLICATIONLANGS).map((key) => {
        return {
          key: key,
          value: PUBLICATIONLANGS[key],
        };
      })}
    />
  );
};

export const SiteLanguageSelect: FC<{
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  hideHelper?: boolean;
}> = ({ value, onChange, hideHelper = false }) => {
  return (
    <SelectInputComponent
      width="auto"
      hideHelper={hideHelper}
      helper="Language"
      value={value || SITELANGS[0]}
      onChange={onChange}
      Elem={Object.keys(SITELANGS).map((key) => {
        return {
          key: key,
          value: SITELANGS[key],
        };
      })}
    />
  );
};
