import dynamic from "next/dynamic";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { IPublicationCoverProps } from "./PublicationCover";
import { colors } from "../theme";

const PublicationCover = dynamic(() => import("./PublicationCover"), {
  ssr: false
});

const PublicationImage: FC<IPublicationCoverProps> = (props) => (
  <Container width={props.width}>
    {props.publication.image && props.publication.image !== "" ? (
      props.removeBackground ? (
        <Img
          backgroundColor={colors.white}
          width={props.width}
          src={props.publication.image}
          alt={props.publication.title}
        />
      ) : (
        <ImgContainer src={props.publication.image}>
          <Img
            width={props.width}
            src={props.publication.image}
            alt={props.publication.title}
          />
        </ImgContainer>
      )
    ) : (
      <PublicationCover {...props} />
    )}
  </Container>
);

export default PublicationImage;

const Container = styled.div<{ width: number }>`
  background-color: ${colors.grey[2]};
  height: ${(props) => props.width * 1.618}px;
  position: relative;
  width: ${(props) => props.width}px;
`;

const Img = styled.img<{ width: number; backgroundColor?: string }>`
  object-fit: scale-down;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  height: ${(props) => Math.floor(props.width * 1.618)}px;
  min-height: ${(props) => Math.floor(props.width * 1.618)}px;
  max-height: ${(props) => Math.floor(props.width * 1.618)}px;
  padding: 0;
  position: relative;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  color: transparent;
`;

const ImgContainer = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  &:before {
    backdrop-filter: blur(10px);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
