import { AUTHORROLE, WORKTYPE } from "@balthasarspeyr/common";
import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import {
  Cell,
  Input,
  RedButton,
  Row,
  SelectInput,
  WhiteButton,
} from "../../../styles/styledcomponets";
import { theme } from "../../../styles/theme";
import { Langs } from "../../../types";
import { WorkLetterInput } from "../../../types";
import LabelsSelector from "../../labels/LabelsSelector";

interface WorkLetterAddProps {
  addHandler: (workLetter: WorkLetterInput) => void;
  author: string;
  recipient: string;
}

const langs: string[] = Object.keys(Langs).map((key) => Langs[key]);

const WorkLetterAdd: FC<WorkLetterAddProps> = ({
  addHandler,
  author,
  recipient,
}) => {
  const [workLetter, setWorkLetter] = useState<WorkLetterInput>({
    type: [WORKTYPE.LETTER],
    authors: [{ id: author, role: AUTHORROLE.Author }],
    public: false,
    originalLang: Langs.de,
    labels: [],
    letterRecipient: recipient,
    letterPlace: "",
    letterDate: new Date("1/1/1900").toString(),
    year: 1900,
    baselref: "",
  });
  const cleanWorkLetter: WorkLetterInput = {
    type: [WORKTYPE.LETTER],
    authors: [{ id: author, role: AUTHORROLE.Author }],
    public: false,
    originalLang: Langs.de,
    labels: [],
    letterRecipient: recipient,
    letterPlace: "",
    letterDate: new Date("1/1/1900").toString(),
    year: 1900,
    baselref: "",
  };
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const valueChange = (value, key) => {
    const newWorkLetter: WorkLetterInput = { ...workLetter };
    if (Array.isArray(value)) newWorkLetter[key] = [...value];
    else newWorkLetter[key] = value;
    setWorkLetter(newWorkLetter);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setWorkLetter({ ...cleanWorkLetter });
  };

  const onAdd = () => {
    addHandler({
      ...workLetter,
      year: new Date(workLetter.letterDate).getFullYear(),
    });
    setWorkLetter({ ...cleanWorkLetter });
  };

  return (
    <React.Fragment>
      <Row borderBottom="1px solid #bbb">
        <Cell>
          <Input
            width="95%"
            placeholder="from (place)"
            type="text"
            onChange={(e) => valueChange(e.target.value, "letterPlace")}
            value={workLetter.letterPlace || ""}
          />
        </Cell>
        <Cell>
          <Input
            width="95%"
            placeholder="date"
            type="date"
            onChange={(e) => valueChange(e.target.value, "letterDate")}
            value={workLetter.letterDate || ""}
          />
        </Cell>
        <Cell>
          <SelectInput
            width="fit-content"
            defaultValue={"de"}
            onChange={(e) => valueChange(e.target.value, "originalLang")}
            value={workLetter.originalLang || "de"}
          >
            {Object.keys(Langs).map((lang) => (
              <option key={lang} value={Langs[lang]}>
                {Langs[lang]}
              </option>
            ))}
          </SelectInput>
        </Cell>
        <Cell>
          <Input
            width="95%"
            placeholder="Basel ref."
            type="string"
            onChange={(e) => valueChange(e.target.value, "baselref")}
            value={workLetter.baselref || ""}
          />
        </Cell>
        <Cell>
          <LabelsSelector
            editing={true}
            labels={workLetter.labels || []}
            onChange={(labels) => valueChange(labels, "labels")}
          />
        </Cell>

        <Cell>
          <Input
            placeholder="Public"
            type="checkbox"
            onChange={(e) => valueChange(e.target.value === "on", "public")}
            checked={workLetter.public}
          />
        </Cell>
        <Cell>
          <Actions>
            <WhiteButton
              onClick={(e) => {
                onAdd();
              }}
            >
              Add
            </WhiteButton>

            {pendingChange ? (
              <RedButton
                onClick={() => {
                  onDiscard();
                }}
              >
                Discard
              </RedButton>
            ) : null}
          </Actions>
        </Cell>
      </Row>
    </React.Fragment>
  );
};

export default WorkLetterAdd;

const Actions = styled.div`
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
`;
