import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import DropDown, { IDropDownProps } from "./DropDown";
import { adjustColorOpacity, colors } from "../theme";

export type IPositionArrowType = "left" | "middle" | "right";

export type IPositionType = "bottom" | "left" | "right" | "top";

export interface ITooltipProps extends Omit<IDropDownProps, "className" | "show"> {
  arrowPosition?: IPositionArrowType;
  avoidClose?: boolean;
  className?: string;
  onShow?: (show: boolean) => void;
  position?: IPositionType;
  show?: boolean;
}

const Tooltip: FC<ITooltipProps> = ({
  arrowPosition = "middle",
  avoidClose,
  className,
  children,
  constraints,
  onShow,
  position = "bottom",
  show: propsShow = false,
  target,
  ...dropDownpProps
}) => {
  const avoidCloseRef = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState<boolean>(propsShow);

  const dropDownPosition = useMemo(() => {
    switch (position) {
      case "bottom":
        switch (arrowPosition) {
          case "left":
            return "top left";
          case "right":
            return "top right";
          case "middle":
            return "top center";
        }
        break;
      case "left":
        return "middle right";
      case "right":
        return "middle left";
      case "top":
        switch (arrowPosition) {
          case "left":
            return "bottom left";
          case "right":
            return "bottom right";
          case "middle":
            return "bottom center";
        }
        break;
    }
  }, [arrowPosition, position]);

  useEffect(() => {
    (avoidCloseRef.current as unknown as boolean) = avoidClose || false;
  }, [avoidClose]);

  useEffect(() => {
    setShow(propsShow);
  }, [propsShow]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) =>
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node) &&
      !avoidCloseRef.current &&
      (setShow(false), onShow?.(false));

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <DropDown
      attachment={dropDownPosition}
      constraints={constraints || [
        {
          to: 'scrollParent',
          pin: true
        },
        // {
        //   to: 'window',
        //   attachment: 'together'
        // }
      ]}
      offset={(position === "bottom" || position === "top") ? "0 -40" : "0 40"}
      show={show}
      target={{
        ...target,
        props: {
          onClick: (e: React.MouseEvent) => (e.stopPropagation(), (setShow(!show), onShow?.(!show))),
          ...target.props
        }
      }}
      {...dropDownpProps}
    >
      <Tag
        className={className}
        position={position}
        ref={wrapperRef}
      >
        {children}
        <Arrow position={position} arrowPosition={arrowPosition} />
      </Tag>
    </DropDown>
  );
};

export default Tooltip;

const tooltipAnimation = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Arrow = styled.div<{ arrowPosition: IPositionArrowType; position: IPositionType }>`
  background-color: inherit;
  height: 15px;
  position: absolute;
  width: 15px;

  ${props => {
    switch (props.position) {
      case "bottom":
        return css`
          border-top-left-radius: 3px;
          left: ${props.arrowPosition === "middle"
            ? "50%"
            : props.arrowPosition === "left"
            ? "20px"
            : "calc(100% - 40px)"};
          top: 0;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case "left":
        return css`
          border-top-right-radius: 3px;
          right: 0;
          top: 50%;
          transform: translate(50%, -50%) rotate(45deg);
        `;
      case "right":
        return css`
          border-bottom-left-radius: 3px;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case "top":
        return css`
          border-bottom-right-radius: 3px;
          left: ${props.arrowPosition === "middle"
            ? "50%"
            : props.arrowPosition === "left"
            ? "20px"
            : "calc(100% - 40px)"};
          bottom: 0;
          transform: translate(-50%, 50%) rotate(45deg);
        `;
    }
  }}
`;

const Tag = styled.div<{ position: IPositionType; }>`
  animation: ${tooltipAnimation} 0.4s linear forwards;
  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 0 10px 20px 5px ${adjustColorOpacity(colors.black, 0.2)};
  box-sizing: border-box;
  position: relative;

  ${props => (props.position === "bottom" || props.position === "top") && "margin: 40px 0;"}
`;
