import styled from "@emotion/styled";
import React, { FC } from "react";
import AuthorEdit from "./AuthorEdit";
import AuthorAdd from "./AuthorAdd";
import { Author, SITELANGS } from "@balthasarspeyr/common";
import { useQuery, useMutation, gql } from "@apollo/client";
import cloneDeep from "clone-deep";
import {
  Titlex,
  TitlesHeader,
  Table,
  Row,
  Title
} from "../../styles/styledcomponets";
import { IAddAuthorInput } from "../../types";
import { LoaderLarge } from "../Loaders";

interface IAuthorsData {
  getAuthors: Partial<Author>[];
}

const AUTHORS = gql`
  query getAuthors {
    getAuthors {
      id
      name {
        ${Object.values(SITELANGS).join("\n")}
      }
      surname {
        ${Object.values(SITELANGS).join("\n")}
      }
      alias {${Object.values(SITELANGS).join("\n")}
      }
      image
      numWorks
      numTranslations
    }
  }
`;

const ADD_AUTHOR = gql`
  mutation addAuthor($author: AuthorInput!) {
    addAuthor(author: $author) {
      id
      name {
        ${Object.values(SITELANGS).join("\n")}
      }
      surname {
        ${Object.values(SITELANGS).join("\n")}
      }
      alias {
        ${Object.values(SITELANGS).join("\n")}
      }
      image
    }
  }
`;

const DELETE_AUTHOR = gql`
  mutation deleteAuthor($id: ID!) {
    deleteAuthor(id: $id) {
      ok
    }
  }
`;

const AuthorsCMSLayout: FC = () => {
  const [addAuthor] = useMutation<
    { addAuthor: Author },
    { author: IAddAuthorInput }
  >(ADD_AUTHOR, {
    onCompleted({ addAuthor }) {
      if (addAuthor) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const [deleteAuthor] = useMutation<
    { deleteAuthor: { ok: boolean } },
    { id: string }
  >(DELETE_AUTHOR, {
    onCompleted({ deleteAuthor }) {
      if (deleteAuthor.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const { loading, error, data, refetch } = useQuery<IAuthorsData>(AUTHORS);

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <Table width="100%">
        <thead>
          <Row borderBottom="1px solid grey" height="30px">
            <Title width="50px">&nbsp;</Title>
            <Title width="250px">Name</Title>
            <Title width="250px">Surname</Title>
            <Title width="250px">Alias</Title>
            <Title width="150px">Num. Works/Translations</Title>
            <Title width="250px">&nbsp;</Title>
          </Row>
        </thead>
        <tbody>
          {data &&
            data.getAuthors.map((author) => (
              <AuthorEdit
                refetchHandler={() => refetch()}
                deleteHandler={() =>
                  deleteAuthor({ variables: { id: author.id! } })
                }
                key={author.id!}
                authorProp={cloneDeep(author)}
              />
            ))}
          <AuthorAdd
            addHandler={(author: IAddAuthorInput) => {
              addAuthor({ variables: { author } });
            }}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default AuthorsCMSLayout;

const AuthorsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
