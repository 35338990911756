import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FC, forwardRef, useEffect, useRef, useState } from "react";
import { adjustColorOpacity, colors, fonts } from "../theme";
import Checkbox from "./Checkbox";
import Icon from "./Icon";
import InputSearch from "./InputSearch";
import TooltipError from "./TooltipError";

interface IOption {
  label: string;
  element?: JSX.Element;
  value: string;
}

interface ISelectProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  mixBlend?: boolean;
  onChange?: (value: string) => void;
  onCloseError?: () => void;
  options: IOption[];
  placeholder?: string;
  value?: string;
}

const Select: FC<ISelectProps> = forwardRef(
  (
    {
      className,
      error,
      onChange,
      onCloseError,
      options,
      placeholder,
      value,
      ...props
    },
    _ref
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) =>
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        setExpanded(false);

      window.addEventListener("click", handleClickOutside);
      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }, []);

    return (
      <TooltipError error={error} onClose={onCloseError} className={className}>
        <Container
          error={!!error}
          ref={wrapperRef}
          expanded={expanded}
          {...props}
        >
          <StyledSelect
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <div>
              {options.find((o) => o.value === value)?.label || placeholder}
            </div>
            <Icon name="drop" />
          </StyledSelect>
          <Values expanded={expanded}>
            <div className="scrollbar">
              {options.length > 0 ? (
                options.map(
                  (option, index) =>
                    option.element || (
                      <label
                        key={index}
                        onClick={() => (
                          setExpanded(false), onChange?.(option.value)
                        )}
                      >
                        {option.label}
                      </label>
                    )
                )
              ) : (
                <ValuesEmpty />
              )}
            </div>
          </Values>
        </Container>
      </TooltipError>
    );
  }
);

Select.displayName = "Select";

export default Select;

interface ISelectCheckProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  hideValues?: boolean;
  initialValues?: string[];
  onChange: (values: string[]) => void;
  onCloseError?: () => void;
  onExpanded?: (expanded: boolean) => void;
  options: IOption[];
  placeholder?: string;
  searchEmpty?: string;
  searchPlaceholder?: string;
  onlyOne?: boolean;
}

export const SelectCheck: FC<ISelectCheckProps> = forwardRef(
  (
    {
      className,
      error,
      hideValues = false,
      initialValues = [],
      onChange,
      onCloseError,
      onExpanded,
      options,
      placeholder,
      searchEmpty,
      searchPlaceholder,
      onlyOne,
      ...props
    },
    _ref
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [values, setValues] = useState<string[]>(initialValues);
    const all: IOption | undefined = options.find((o) => o.value === "all");

    const resetValues = () =>
      setValues(
        initialValues.includes("all")
          ? options.filter((o) => o.value !== "all").map((o) => o.value)
          : initialValues.filter((v) => v !== "all")
      );

    useEffect(() => {
      expanded ? (resetValues(), setSearchText("")) : onChange(values);
    }, [expanded]);

    useEffect(() => {
      if (onlyOne && values.length == 2) {
        setValues([values[values.length - 1]]);
        onChange([values[values.length - 1]]);
      } else {
        onChange(values);
      }
    }, [values]);

    useEffect(() => {
      resetValues();
      const handleClickOutside = (event: MouseEvent) =>
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        (setExpanded(false), onExpanded?.(false));

      window.addEventListener("click", handleClickOutside);
      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }, []);

    return (
      <TooltipError error={error} onClose={onCloseError} className={className}>
        <Container
          error={!!error}
          expanded={expanded}
          ref={wrapperRef}
          {...props}
        >
          <StyledSelect
            expanded={expanded}
            onClick={() => (setExpanded(!expanded), onExpanded?.(!expanded))}
          >
            <div>
              {hideValues
                ? placeholder
                : all && values.length === options.length - 1
                ? all.label
                : values.length > 0
                ? options
                    .filter((o) => values.includes(o.value))
                    .map((o) => o.label)
                    .join(", ")
                : placeholder}
            </div>
            <Icon name="drop" />
          </StyledSelect>
          <Values
            expanded={expanded}
            search={!!searchEmpty && !!searchPlaceholder}
          >
            {searchEmpty && searchPlaceholder && (
              <div>
                <InputSearch
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder={searchPlaceholder}
                  value={searchText}
                />
              </div>
            )}
            <div className="scrollbar">
              {options.filter((option) =>
                option.label
                  .toLowerCase()
                  .includes(searchText.toLowerCase() || "")
              ).length > 0 ? (
                options
                  .filter((option) =>
                    option.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase() || "")
                  )
                  .map((option, index) => (
                    <Checkbox
                      key={index}
                      checked={
                        option.value === "all"
                          ? values.length === options.length - 1
                          : values.includes(option.value)
                      }
                      label={option.label}
                      onChange={() => {
                        if (option.value === "all") {
                          values.length === options.length - 1
                            ? setValues([])
                            : setValues(
                                options
                                  .filter((o) => o.value !== "all")
                                  .map((o) => o.value)
                              );
                        } else {
                          const array = Object.assign([], values);
                          values.includes(option.value)
                            ? array.splice(array.indexOf(option.value), 1)
                            : array.push(option.value);
                          setValues(array);
                        }
                      }}
                    />
                  ))
              ) : (
                <ValuesEmpty>{searchEmpty}</ValuesEmpty>
              )}
            </div>
          </Values>
        </Container>
      </TooltipError>
    );
  }
);

SelectCheck.displayName = "SelectCheck";

const Container = styled.div<{
  disabled?: boolean;
  error?: boolean;
  expanded: boolean;
  mixBlend?: boolean;
}>`
  ${fonts.family.theinhardt}
  ${fonts.size.smallExtra}
  align-items: center;
  background-color: ${(props) =>
    props.error ? adjustColorOpacity(colors.red[0], 0.2) : "white"};
  border-radius: 5px;
  border: solid 1px ${(props) => (props.error ? colors.red[0] : colors.gold[2])};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: ${(props) => (props.error ? colors.red[0] : colors.black)};
  cursor: pointer;
  display: flex;
  height: 40px;
  position: relative;
  width: 100%;

  :hover {
    border: solid 1px ${(props) => (props.error ? colors.red[0] : colors.black)};
    z-index: 1;
  }

  ${(props) =>
    props.expanded &&
    css`
      border: solid 1px ${props.error ? colors.red[0] : colors.black};
      z-index: 1;
    `}

  ${(props) =>
    props.mixBlend &&
    !props.expanded &&
    css`
      mix-blend-mode: difference;
    `}

  ${(props) =>
    props.error &&
    css`
      svg {
        color: ${colors.red};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${colors.black[2]};
      opacity: 0.5;
      pointer-events: none;

      > div > svg {
        opacity: 0.5;
      }
    `}
`;

const StyledSelect = styled.div<{ expanded: boolean }>`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  height: 100%;
  outline: none;
  padding: 0 15px 0 20px;
  width: 100%;

  ${(props) =>
    props.expanded &&
    css`
      :after {
        border-left: 8px solid transparent;
        border-bottom: 8px solid ${colors.white};
        border-radius: 1px;
        border-right: 8px solid transparent;
        content: "";
        height: 0;
        bottom: -5px;
        right: 15px;
        position: absolute;
        transform: translateY(100%);
        width: 0;
        z-index: 3;
      }
    `}

  div {
    flex: 1;
    overflow: hidden;
    padding-right: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    color: ${colors.gold[2]};
    margin-left: 15px;
  }

  &::placeholder {
    color: ${colors.grey};
  }
`;

const Values = styled.div<{ expanded: boolean; search?: boolean }>`
  border-radius: 3px;
  background-color: ${colors.white};
  box-shadow: 0 5px 20px 0 ${adjustColorOpacity(colors.black, 0.2)};
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 5px;
  transform: translateY(45px);
  z-index: 2;

  > div:first-of-type:not(:last-of-type) {
    padding: 8px 20px;

    :not(:last-of-type) {
      border-bottom: 1px solid ${adjustColorOpacity(colors.blue[4], 0.1)};
    }
  }

  > div {
    ${(props) => !props.expanded && "display: none"};

    :last-of-type {
      max-height: 359px;
      overflow: auto;

      > div,
      label {
        align-items: center;
        cursor: pointer;
        display: flex;
        padding: 8px 20px;
        position: relative;
        white-space: nowrap;

        span {
          margin-left: 10px;
          white-space: nowrap;
        }

        :not(:last-of-type) {
          border-bottom: 1px solid ${adjustColorOpacity(colors.blue[4], 0.1)};

          :after {
            height: calc(100% + 1px);
          }
        }
      }
    }
  }
`;

const ValuesEmpty = styled.div`
  justify-content: center;
  min-height: 104px;
  white-space: nowrap;
`;
