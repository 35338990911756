import React, { FC, useState } from "react";
import ThirdAuthorWorkAdd from "./ThirdAuthorWorkAdd";
import ThirdAuthorsWorksListComponent from "./ThirdAuthorWorksListComponent";
import { BlackButton } from "../../../styles/styledcomponets";
import ThirdAuthorWorkDetails from "./ThirdAuthorWorkDetails";

enum States {
  LISTING = 0,
  ADDING = 1,
  EDITING = 2
}

const ThirdAuthorsWorkCMSLayout: FC = () => {
  const [state, setState] = useState<States>(States.LISTING);
  const [workID, setWorkID] = useState<string | undefined>(undefined);

  const workSaved = () => {
    setState(States.LISTING);
  };

  const selectWorkToEdit = (id: string, state: States): void => {
    setWorkID(id);
    setState(state);
  };

  return (
    <React.Fragment>
      {state === States.LISTING && (
        <>
          <BlackButton onClick={() => setState(States.ADDING)}>
            Add new work
          </BlackButton>
          <ThirdAuthorsWorksListComponent workIDHandler={selectWorkToEdit} />
          <BlackButton onClick={() => setState(States.ADDING)}>
            Add new work
          </BlackButton>
        </>
      )}

      {[States.ADDING, States.EDITING].includes(state) && (
        <BlackButton onClick={() => setState(States.LISTING)}>
          Go back to works list
        </BlackButton>
      )}

      {state === States.ADDING && <ThirdAuthorWorkAdd workSaved={workSaved} />}
      {state === States.EDITING && workID && (
        <ThirdAuthorWorkDetails id={workID} />
      )}

      {[States.ADDING, States.EDITING].includes(state) && (
        <BlackButton onClick={() => setState(States.LISTING)}>
          Go back to works list
        </BlackButton>
      )}
    </React.Fragment>
  );
};

export default ThirdAuthorsWorkCMSLayout;
