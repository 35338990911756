import styled from "@emotion/styled";
import React, { FC } from "react";
import { theme } from "../../styles/theme";
import UserEdit from "./UserEdit";
import UserAdd from "./UserAdd";
import { IUser } from "../../types";
import { useQuery, useMutation, gql } from "@apollo/client";
import { LoaderLarge } from "../Loaders";

interface IUsersData {
  getUsers: IUser[];
}

const USERS = gql`
  query getUsers {
    getUsers {
      id
      name
      surname
      email
      role
    }
  }
`;

const ADD_USER = gql`
  mutation addUser($user: UserInput!) {
    addUser(user: $user) {
      id
      name
      surname
      email
      role
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUSER($email: String!) {
    deleteUser(email: $email) {
      ok
    }
  }
`;

interface IAddUserInput {
  name: string;
  surname: string;
  email: string;
  role: string[];
  authorized: boolean;
}

const UsersCMSLayout: FC = () => {
  const [addUser] = useMutation<{ addUser: IUser }, { user: IAddUserInput }>(
    ADD_USER,
    {
      onCompleted({ addUser }) {
        if (addUser) {
          refetch();
        }
      },
      onError(error) {
        console.log(error);
      },
    }
  );

  const [deleteUser] = useMutation<
    { deleteUser: { ok: boolean } },
    { email: string }
  >(DELETE_USER, {
    onCompleted({ deleteUser }) {
      if (deleteUser.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const { loading, error, data, refetch } = useQuery<IUsersData>(USERS);
  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;
  return (
    <div>
      <TitlesHeader>
        <Title>Name</Title>
        <Title>Surname</Title>
        <Title>Email</Title>
        <Title>Roles</Title>
        <Title>Passoword</Title>
        <Title>&nbsp;</Title>
      </TitlesHeader>
      <UsersList>
        {data &&
          data.getUsers.map((user) => (
            <UserEdit
              deleteHandle={() =>
                deleteUser({ variables: { email: user.email! } })
              }
              key={user.email!}
              userProp={user}
            />
          ))}
        <UserAdd
          addHandle={(user) => {
            addUser({ variables: { user } });
          }}
        />
      </UsersList>
    </div>
  );
};

export default UsersCMSLayout;

const TitlesHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  border-bottom: 1px solid ${theme.colors.brandBlack};
  margin-bottom: 10px;
`;

const Title = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
  font-weight: bold;
`;

const UsersList = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
