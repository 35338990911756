import React, {
  ComponentProps,
  FC,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { adjustColorOpacity, colors, fonts } from "../theme";
import TooltipError from "./TooltipError";

export interface IRadioButtonProps {
  error?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseError?: () => void;
}

const RadioButton: FC<
  IRadioButtonProps &
    Omit<ComponentProps<"input">, "ref"> &
    HTMLAttributes<HTMLInputElement>
> = forwardRef((props, _ref) => {
  const { error, label, onCloseError, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState<boolean | undefined>(props.checked);

  useEffect(() => {
    inputRef.current && setChecked(inputRef.current.checked);
  }, []);

  useEffect(() => setChecked(props.checked), [props.checked]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputRef.current && setChecked(inputRef.current.checked);
    props.onChange?.(e);
  };

  return (
    <TooltipError error={error} onClose={onCloseError}>
      <RadioButtonWrap error={!!error}>
        <RadioButtonInput
          selected={checked}
          error={!!error}
          disabled={rest.disabled}
          onClick={(e) => (e.preventDefault(), e.stopPropagation(), inputRef.current?.click())}
        >
          <input
            {...rest}
            ref={inputRef}
            type="radio"
            onChange={onChange}
            style={{ display: "none" }}
          />
          {checked && <Checked error={!!error} />}
        </RadioButtonInput>
        {label && <span>{label}</span>}
      </RadioButtonWrap>
    </TooltipError>
  );
});

RadioButton.displayName = "Checkbox";

export default RadioButton;

const Checked = styled.div<{ error?: boolean }>`
  background-color: ${props => props.error ? colors.red[0] : colors.blue[0]};
  border-radius: 50%;
  height: 9px;
  position: absolute;
  width: 9px;
`;

const RadioButtonInput = styled.div<{ disabled?: boolean, error?: boolean, selected?: boolean }>`
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${colors.gold[2]};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 15px;
  min-width: 15px;
  justify-content: center;
  transform: translateY(1px);

  :hover {
    border-color: ${colors.blue[0]};
  }

  ${(props) =>
    props.error &&
    `
    background-color: ${adjustColorOpacity(colors.red[2], 0.5)};
    border-color: ${colors.red[0]};
  `};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${colors.grey[2]};
    border-color: ${colors.grey[1]};
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const RadioButtonWrap = styled.div<{ error?: boolean }>`
  align-items: baseline;
  display: flex;

  span {
    ${fonts.family.theinhardt}
    ${fonts.size.smallExtra}

    color: ${props => props.error ? colors.red[0] : "inherit"};
    margin-left: 10px;
  }
`;
