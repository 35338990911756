import React, { FC, useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { LoaderLarge } from "../../Loaders";
import { Author, ShortWorksMap, SITELANGS } from "@balthasarspeyr/common";
import {
  Input,
  H2,
  H3,
  Table,
  Row,
  Title,
  Cell
} from "../../../styles/styledcomponets";
import { cloneDeep } from "@apollo/client/utilities";
import styled from "@emotion/styled";
import ShortWorkPublications from "./ShortWorkPublications";

type WorkShortWorkPubData = {
  getShortWorksMap: ShortWorksMap;
};
const SHORTWORKSMAP = gql`
  query getShortWorksMap($author: ID!) {
    getShortWorksMap(author: $author) {
      author {
        id
        name {
          en
        }
        surname {
          en
        }
      }
      shortWorksStages {
        id
        name {
          en
        }
        articles {
          public
          year
          id
          shortWorksStage {
            name {
              en
            }
          }
          title {
           ${Object.values(SITELANGS).join("\n")}
          }
          subtitle {
           ${Object.values(SITELANGS).join("\n")}
          }
          authors {
            author {
              name {
                en
              }
              surname {
                en
              }
              id
            }
          }
          baselref
          originalLang
          originalLangPubs {
            id
          }
          allPubs {
            id
            bibref
            lang
            year
            pressHouse {
              name
            }
          }
        }
        contributions {
          public
          year
          id
          shortWorksStage {
            name {
              en
            }
          }
          title {
            en
          }
          subtitle {
            en
          }
          authors {
            author {
              name {
                en
              }
              surname {
                en
              }
              id
            }
          }
          baselref
          originalLang
          originalLangPubs {
            id
          }
          allPubs {
            id
            bibref
            lang
            year
            pressHouse {
              name
            }
          }
        }
      }
    }
  }
`;

const DELETEPUBLICATION = gql`
  mutation deletePublication($id: ID!) {
    deletePublication(id: $id) {
      ok
      id
    }
  }
`;

const ShortWorkPubList: FC<{ author: Omit<Author, "works"> }> = ({
  author
}) => {
  const [nameFilter, setNameFilter] = useState("");

  const [visibleStage, setVisibleStage] = useState<string | null>(null);

  const { loading, error, data, refetch } = useQuery<WorkShortWorkPubData>(
    SHORTWORKSMAP,
    {
      variables: { author: author.id! },
      fetchPolicy: "no-cache"
    }
  );

  const [deletePublication] = useMutation<
    { deletePublication: { ok: boolean; id: string } },
    { id: string }
  >(DELETEPUBLICATION, {
    onCompleted({ deletePublication }) {
      if (deletePublication.ok) {
        refetch();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <H2>
        Short Work by {author.name?.en} {author.surname?.en}
      </H2>
      <Input
        border="1px solid #ccc"
        marginBottom="10px"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        placeholder="Filter by stage name"
        width="200px"
      />
      {data &&
        data.getShortWorksMap &&
        data.getShortWorksMap.shortWorksStages
          .filter((shortWorksStage) =>
            shortWorksStage.name.en
              ?.toLowerCase()
              .includes(nameFilter.toLowerCase())
          )
          .map((shortWorksStage) => (
            <React.Fragment key={shortWorksStage.id}>
              {visibleStage === shortWorksStage.id && (
                <React.Fragment>
                  <H3 onClick={() => setVisibleStage(null)}>
                    To {shortWorksStage.name.en}
                  </H3>
                  <Table width="100%">
                    <thead>
                      <Row borderBottom="1px solid #aaa">
                        <Title width="30%">Title</Title>
                        <Title width="25%">Subtitle</Title>
                        <Title width="80px">Year</Title>
                        <Title width="10%">Language</Title>
                        <Title width="150px">Basel Ref.</Title>
                        <Title width="20px">Public</Title>
                        <Title width="*">&nbsp;</Title>
                      </Row>
                    </thead>
                    <tbody>
                      <Row borderBottom={"1px solid black"}>
                        <Cell colSpan={7}>Articles</Cell>
                      </Row>
                      {shortWorksStage.articles.map((shortWork) => (
                        <ShortWorkPublications
                          author={author.id!}
                          shortWorksStage={shortWorksStage.id}
                          refetchHandler={() => refetch()}
                          deleteHandler={(id) =>
                            deletePublication({
                              variables: { id }
                            })
                          }
                          key={shortWork.id!}
                          workShortWorkProp={cloneDeep(shortWork)}
                        />
                      ))}
                      <Row borderBottom={"1px solid black"}>
                        <Cell colSpan={7}>Contributions</Cell>
                      </Row>
                      {shortWorksStage.contributions.map((shortWork) => (
                        <ShortWorkPublications
                          author={author.id!}
                          shortWorksStage={shortWorksStage.id}
                          refetchHandler={() => refetch()}
                          deleteHandler={(id) =>
                            deletePublication({
                              variables: { id }
                            })
                          }
                          key={shortWork.id!}
                          workShortWorkProp={cloneDeep(shortWork)}
                        />
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
              {visibleStage !== shortWorksStage.id &&
                (shortWorksStage.articles.length > 0 ||
                  shortWorksStage.contributions.length > 0) && (
                  <>
                    <StageItem
                      onClick={() => setVisibleStage(shortWorksStage.id)}
                    >
                      {shortWorksStage.name.en}
                      {` (${shortWorksStage.articles.length} Article${
                        shortWorksStage.articles.length > 1 ? "s)" : ")"
                      }`}
                    </StageItem>
                    <StageItem
                      onClick={() => setVisibleStage(shortWorksStage.id)}
                    >
                      {shortWorksStage.name.en}
                      {` (${shortWorksStage.contributions.length} Contribution${
                        shortWorksStage.contributions.length > 1 ? "s)" : ")"
                      }`}
                    </StageItem>
                  </>
                )}
            </React.Fragment>
          ))}
    </div>
  );
};

export default ShortWorkPubList;

const StageItem = styled.div`
  font-size: 0.9rem;
  cursor: pointer;
`;
