import styled from "@emotion/styled";
import React, { FC, useEffect, useRef, useState } from "react";
import { breakpoints, fonts } from "../theme";

interface ITableColumnWithTagsProps {
  className?: string;
  hiddenTags?: JSX.Element;
  tags?: JSX.Element;
}

const TableColumnWithTags: FC<ITableColumnWithTagsProps> = ({ children, hiddenTags, tags, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showHiddenTags, setShowHiddenTags] = useState<boolean>(true);

  useEffect(() => {
    if (!ref.current?.parentElement || !hiddenTags) return;

    if (ref.current.parentElement.id.split("collapsed-").length > 1) {
      setShowHiddenTags(ref.current.parentElement.id === "collapsed-true");
    }
  }, []);

  return (  
    <ColumnWithTags ref={ref} {...props}>
      <div>
        {children}
      </div>
      {showHiddenTags ? (
        <Tags>
          {tags}
          {hiddenTags}
        </Tags>
      ) : tags && (
        <Tags>
          {tags}
        </Tags>
      )}
    </ColumnWithTags>
  );
};

export default TableColumnWithTags;

const ColumnWithTags = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 0;
  width: 100%;

  > div {
    align-items: center;
    display: flex;

    :first-of-type {
      align-self: flex-start;
      max-width: 100%;
      padding-right: 10px;
    }
  }
`;

const Tags = styled.div`
  ${fonts.family.theinhardt}

  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 100%;
  white-space: nowrap;
  width: 100%;

  b {
    display: inline-block;
    transform: translateY(1px);
  }

  > div {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    margin-left: 0;
  }
`;
