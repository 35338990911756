import { cloneDeep } from "@apollo/client/utilities";
import {
  AUTHORROLE,
  PUBLICATIONLANGS,
  SITELANGS,
  WORKTYPE
} from "@balthasarspeyr/common";
import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { Label } from "react-bootstrap";
import {
  Actions,
  Cell,
  Input,
  RedButton,
  Row,
  SelectInput,
  WhiteButton
} from "../../../styles/styledcomponets";
import { theme } from "../../../styles/theme";
import { Langs } from "../../../types";
import { ShortWorkInput } from "../../../types";
import LabelsSelector from "../../labels/LabelsSelector";
import {
  PublicationLanguageSelect,
  SiteLanguageSelect
} from "../../LanguageSelect";
import MultilingualInput from "../../MultilingualInput";

type ShortWorkAddProps = {
  addHandler: (shortWork: ShortWorkInput) => void;
  author: string;
  shortWorksStage: string;
  type: WORKTYPE[];
};

const ShortWorkAdd: FC<ShortWorkAddProps> = ({
  addHandler,
  author,
  shortWorksStage,
  type
}) => {
  const cleanShortWork: ShortWorkInput = {
    title: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    subtitle: Object.values(SITELANGS).reduce((acc, lang) => {
      acc[lang] = "";
      return acc;
    }, {}),
    type,
    authors: [{ id: author, role: AUTHORROLE.Author }],
    public: false,
    originalLang: Langs.de,
    labels: [],
    shortWorksStage,
    year: 1900,
    baselref: ""
  };

  const [shortWork, setShortWork] = useState<ShortWorkInput>(
    cloneDeep(cleanShortWork)
  );
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const valueChange = (value, key) => {
    const newWorkLetter: ShortWorkInput = { ...shortWork };
    newWorkLetter[key] = value;
    setShortWork(newWorkLetter);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setShortWork({ ...cleanShortWork });
    setPendingChange(false);
  };

  const onAdd = () => {
    addHandler({
      ...shortWork
    });
    setShortWork({ ...cleanShortWork });
    setPendingChange(false);
  };

  return (
    <React.Fragment>
      <Row borderBottom="1px solid #bbb">
        <Cell>
          <MultilingualInput
            marginBottom={"5px"}
            width={"95%"}
            value={{ ...shortWork.title }}
            onChange={(value) => valueChange(value, "title")}
            multilang={true}
          />
        </Cell>
        <Cell>
          <MultilingualInput
            marginBottom={"5px"}
            width={"95%"}
            value={{ ...shortWork.subtitle }}
            onChange={(value) => valueChange(value, "subtitle")}
            multilang={true}
          />
        </Cell>
        <Cell>
          <Input
            type="number"
            width={"95%"}
            value={shortWork.year}
            onChange={(e) => valueChange(parseInt(e.target.value), "year")}
          />
        </Cell>
        <Cell padding="0 0 0 10px">
          <PublicationLanguageSelect
            hideHelper={true}
            onChange={(e) => valueChange(e.target.value, "originalLang")}
            value={shortWork.originalLang || PUBLICATIONLANGS[0]}
          />
        </Cell>
        <Cell>
          <Input
            width="95%"
            placeholder="Basel ref."
            type="string"
            onChange={(e) => valueChange(e.target.value, "baselref")}
            value={shortWork.baselref || ""}
          />
        </Cell>
        <Cell>
          <Input
            placeholder="Public"
            type="checkbox"
            onChange={(e) => {
              valueChange(e.target.checked, "public");
            }}
            checked={shortWork.public}
          />
        </Cell>
        <Cell>
          <LabelsSelector
            editing={true}
            labels={shortWork.labels}
            onChange={(labels) => valueChange(labels, "labels")}
          />
        </Cell>

        <Cell>
          <Actions>
            <WhiteButton
              onClick={(e) => {
                onAdd();
              }}
            >
              Add
            </WhiteButton>

            {pendingChange ? (
              <RedButton
                onClick={() => {
                  onDiscard();
                }}
              >
                Discard
              </RedButton>
            ) : null}
          </Actions>
        </Cell>
      </Row>
    </React.Fragment>
  );
};

export default ShortWorkAdd;
