import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { ReactComponent as TrinityLogo } from "../logo.svg";
import { ReactComponent as FolderLogo } from "../static/icons/folder.svg";
import { ReactComponent as PeopleLogo } from "../static/icons/family.svg";
import { ReactComponent as BookLogo } from "../static/icons/handbook.svg";
import { ReactComponent as FeatherLogo } from "../static/icons/feather.svg";
import { ReactComponent as ClampLogo } from "../static/icons/clamp.svg";
import { theme } from "../styles/theme";
import AuthorsCMSLayout from "./authors/AuthorsCMSLayout";
import Login from "./Login";
import { IUser } from "../types";
import UsersCMSLayout from "./users/UsersCMSLayout";
import { UserRole } from "../types";
import { LoaderLarge } from "./Loaders";
import PressHouseCMSLayout from "./presshouses/PressHouseCMSLayout";
import { useRecoilState } from "recoil";
import { MainSections, sectionState, subsectionState } from "../store";
import ShortWorksStagesCMSLayout from "./shortworksstages/ShortWorksStageCMSLayout";
import RecipientsCMSLayout from "./recipients/RecipientCMSLayout";
import EditedAuthorsCMSLayout from "./editedAuthors/EditedAuthorCMSLayout";
import NewssCMSLayout from "./news/NewsCMSLayout";
import WorkLettersCMSLayout from "./works/letters/WorkLetterCMSLayout";
import LetterCMSLayout from "./publications/letters/LetterCMSLayout";
import ShortWorksCMSLayout from "./works/shortworks/ShortWorksCMSLayout";
import ShortWorkPublications from "./publications/shortworks/ShortWorkPublications";
import ShortWorkPubCMSLayout from "./publications/shortworks/ShortWorkPubCMSLayout";
import LabelsCMSLayout from "./labels/LabelsCMSLayout";
import EditedWorksCMSLayout from "./works/editedworks/EditedWorkCMSLayout";
import MonographicsCMSLayout from "./works/mainworks/MonographicCMSLayout";
import BookCMSLayout from "./publications/books/BookCMSLayout";
import OrphansCMSLayout from "./publications/books/OrphansCMSLayout";
import ThirdAuthorsWorkCMSLayout from "./works/thirdauthors/ThirdAuthorWorksCMSLayout";
import ThirdAuthorsBooksCMSLayout from "./publications/books/ThirdAuthorsBooksCMSLayout";

type Section = {
  section: MainSections;
  subsection?: string;
};

export interface CMSLayoutProps {
  noScroll?: boolean;
}

interface IUserData {
  getMe: IUser;
}

const CMSLayout: FC<CMSLayoutProps> = ({ noScroll }) => {
  const [authData, setAuthData] = useState({
    token: localStorage.getItem("token") || "",
    email: localStorage.getItem("email") || ""
  });

  const ME = gql`
    query getMe($email: String!, $token: String!) {
      getMe(email: $email, token: $token) {
        name
        surname
        email
        token
        role
        authorized
      }
    }
  `;

  const [section, setSection] = useRecoilState(sectionState);
  const [subsection, setSubsection] = useRecoilState(subsectionState);

  // get logged user data
  const { loading, error, data, refetch } = useQuery<IUserData>(ME, {
    variables: { email: authData.email, token: authData.token },
    fetchPolicy: "no-cache"
  });

  if (loading)
    return (
      <React.Fragment>
        <LoaderLarge />
      </React.Fragment>
    );

  // cannot get Me user, thus, show login
  if (error || !data?.getMe) {
    const loginHandle = (token: string, email: string) => {
      setAuthData({ token, email });
      refetch();
    };

    return (
      <div>
        <Login loginHandle={loginHandle} />
      </div>
    );
  }

  return (
    <Container>
      <Header>
        <TrinityLogo style={{ width: "50px", height: "50px" }} />
        <h1>Balthasar & Speyr - CMS</h1>
        <div>
          Welcome {data?.getMe.name} {data?.getMe.surname}&nbsp;&nbsp;
          <span
            onClick={() => {
              localStorage.clear();
              setAuthData({ email: "", token: "" });
            }}
          >
            Sign out
          </span>
        </div>
      </Header>
      <Main>
        <Menu>
          {data?.getMe.authorized && data.getMe.role?.includes(UserRole.USER) && (
            <React.Fragment>
              <MenuSection>
                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.Authors);
                    setSubsection(undefined);
                  }}
                  selected={section === MainSections.Authors}
                >
                  <FeatherLogo />
                  Authors
                </MenuSectionTitle>
                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.Labels);
                    setSubsection(undefined);
                  }}
                  selected={section === MainSections.Labels}
                >
                  <FeatherLogo />
                  Labels
                </MenuSectionTitle>

                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.ShortWorksStages);
                    setSubsection(undefined);
                  }}
                  selected={section === MainSections.ShortWorksStages}
                >
                  <FeatherLogo />
                  Short Works Stages
                </MenuSectionTitle>
                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.LetterRecipients);
                    setSubsection(undefined);
                  }}
                  selected={section === MainSections.LetterRecipients}
                >
                  <FeatherLogo />
                  Letter Correspondents
                </MenuSectionTitle>
                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.EditedAuthors);
                    setSubsection(undefined);
                  }}
                  selected={section === MainSections.EditedAuthors}
                >
                  <FeatherLogo />
                  Edited Authors
                </MenuSectionTitle>
              </MenuSection>
              <MenuSection>
                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.PressHouses);
                    setSubsection(undefined);
                  }}
                  selected={section === MainSections.PressHouses}
                >
                  <ClampLogo />
                  Press Houses
                </MenuSectionTitle>
              </MenuSection>
              <MenuSection>
                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.Works);
                    setSubsection("monographicworks");
                  }}
                  selected={section === MainSections.Works}
                >
                  <FolderLogo />
                  Work
                </MenuSectionTitle>
                {section === MainSections.Works && (
                  <React.Fragment>
                    <MenuItem
                      onClick={() => setSubsection("monographicworks")}
                      selected={
                        section === MainSections.Works &&
                        subsection === "monographicworks"
                      }
                    >
                      Main Works
                    </MenuItem>
                    <MenuItem
                      onClick={() => setSubsection("shortworks")}
                      selected={
                        section === MainSections.Works &&
                        subsection === "shortworks"
                      }
                    >
                      Short Works
                    </MenuItem>
                    <MenuItem
                      onClick={() => setSubsection("editedworks")}
                      selected={
                        section === MainSections.Works &&
                        subsection === "editedworks"
                      }
                    >
                      Edited Works
                    </MenuItem>
                    <MenuItem
                      onClick={() => setSubsection("letters")}
                      selected={
                        section === MainSections.Works &&
                        subsection === "letters"
                      }
                    >
                      Letters
                    </MenuItem>
                    <MenuItem
                      onClick={() => setSubsection("thirdauthors")}
                      selected={
                        section === MainSections.Works &&
                        subsection === "thirdauthors"
                      }
                    >
                      Third Authors
                    </MenuItem>
                  </React.Fragment>
                )}
              </MenuSection>

              <MenuSection>
                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.Publications);
                    setSubsection("books");
                  }}
                  selected={section === MainSections.Publications}
                >
                  <FolderLogo />
                  Publications
                </MenuSectionTitle>
                {section === MainSections.Publications && (
                  <React.Fragment>
                    <MenuItem
                      onClick={() => setSubsection("books")}
                      selected={
                        section === MainSections.Publications &&
                        subsection === "books"
                      }
                    >
                      Books
                    </MenuItem>
                    <MenuItem
                      onClick={() => setSubsection("shortworks")}
                      selected={
                        section === MainSections.Publications &&
                        subsection === "shortworks"
                      }
                    >
                      Short Works
                    </MenuItem>
                    <MenuItem
                      onClick={() => setSubsection("letters")}
                      selected={
                        section === MainSections.Publications &&
                        subsection === "letters"
                      }
                    >
                      Letters
                    </MenuItem>
                    <MenuItem
                      onClick={() => setSubsection("orphans")}
                      selected={
                        section === MainSections.Publications &&
                        subsection === "orphans"
                      }
                    >
                      Orphans
                    </MenuItem>
                    <MenuItem
                      onClick={() => setSubsection("thirdauthors")}
                      selected={
                        section === MainSections.Publications &&
                        subsection === "thirdauthors"
                      }
                    >
                      Third Authors
                    </MenuItem>
                  </React.Fragment>
                )}
              </MenuSection>
              <MenuSection>
                <MenuSectionTitle
                  onClick={() => {
                    setSection(MainSections.News);
                    setSubsection(undefined);
                  }}
                  selected={section === MainSections.News}
                >
                  <FeatherLogo />
                  News
                </MenuSectionTitle>
              </MenuSection>
            </React.Fragment>
          )}
          {data?.getMe.authorized && data.getMe.role?.includes(UserRole.ADMIN) && (
            <MenuSection>
              <MenuItem
                onClick={() => {
                  setSection(MainSections.Users);
                  setSubsection(undefined);
                }}
                selected={section === MainSections.Users}
              >
                <PeopleLogo />
                Users
              </MenuItem>
            </MenuSection>
          )}
        </Menu>
        <Right>
          <Wrap>
            <Content>
              {section === MainSections.Labels && <LabelsCMSLayout />}
              {section === MainSections.PressHouses && <PressHouseCMSLayout />}
              {section === MainSections.Users && <UsersCMSLayout />}

              {section === MainSections.Works &&
                subsection === "monographicworks" && <MonographicsCMSLayout />}
              {section === MainSections.Works &&
                subsection === "editedworks" && <EditedWorksCMSLayout />}
              {section === MainSections.Works &&
                subsection === "shortworks" && <ShortWorksCMSLayout />}
              {section === MainSections.Works && subsection === "letters" && (
                <WorkLettersCMSLayout />
              )}
              {section === MainSections.Works &&
                subsection === "thirdauthors" && <ThirdAuthorsWorkCMSLayout />}

              {section === MainSections.Publications &&
                subsection === "letters" && <LetterCMSLayout />}
              {section === MainSections.Publications &&
                subsection === "books" && <BookCMSLayout />}
              {section === MainSections.Publications &&
                subsection === "shortworks" && <ShortWorkPubCMSLayout />}
              {section === MainSections.Publications &&
                subsection === "orphans" && <OrphansCMSLayout />}
              {section === MainSections.Publications &&
                subsection === "thirdauthors" && <ThirdAuthorsBooksCMSLayout />}

              {section === MainSections.Authors && <AuthorsCMSLayout />}
              {section === MainSections.ShortWorksStages && (
                <ShortWorksStagesCMSLayout />
              )}
              {section === MainSections.LetterRecipients && (
                <RecipientsCMSLayout />
              )}
              {section === MainSections.EditedAuthors && (
                <EditedAuthorsCMSLayout />
              )}
              {section === MainSections.News && <NewssCMSLayout />}
            </Content>
          </Wrap>
        </Right>
      </Main>
    </Container>
  );
};

export default CMSLayout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: 100%;
`;

const Header = styled.div`
  align-items: center;
  background-color: ${theme.colors.brandBlack};
  box-shadow: 0 2px 4px 0 rgba(20, 21, 31, 0.5);
  display: flex;
  flex-basis: 50px;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0px 30px 0px 16px;
  z-index: 10;
  h1 {
    color: ${theme.colors.gold04};
    font-size: 1.2rem;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  span {
    color: ${theme.colors.gold04};
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    margin-left: 10px;
  }
`;

const Main = styled.div`
  display: flex;
  flex: 1;
`;

const Menu = styled.div`
  background-color: ${theme.colors.brandBlack};
  padding: 15px 0px;
  width: 180px;
`;

interface MenuItemProps {
  selected?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  align-items: center;
  background-color: transparent;
  border-left: 3px solid
    ${(props) => (props.selected ? theme.colors.gold01 : "transparent")};
  color: ${(props) =>
    props.selected ? theme.colors.gold01 : theme.colors.gold04};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 25px;
  margin: 0;
  padding: 0px 10px;
  white-space: nowrap;
  img,
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    display: ${(props) => (props.selected ? "none" : "initial")};
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MenuSectionTitle = styled.div<MenuItemProps>`
  align-items: center;
  background-color: ${(props) =>
    props.selected ? theme.colors.gray6 : "transparent"};
  border-left: 3px solid
    ${(props) => (props.selected ? theme.colors.brandRed : "transparent")};
  color: ${(props) =>
    props.selected ? theme.colors.brandBlack : theme.colors.brandWhite};
  margin: ${(props) => (props.selected ? "0px -12px" : "0px 0px")};
  padding: ${(props) => (props.selected ? "0px 12px" : "0")};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 37px;
  white-space: nowrap;
  img,
  svg {
    color: ${theme.colors.brandWhite};
    fill: ${theme.colors.brandBlack};
    display: ${(props) => (props.selected ? "none" : "initial")};
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
`;
const MenuSection = styled.div`
  padding: 0px 12px;
`;

const Wrap = styled.div`
  background-color: ${theme.colors.brandBlack};
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

interface ContentProps {
  noScroll?: boolean;
}
const Content = styled.div<ContentProps>`
  background-color: ${theme.colors.gray6};
  bottom: ${(props) => (props.noScroll ? "0px" : "auto")};
  box-sizing: border-box;
  left: 0px;
  min-height: 100%;
  overflow: ${(props) => (props.noScroll ? "hidden" : "visible")};
  padding: ${(props) => (props.noScroll ? "0px" : "50px")};
  position: absolute;
  right: 0px;
  top: 0px;
`;
