import React, { FC, useEffect, useState } from "react";
import { AUTHORROLE, PUBLICATIONLANGS, WORKTYPE } from "@balthasarspeyr/common";
import BoxWithTitleContainer from "../../BoxWithTitleContainer";
import styled from "@emotion/styled";
import { InputTextComponent } from "../../InputTextComponent";
import { MainWorkInput } from "../../../types";
import AddAuthors from "../AddAuthors";
import { PublicationLanguageSelect } from "../../LanguageSelect";
import { Checkbox } from "@balthasarspeyr/ui";
import LabelsSelector from "../../labels/LabelsSelector";

interface IAuthorRole {
  role: AUTHORROLE;
  id: string;
}

const WorkGeneralDataComponent: FC<{
  title: string;
  componentData: Partial<MainWorkInput>;
  updateData: (data: Partial<MainWorkInput>) => void;
}> = ({ title, componentData, updateData }) => {
  const [addedAuthors, setAddedAuthors] = useState<Array<IAuthorRole>>(
    componentData.authors || []
  );

  const [baselref, setBaselRef] = useState<string>(
    componentData.baselref || ""
  );
  const [year, setYear] = useState<string>(
    componentData.year?.toString() || ""
  );

  const [originalLang, setOriginalLang] = useState<string>(
    componentData.originalLang || PUBLICATIONLANGS.de
  );

  const [type, setType] = useState<WORKTYPE[]>(componentData.type || []);
  const [labels, setLabels] = useState<string[]>(componentData.labels || []);

  // if a list of authors are passed as argument, set them as added
  useEffect(() => {
    setAddedAuthors(componentData.authors || []);
    setBaselRef(componentData.baselref || "");
    setYear(componentData.year?.toString() || "");
    setOriginalLang(componentData.originalLang || PUBLICATIONLANGS.de);
    setType(componentData.type || []);
    setLabels(componentData.labels || []);
  }, [componentData]);

  const ComponentJSX = (
    <Container>
      <AuthorDiv>
        <AddAuthors
          authors={addedAuthors}
          updateAuthors={(authors: IAuthorRole[]) => {
            updateData({ authors });
            setAddedAuthors([...authors]);
          }}
        />
      </AuthorDiv>
      <div>
        <InputTextComponent
          margin="0px 20px 10px 0px"
          padding="0px 0px 0px 0px"
          width="200px"
          placeholder="bib. ref."
          value={baselref}
          helper="Basel bib. Reference"
          onChange={(value) => {
            updateData({ baselref: value });
            setBaselRef(value);
          }}
        />
        <LabelsSelector
          labels={labels}
          showHelper={true}
          onChange={(value) => {
            updateData({ labels: value });
            setLabels(value);
          }}
        />
      </div>
      <div>
        <PublicationLanguageSelect
          value={originalLang}
          onChange={(e) => {
            updateData({ originalLang: e.target.value });
            setOriginalLang(e.target.value);
          }}
        />

        <br />
        <InputTextComponent
          margin="0px 20px 10px 0px"
          padding="0px 0px 0px 0px"
          width="200px"
          placeholder="Year"
          value={year}
          helper="First Publication Year"
          onChange={(value) => {
            updateData({ year: Number(value) });
            setYear(value);
          }}
        />
      </div>
    </Container>
  );

  return (
    <BoxWithTitleContainer title={title}>{ComponentJSX}</BoxWithTitleContainer>
  );
};

export default WorkGeneralDataComponent;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const AuthorDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  width: 50%;
`;
