import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { theme } from "../../styles/theme";
import { ReactComponent as TrashIcon } from "../../static/icons/trash.svg";
import { EditedAuthor, SITELANGS } from "@balthasarspeyr/common";
import { EditedAuthorInput } from "../../types";

import { gql, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import AvatarUploader from "../AvatarUploader";

const langs = Object.values(SITELANGS);

const UPDATE_EDITEDAUTHOR = gql`
  mutation updateEditedAuthor($id: ID!, $editedAuthor: EditedAuthorInput!) {
    updateEditedAuthor(id: $id, editedAuthor: $editedAuthor) {
      id
      image
      name {
       ${Object.values(SITELANGS).join("\n")}
      }
      surname {
       ${Object.values(SITELANGS).join("\n")}
      }
      alias {
       ${Object.values(SITELANGS).join("\n")}
      }
    }
  }
`;

interface EditedAuthorEditProps {
  editedAuthorProp: EditedAuthorInput & { id: string };
  deleteHandler: () => void;
  refetchHandler: () => void;
}

const EditedAuthorEdit: FC<EditedAuthorEditProps> = ({
  editedAuthorProp,
  deleteHandler,
  refetchHandler
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [multilang, setMultilang] = useState<boolean>(false);
  const [editedAuthor, setEditedAuthor] = useState<
    EditedAuthorInput & { id: string }
  >(cloneDeep(editedAuthorProp));
  const [backEditedAuthor, setBackEditedAuthor] = useState<
    EditedAuthorInput & { id: string }
  >(cloneDeep(editedAuthorProp));
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const [updateEditedAuthor] = useMutation<
    { updateEditedAuthor: EditedAuthor },
    { id: string; editedAuthor: EditedAuthorInput }
  >(UPDATE_EDITEDAUTHOR, {
    onCompleted({ updateEditedAuthor }) {
      if (updateEditedAuthor) {
        setEditing(false);
        setPendingChange(false);
        setBackEditedAuthor(cloneDeep(editedAuthor));
        refetchHandler();
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const valueChange = (value, key: string, lang?: string) => {
    const newEditedAuthor = cloneDeep(editedAuthor);
    if (key === "image") {
      newEditedAuthor.image = value;
    } else {
      if (lang) newEditedAuthor[key][lang] = value;
      else newEditedAuthor[key] = value;
    }
    setEditedAuthor(newEditedAuthor);
    setPendingChange(true);
  };

  const onDiscard = () => {
    setEditing(false);
    setPendingChange(false);
    setEditedAuthor(backEditedAuthor);
  };

  const onSave = () => {
    const { id, ...neweditedAuthor } = editedAuthor;

    updateEditedAuthor({
      variables: {
        id,
        editedAuthor: neweditedAuthor
      }
    });
  };

  return (
    <React.Fragment>
      {langs.map(
        (lang) =>
          (multilang || lang === "en") && (
            <Row key={`${lang}${editedAuthor.id}`}>
              <Cell>
                {lang === SITELANGS.en && (
                  <AvatarUploader
                    setImageURL={(url) => valueChange(url, "image")}
                    url={editedAuthor.image || ""}
                  />
                )}
              </Cell>
              <Cell>
                <Input
                  type="text"
                  name="name"
                  editing={editing}
                  onClick={() => setEditing(true)}
                  onChange={(e) => valueChange(e.target.value, "name", lang)}
                  value={editedAuthor.name[lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  type="text"
                  name="surname"
                  editing={editing}
                  onClick={() => setEditing(true)}
                  onChange={(e) => valueChange(e.target.value, "surname", lang)}
                  value={editedAuthor.surname?.[lang] || ""}
                />
              </Cell>
              <Cell>
                <Input
                  type="text"
                  editing={editing}
                  name="alias"
                  onClick={() => setEditing(true)}
                  onChange={(e) => valueChange(e.target.value, "alias", lang)}
                  value={editedAuthor.alias?.[lang] || ""}
                />
              </Cell>
              {lang === "en" ? (
                <Cell>
                  <Actions editing={editing}>
                    {multilang ? (
                      <Langs onClick={() => setMultilang(false)}>
                        View only English
                      </Langs>
                    ) : (
                      <Langs onClick={() => setMultilang(true)}>
                        View all langs.
                      </Langs>
                    )}
                    {pendingChange ? (
                      <Save
                        onClick={() => {
                          onSave();
                        }}
                      >
                        Save
                      </Save>
                    ) : null}
                    {pendingChange ? (
                      <Discard
                        onClick={() => {
                          onDiscard();
                        }}
                      >
                        Discard
                      </Discard>
                    ) : null}
                    <TrashIcon
                      onClick={() => {
                        if (
                          confirm(
                            "Are you sure you want to delete this recipient? (you will remove all his works and letters"
                          )
                        ) {
                          deleteHandler();
                        }
                      }}
                    />
                  </Actions>
                </Cell>
              ) : (
                <Cell>
                  <Actions editing={editing}>{lang}</Actions>
                </Cell>
              )}
            </Row>
          )
      )}
    </React.Fragment>
  );
};

export default EditedAuthorEdit;

const Row = styled.tr``;

type InputProps = {
  editing: boolean;
};
const Input = styled.input<InputProps>`
  width: 95%;
  background-color: ${(props) =>
    props.editing ? theme.colors.brandWhite : theme.colors.gray6};
  border: 0px;
  height: 25px;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
`;

interface IActionsProps {
  editing: boolean;
}
const Actions = styled.div<IActionsProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  background-color: ${theme.colors.gray6};
  border: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  img,
  svg {
    fill: ${theme.colors.brandBlack};
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    cursor: pointer;
  }
  svg:hover {
    fill: ${theme.colors.brandRed};
  }
`;

const Langs = styled.button`
  background-color: ${theme.colors.brandWhite};
  color: ${theme.colors.brandLang};
  font-weight: bold;
  border-radius: 8px;
  border: 1px solid black;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Save = styled.button`
  background-color: ${theme.colors.brandBlack};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;

const Discard = styled.button`
  background-color: ${theme.colors.brandRed};
  color: ${theme.colors.brandWhite};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 25px;
  flex-basis: auto;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
`;

const Cell = styled.td`
  border: 0;
  padding: 0;
`;
