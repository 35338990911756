import React, { FC, RefObject } from "react";
import { Helper, Input, TextArea } from "../styles/styledcomponets";
import styled from "@emotion/styled";
import { theme } from "../styles/theme";

interface IInputTextProps {
  helper: string;
  placeholder: string;
  value?: string;
  defaultValue?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  readOnly?: boolean;
  editing?: boolean;
  onChange?: (value: string) => void;
  showHelper?: boolean;
}

const TextAreaComponent: FC<IInputTextProps> = ({
  helper,
  placeholder,
  value,
  defaultValue,
  width,
  height,
  margin,
  padding,
  readOnly,
  onChange,
  showHelper = true,
}) => {
  return (
    <Div {...{ margin, padding, width, height }}>
      {showHelper && <Helper>{helper}</Helper>}
      <TextArea
        spellCheck="true"
        autoCorrect="on"
        autoComplete="on"
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        width={width}
        height={height}
        margin={margin || "0"}
        padding={padding || "0"}
        readOnly={readOnly}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
    </Div>
  );
};

const InputTextComponent: FC<IInputTextProps> = ({
  helper,
  editing,
  placeholder,
  value,
  defaultValue,
  width,
  height,
  margin,
  padding,
  onChange,
  readOnly,
  showHelper = true,
}) => {
  return (
    <Div {...{ margin, padding, width, height }}>
      {showHelper && <Helper>{helper}</Helper>}
      <Input
        editing={editing || true}
        readOnly={readOnly || false}
        type="text"
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        width={width}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </Div>
  );
};

export { InputTextComponent, TextAreaComponent };

const Div = styled.div<{
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
}>`
  width: "fit-content";
  height: ${(props) => props.height || "fit-content"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
`;
